import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import OutsideClickHandler from "react-outside-click-handler";

export default function DatePicker({visible, setVisible}: {visible: boolean, setVisible: any}) {
  return (
    <>
      {
        visible &&
        <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar showDaysOutsideCurrentMonth  onChange={(value) => {
              // @ts-ignore
              console.log(`${value['$d'].getMonth() + 1}/${value['$d'].getDate()}/${value['$d'].getFullYear()}`);
            }}/>
          </LocalizationProvider>
        </OutsideClickHandler>
      }
    </>

  );
}
