import React from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import styles from "../../Components/ProductAddContent/ProductAddContent.module.scss";
import {Icon} from "@iconify/react";
import PageTitle from "../../Components/PageTitle";
import WorkerEditContent from "../../Components/WorkerEditContent";

function Index() {

  const {theme} = useSelector((state: RootState) => state.MainSlice);

  return (
    <div className={'worker-add-page'} >

      <WorkerEditContent/>
    </div>
  );
}

export default Index;
