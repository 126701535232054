import React, {useEffect, useState} from 'react';
import styles from './OrdersListContent.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {Icon} from "@iconify/react";
import {NavLink} from "react-router-dom";
import {getOrders} from "../../Hooks/Hooks";
import {setOrders} from "../../redux/MainSlice";
import moment from "moment/";
import loader from '../../assets/img/loader.svg'

export const formatNumber = (num: number) => {
  const str = "" + num
  const pad = "0000"
  return (pad.substring(0, pad.length - str.length) + str);
}

function Index() {

  const {theme, orders} = useSelector((state: RootState) => state.MainSlice)
  const {selectRestaurantId} = useSelector((state: RootState) => state.MainSlice);
  const {accessToken} = useSelector((state: RootState) => state.UserSlice);
  const [page, setPage] = useState(1);
  const [pages, setTotalPages] = useState<number[]>([])
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const [sortBy, changeSort] = useState({
    type: 'createdAt',
    reverse: true,
  })
  useEffect(() => {
    if(accessToken && selectRestaurantId) {
      setLoading(true);
      getOrders({accessToken: accessToken, id: selectRestaurantId, page: page, limit: 10, sortBy: sortBy.type, order: !sortBy.reverse ? 'asc' : 'desc'}).then(response => {
        dispatch(setOrders(response?.data))
        setLoading(false);
        setTotalPages([]);
        // @ts-ignore
          for(let i = 1; i <= (Math.ceil(response?.data[0].totalItemsCount/10)); i++) {
            setTotalPages((current) => [...current, i])
          }
      })
    }
  }, [accessToken, selectRestaurantId, page, sortBy])



  function byField(field: any) {
    return (a: any, b: any) => a[field] > b[field] ? 1 : -1;
  }

  const [sortedOrders, setSortedOrders] = useState<any>([]);


  useEffect(() => {
      if(sortBy.reverse) {
        setSortedOrders([...orders].sort(byField(sortBy.type)).reverse());
      } else {
        setSortedOrders([...orders].sort(byField(sortBy.type)))
      }
  }, [orders, sortBy])

  const [searchValue, changeSearch] = useState('');

  return (
    <>
        <div className={styles.content}  style={{paddingTop: loading ? '34px' : '',backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 4px 18px rgba(75, 70, 92, 0.1)'}}>
          {
            loading ?
              <img src={loader} className={styles.loader}/>:
              <>
                <div className={styles.top}>
                  <div className={styles.searchWrap} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}}>
                    <Icon color={theme === 'light' ? '#4B465C66' : '#8692D066'} icon="material-symbols:search-rounded" />
                    <input value={searchValue} onChange={(e) => changeSearch(e.target.value)} type="text" className={styles.search} placeholder={'Поиск'}/>
                  </div>
                </div>
                <div className={styles.tableTop} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}}>
                  <div className={styles.colSmall}>
                    <p className={styles.title}># заказа</p>

                  </div>
                  <div className={styles.colLarge}>
                    <p className={styles.title} onClick={() => changeSort({type: 'table', reverse: sortBy.type !== 'table' ? true : !sortBy.reverse})}>стол</p>
                    <div className={styles.sortBtns} onClick={() => changeSort({type: 'table', reverse: sortBy.type !== 'table' ? true : !sortBy.reverse})}>
                      <button>
                        <Icon color={sortBy.type === 'table' && !sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-up" />
                      </button>
                      <button>
                        <Icon color={sortBy.type === 'table' && sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-down" />
                      </button>
                    </div>
                  </div>
                  <div className={styles.colLarge}>
                    <p className={styles.title} onClick={() => changeSort({type: 'totalCost', reverse: sortBy.type !== 'totalCost' ? true : !sortBy.reverse})}>Сумма, руб</p>
                    <div className={styles.sortBtns} onClick={() => changeSort({type: 'totalCost', reverse: sortBy.type !== 'totalCost' ? true : !sortBy.reverse})}>
                      <button>
                        <Icon color={sortBy.type === 'totalCost' && !sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-up" />
                      </button>
                      <button>
                        <Icon color={sortBy.type === 'totalCost' && sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-down" />
                      </button>
                    </div>
                  </div>
                  <div className={styles.colLarge}>
                    <p className={styles.title} onClick={() => changeSort({type: 'status', reverse: sortBy.type !== 'status' ? true : !sortBy.reverse})}>Статус</p>
                    <div className={styles.sortBtns} onClick={() => changeSort({type: 'status', reverse: sortBy.type !== 'status' ? true : !sortBy.reverse})}>
                      <button>
                        <Icon color={sortBy.type === 'status' && !sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-up" />
                      </button>
                      <button>
                        <Icon color={sortBy.type === 'status' && sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-down" />
                      </button>
                    </div>
                  </div>
                  <div className={styles.colLarge}>
                    <p className={styles.title} onClick={() => changeSort({type: 'createdAt', reverse: sortBy.type !== 'createdAt' ? true : !sortBy.reverse})}>Дата создания</p>
                    <div className={styles.sortBtns} onClick={() => changeSort({type: 'createdAt', reverse: sortBy.type !== 'createdAt' ? true : !sortBy.reverse})}> 
                      <button>
                        <Icon color={sortBy.type === 'createdAt' && !sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-up" />
                      </button>
                      <button>
                        <Icon color={sortBy.type === 'createdAt' && sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-down" />
                      </button>
                    </div>
                  </div>
                  <div className={styles.colLarge}>
                    <p className={styles.title} onClick={() => changeSort({type: 'updatedAt', reverse: sortBy.type !== 'updatedAt' ? true : !sortBy.reverse})}>Последнее изменение</p>
                    <div className={styles.sortBtns} onClick={() => changeSort({type: 'updatedAt', reverse: sortBy.type !== 'updatedAt' ? true : !sortBy.reverse})}>
                      <button>
                        <Icon color={sortBy.type === 'updatedAt' && !sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-up" />
                      </button>
                      <button>
                        <Icon color={sortBy.type === 'updatedAt' && sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-down" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className={styles.tableContent}>
                  {
                    orders.length === 0 ?
                      <p className={styles.notFound}>Ничего не найдено</p>
                      :
                      sortedOrders.map((item: any) => {
                        return formatNumber(item.orderNumber).includes(searchValue) && (
                          <div style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}} key={item._id} className={styles.categoriesItem}>
                            <div className={styles.colLarge}>
                              <NavLink to={'/orders-list/order?id=' + item._id} className={styles.orderNum}>#{formatNumber(item.orderNumber)}</NavLink>
                            </div>
                            <div className={styles.colLarge}>
                              <p className={styles.tableNum}>{item.table}</p>
                            </div>
                            <div className={styles.colLarge}>
                              <p className={styles.price}>{item.totalCost}</p>
                            </div>
                            <div className={styles.colLarge}>
                              <p className={`${styles.status} ${item.status} ${item.status === 'pending' ? styles.wait : item.status === 'accepted' ? styles.accepted : item.status === 'Завершен' ? styles.complete : item.status === 'Готовится' ? styles.getReady : item.status === 'canceled' ? styles.canceled : ''}`}>{item.status === 'pending' ? 'Ожидаение' : item.status === 'accepted' ? 'Принят' : item.status === 'canceled' ? 'Отменен' : ''}</p>
                            </div>
                            <div className={styles.colLarge}>
                              <p className={styles.date}>{moment(item.createdAt).format('DD.MM.YYYY HH:mm').toString()}</p>
                            </div>
                            <div className={styles.colLarge}>
                              <p className={styles.lastChangeDate}>{moment(item.updatedAt).format('DD.MM.YYYY HH:mm').toString()}</p>
                            </div>
                          </div>
                        )
                      })
                  }
                </div>
                {
                  pages.length > 1 &&
                  <div className={styles.pagination}>
                    <button onClick={() => {
                      if(page > 1) {
                        setPage(page - 1)
                      }
                    }}  style={{backgroundColor: theme === 'light' ? 'rgba(75, 70, 92, 0.08)' : 'rgba(194,192,197,0.08)'}}>
                      <Icon color={theme === 'light' ? '#4B465C' : '#CFD3EC'} icon={'tabler:chevron-left'} width={16}/>
                    </button>
                    {
                      pages.map(item => <button onClick={() => setPage(item)} className={page === item ? styles.active : ''} key={item} style={{backgroundColor: theme === 'light' ? 'rgba(75, 70, 92, 0.08)' : 'rgba(194,192,197,0.08)'}}>{item}</button>)
                    }
                    <button onClick={() => {
                      if(page < pages.length) {
                        setPage(page + 1)
                      }
                    }} style={{backgroundColor: theme === 'light' ? 'rgba(75, 70, 92, 0.08)' : 'rgba(194,192,197,0.08)'}}>
                      <Icon color={theme === 'light' ? '#4B465C' : '#CFD3EC'} icon={'tabler:chevron-right'} width={16}/>
                    </button>
                  </div>
                }
              </>
          }
        </div>
    </>
  );
}

export default Index;
