import { useState } from 'react'

import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MuiFormControl, { FormControlProps } from '@mui/material/FormControl'
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const SelectCustomized = ({change, fullWidth, defaultValue}: {change: any, fullWidth?: boolean, defaultValue?: string}) => {
  const [value, setValue] = useState<string>(defaultValue ? defaultValue : 'grams')
  const handleChange = (event: SelectChangeEvent) => {
    change(event.target.value as string)
    setValue(event.target.value as string)
  }

  const localTheme = useSelector((state: RootState) => state.MainSlice.theme);

  const FormControl = styled(MuiFormControl)<FormControlProps>(({ theme }) => ({
    '& > .MuiInputBase-root': {
      '&.MuiInput-root:before, &.MuiInput-root:after': {
        border: 0
      }
    },
    '& .MuiInputBase-input': {
      fontFamily: '"Public Sans", sans-serif',
      fontSize: 13,
      borderRadius: 4,
      position: 'relative',
      color: localTheme === 'dark' ? '#CFD3EC' : '#A5A3AE',
      padding: '7px 34px 7px 10px',
      backgroundColor: 'transparent',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      border: localTheme === 'light' ? '1px solid #DBDADE' : `1px solid #434968`,
      fontStyle: 'normal',
      '&:focus-within': {
        borderRadius: 4,
        borderColor: theme.palette.warning.light,
      }
    }
  }))

  return (
    <div className='demo-space-x'>
      <FormControl fullWidth sx={{maxWidth: fullWidth ? 'none' : '250px'}}>
        <Select
          value={value}
          input={<InputBase />}
          onChange={handleChange}
          id='demo-customized-select'
          labelId='demo-customized-select-label'
          IconComponent={(props) => <KeyboardArrowDownIcon sx={{fill: localTheme === 'light' ? '#6F6B7D' : '#CFD3EC'}} {...props}/>}
        >
          <MenuItem value={'grams'}>гр</MenuItem>
          <MenuItem value={'kilograms'}>кг</MenuItem>
          <MenuItem value={'milliliters'}>мл</MenuItem>
          <MenuItem value={'liters'}>л</MenuItem>
          <MenuItem value={'pieces'}>шт</MenuItem>
        </Select>
      </FormControl>
    </div>
  )
}

export default SelectCustomized
