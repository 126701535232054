import React from 'react';
import styles from './Dashboard.module.scss';
import {Icon} from "@iconify/react";
import {useTheme} from "@mui/material";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import Typography from '@mui/material/Typography';
import {DashboardChart} from '../DashboardChart';


function Index() {

  const MuiTheme = useTheme();

  const theme = useSelector((state: RootState) => state.MainSlice.theme);

  return (
    <div className={styles.content}>
      <div className={styles.blocks}>
        <div style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349'}} className={styles.block}>
          <div className={styles.info}>
            <p className={styles.value}>123</p>
            <p className={styles.title}>Просмотры блюд</p>
          </div>
          <div className={styles.icon}>
            <Icon width={22} height={22} icon={'tabler:eye'} color={"#ff9f43"}/>
          </div>
        </div>
        <div style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349'}} className={styles.block}>
          <div className={styles.info}>
            <p className={styles.value}>8</p>
            <p className={styles.title}>Заказы</p>
          </div>
          <div className={styles.icon}>
            <Icon width={22} height={22} icon={'tabler:receipt'} color={"#ff9f43"}/>
          </div>
        </div>
        <div style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349'}} className={styles.block}>
          <div className={styles.info}>
            <p className={styles.value}>35</p>
            <p className={styles.title}>Блюд заказано</p>
          </div>
          <div className={styles.icon}>
            <Icon width={22} height={22} icon={'tabler:tools-kitchen-2'} color={"#ff9f43"}/>
          </div>
        </div>
      </div>
      <div className={styles.chart} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349'}}>
        {/* <DashboardChart/> */}
      </div>
      <div className={styles.chart} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', marginLeft: '24px'}}>
        {/* <DashboardChart/> */}
      </div>
    </div>
  );
}

export default Index;
