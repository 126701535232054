import React, {useEffect, useLayoutEffect, useState} from 'react';
import styles from './Header.module.scss';
import {NavLink, useLocation} from "react-router-dom";
import HeaderSelect from '../../Components/HeaderSelect';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {openMenuToggle, setNotice, themeToggle, toggleNoticeModal, toggleUserModal} from "../../redux/MainSlice";
import {Badge, IconButton, Typography, useMediaQuery} from "@mui/material";
import {Icon} from "@iconify/react";
import OutsideClickHandler from 'react-outside-click-handler';
import {setCompany} from "../../redux/UserSlice";
import {getBalance, getCanAdd, getDate} from "../../Hooks/Hooks";
import axios from "axios";
import { Button } from 'antd';
// import CompletionPanel from "../../Components/CompletionPanel";
import ym from 'react-yandex-metrika';

function Header() {

  const {theme, userModal, noticeItems, noticeModal, selectRestaurantId} = useSelector((state: RootState) => state.MainSlice);
  const {user, company, accessToken, restaurants} = useSelector((state: RootState) => state.UserSlice);

  const location = useLocation();

  const [visible, setVisible] = useState(true);
  const {layoutVisible} = useSelector((state: RootState) => state.MainSlice)
  const [plus, setPlus] = useState(false);

  useEffect(() => {
    const refreshToken = localStorage.getItem('refreshToken');
    if(accessToken && refreshToken && user !== null && user.emailConfirmed) {
      getBalance({accessToken: accessToken, refreshToken: refreshToken, id: user?.companyId}).then(balance => {
        dispatch(setCompany({...balance}));
      })
      getCanAdd({accessToken}).then(response => {
        if(response.type !== 'error') {
          setPlus(response.result)
        }
      })
    }
  }, [location, user])

  useLayoutEffect(() => {
    const path = location.pathname;
    if(!(path !== '/reset-password' && path !== '/registration' && location.pathname !== '/try-demo' && path !== '/sign-in' && path !== '/add-restaurant') || !layoutVisible) {
      setVisible(false);}
    else {
      setVisible(true);
    }
  }, [location, layoutVisible])

  const removeNotice = async (id: string) => {
    const result = await axios.post(`https://api.vkusclick.ru/v1/restaurants/${selectRestaurantId}/notifications/${id}`, {}, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    if(result.status === 200) {
      dispatch(setNotice([...noticeItems.filter(item => item._id !== id)]));
    }
  }


  const matches = useMediaQuery('(min-width:1420px)');
  const dispatch = useDispatch();

  const toggleDrawer = (open: boolean) => dispatch(openMenuToggle(open));

  function notificationsLabel(count: number) {
    if (count === 0) {
      return 'no notifications';
    }
    if (count > 99) {
      return 'more than 99 notifications';
    }
    return `${count} notifications`;
  }

  const logout = () => {
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('defaultRest');
    window.location.reload();
  }

  const exitDemo = () => {
    ym('reachGoal', 'demoRegistrationButtonClick');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('defaultRest');
    window.open("https://panel.vkusclick.ru/registration", "_self");
  }

  return (
    <>
      {
        visible &&
        <>
          {/* <CompletionPanel /> */}

          <div className={styles.header} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light'  ? `0px 2px 4px rgba(165, 163, 174, 0.3)` : '0px 2px 4px rgba(15, 20, 34, 0.4)'}}>
            {!matches ?
              <IconButton onClick={() => toggleDrawer(true)} sx={{padding: 0, marginRight: '15px'}}>
                <Icon color={theme === 'light' ? '#6F6B7D' : '#CFD3EC'} icon={'tabler:menu-2'}/>
              </IconButton> : null
            }
            <HeaderSelect/>
            {
              restaurants?.filter(item => item._id === selectRestaurantId)[0] &&
              <a href={'https://vkusclick.ru/' + (restaurants && restaurants?.length > 0 && restaurants?.filter(item => item._id === selectRestaurantId)[0] ? restaurants?.filter(item => item._id === selectRestaurantId)[0].code + '/1' : '')} target='_blank' rel='noreferrer' className={styles.plusBtn} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}}>
                <Icon width={18} height={18} color={theme === 'light' ? '#6F6B7D' : '#B6BEE3'} icon={'solar:eye-linear'}/>
                Меню
              </a>
            }
            {
              user?.role === 'director' && plus ? (
                <NavLink to={'/add-restaurant'} className={styles.plusBtn} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}}>
                  <Icon width={18} height={18} color={theme === 'light' ? '#6F6B7D' : '#B6BEE3'} icon={'tabler:plus'}/>
                </NavLink>
              ) : (
                user?.role === 'demo' ? (
                <div onClick={exitDemo} className={styles.demoPanel} style={{borderColor: theme === 'dark' ? '#434968' : '#DBDADE'}}>              
                  <div>Это демонстрационное заведение</div>
                  <div>Нажмите сюда, чтобы создать своё</div>
                </div>
                ) : (null)
              )
            }
            <div className={styles.headerContent}>
              { user?.role === 'director' || user?.role === 'demo' ? (
                <NavLink to={'/calculator'} className={styles.balance} style={{borderColor: theme === 'dark' ? '#434968' : '#DBDADE'}}>
                  <Icon icon={'tabler:wallet'}/>
                  <p className={styles.balanceText}>{!Number.isInteger(company?.currentBalance) ? company?.currentBalance : company?.currentBalance + '.00'} руб.</p>
                </NavLink>
              ) : (null)}
              <IconButton onClick={() => dispatch(themeToggle(theme === 'light' ? 'dark' : 'light'))} className={styles.themeToggle}>
                {theme === 'light' ?
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke={'#6F6B7D'} strokeLinecap="round" strokeLinejoin="round"  strokeWidth="2" d="M12 3h.393a7.5 7.5 0 0 0 7.92 12.446A9 9 0 1 1 12 2.992zm5 1a2 2 0 0 0 2 2a2 2 0 0 0-2 2a2 2 0 0 0-2-2a2 2 0 0 0 2-2m2 7h2m-1-1v2"/></svg>
                  :
                  <svg xmlns="http://www.w3.org/2000/svg" width="256" height="256" viewBox="0 0 256 256"><path fill="#B6BEE3" d="M116 32V16a12 12 0 0 1 24 0v16a12 12 0 0 1-24 0Zm80 96a68 68 0 1 1-68-68a68.07 68.07 0 0 1 68 68Zm-24 0a44 44 0 1 0-44 44a44.05 44.05 0 0 0 44-44ZM51.51 68.49a12 12 0 1 0 17-17l-12-12a12 12 0 0 0-17 17Zm0 119l-12 12a12 12 0 0 0 17 17l12-12a12 12 0 1 0-17-17ZM196 72a12 12 0 0 0 8.49-3.51l12-12a12 12 0 0 0-17-17l-12 12A12 12 0 0 0 196 72Zm8.49 115.51a12 12 0 0 0-17 17l12 12a12 12 0 0 0 17-17ZM44 128a12 12 0 0 0-12-12H16a12 12 0 0 0 0 24h16a12 12 0 0 0 12-12Zm84 84a12 12 0 0 0-12 12v16a12 12 0 0 0 24 0v-16a12 12 0 0 0-12-12Zm112-96h-16a12 12 0 0 0 0 24h16a12 12 0 0 0 0-24Z"/></svg>
                }
              </IconButton>
              <div className={styles.notice}>

                {
                  <OutsideClickHandler onOutsideClick={() => {
                    if(noticeModal) {
                      dispatch(toggleNoticeModal())
                    }
                  }}>
                    <IconButton onClick={() => dispatch(toggleNoticeModal())} aria-label={notificationsLabel(4)}>
                      <Badge anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }} badgeContent={noticeItems.length} color="error">
                        <Icon color={theme === 'light' ? '#6F6B7D' : '#B6BEE3'} icon={'tabler:bell'}/>
                      </Badge>
                    </IconButton>
                    {
                      noticeModal &&
                      <div className={styles.noticeModal} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 10px 20px rgba(165, 163, 174, 0.4)' : '0px 10px 20px rgba(15, 20, 34, 0.5)'}}>
                        <p className={styles.modalTitle}>Уведомления</p>
                        <div className={styles.modalItem}>
                          {
                            noticeItems.length > 0 ?
                            noticeItems.map(item => {
                              return (
                                <div key={item._id} className={styles.noticeItem} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}}>
                                  <div className={styles.iconWrap} style={{backgroundColor: item.type === 'zeroBalance' ? 'rgba(234, 84, 85, 0.16)' : item.type === 'lowBalance' ? 'rgba(255, 159, 67, 0.16)' : 'rgba(0, 207, 232, 0.24)'}}>
                                    <Icon color={item.type === 'zeroBalance' ? '#EA5455' : item.type === 'lowBalance' ? '#FF9F43' : '#00CFE8'} icon={item.type === 'zeroBalance' ? 'tabler:currency-rubel' : item.type === 'lowBalance' ? 'tabler:currency-rubel' : 'tabler:info-circle'}/>
                                  </div>
                                  <div className={styles.description}>
                                    {/*<p style={{color: item.type === 'error' ? '#EA5455' : item.type === 'warning' ? '#FFB976' : '#00CFE8'}} className={styles.title}>{item.title}</p>*/}
                                    <p style={{color: item.type === 'zeroBalance' ? '#EA5455' : item.type === 'lowBalance' ? '#FFB976' : '#00CFE8'}} className={styles.title}>{item.type === 'zeroBalance' ? 'Баланс исчерпан' : item.type === 'lowBalance' ? 'Баланс подходит к концу' : 'Информация'}</p>
                                    <p className={styles.text}>{item.type === 'zeroBalance' ? 'Основной функционал сервиса недоступен до пополнения баланса заведения' : item.type === 'lowBalance' ? 'Ваш баланс менее 250 рублей. Пополните баланс во избежании приостановки функционала' : item.value}</p>
                                    <p className={styles.date}>{getDate(item.updatedAt)}</p>
                                  </div>
                                  <button onClick={() => removeNotice(item._id)} className={styles.delete}><Icon width={20} icon={'tabler:x'} color={theme === 'light' ? '#4B465C' : '#CFD3EC'}/></button>
                                </div>
                              )
                            }) :
                              <p style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}} className={styles.noNotice}>Нет уведомлений</p>
                          }
                        </div>
                      </div>
                    }
                  </OutsideClickHandler>
                }
              </div>
              <div className={styles.user}>
                <div className={styles.info}>
                  <p className={styles.name}>{user?.firstName + ' ' + user?.lastName}</p>
                  <p style={{color: theme === 'light' ? '#A5A3AE' : ''}} className={styles.work}>{
                    user?.role === 'director' ? 'Директор' : user?.role === 'officiant' ? 'Официант' : user?.role === 'demo' ? 'Менеджер' : 'Менеджер'
                  }</p>
                </div>
                <OutsideClickHandler onOutsideClick={() => {
                        if(userModal) {
                          dispatch(toggleUserModal(!userModal))
                        }
                      }}>
                        {
                          user?.img && user?.img !== '' ?
                            <img onClick={() => {
                              dispatch(toggleUserModal(!userModal))
                            }} className={styles.userImg} src={user?.img} alt=""/>
                            : <div onClick={() => {
                              dispatch(toggleUserModal(!userModal))
                            }} className={styles.noAvatar}>{user?.firstName[0]}{user?.lastName[0]}</div>
                        }
                        {userModal &&
                          <div className={styles.userModal} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 10px 20px rgba(165, 163, 174, 0.4)' : '0px 10px 20px rgba(15, 20, 34, 0.5)'}}>
                          {
                            user?.role === 'director' &&
                            <NavLink to={'/workers/edit?id=' + user._id} className={styles.modalItem}>
                              <Icon width={24} icon={'tabler:user'}/>
                              Профиль
                            </NavLink>
                          }
                          {
                            user?.role === 'director' &&
                            <span className={styles.underline} style={{backgroundColor: theme === 'light' ? '#DBDADE' : '#434968'}}/>
                          }
                          <button onClick={logout} className={styles.modalItem}>
                            <Icon width={24} icon={'tabler:logout'}/>
                            Выйти
                          </button>
                        </div>
                        }
                     </OutsideClickHandler>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
}

export default Header;
