import React from 'react';
import WorkerEditContent from "../../Components/WorkerEditContent";

function Index() {


  return (
    <div className={'worker-add-page'} >
      <WorkerEditContent/>
    </div>
  );
}

export default Index;
