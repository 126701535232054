import React from 'react';
import styles from './ServicesListContent.module.scss';
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import ServiceBlock from "../ServiceBlock";
import ServiceModal from "../ServiceModal";

function Index() {

  const {services, serviceModalActive} = useSelector((state: RootState) => state.MainSlice);


  return (
    <>
      <div className={styles.content}>
        {
          // serviceModalActive && <ServiceModal/>
        }
        {
          services.map((item) => {
            return (
              <ServiceBlock item={item}/>
            )
          })
        }
      </div>
    </>
  );
}

export default Index;
