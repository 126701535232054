import React from 'react';
import PageTitle from "../../Components/PageTitle";
import ProductsContent from "../../Components/ProductsContent";

function Index() {
  return (
    <div className={'products-page'}>
      <PageTitle title={'Блюда и напитки'}/>
      <ProductsContent/>
    </div>
  );
}

export default Index;
