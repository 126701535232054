import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import service from '../assets/img/service.png';

export interface CounterState {
  theme: 'dark' | 'light',
  layoutVisible: boolean,
  categories?: {
    activeTimeStart: any;
    activeTimeEnd: any;
    _id: string,
    title: string,
    priority: number,
    active: true,
    image: string,
    updatedAt: string
  }[] | null,
  menuOpen: boolean,
  products: {
    _id: string,
    categoryId: string,
    title: string,
    description: string,
    price: number,
    oldPrice: number,
    quantity: number,
    measure: string,
    servingTime: number,
    kilocalories: number,
    proteins: number,
    fats: 0,
    carbohydrates: 0,
    ingredients: string,
    allergens: [
      string
    ],
    active: true,
    image: string,
    updatedAt: string
  }[],
  waiters: {
    id: number,
    name: string,
    tables: number[]
  }[],
  tables: number[],
  orders: {
    _id: string,
    officiantId: string,
    orderNumber: number,
    table: number,
    wishes: string,
    status: string,
    dishes: [
      {
        _id: string,
        quantity: number
      }
    ],
    totalCost: number,
    acceptedAt: string,
    completedAt: string,
    updatedAt: string,
    totalItemsCount: number
  }[],
  workers: {
    _id: string,
    companyId: string,
    restaurantsId: [
      string
    ],
    firstName: string,
    lastName: string,
    role: string,
    phone: string,
    email: string,
    updatedAt: string
  }[] | null,
  subscriptions: {
    _id: string,
    name: string,
    type: string,
    title: string,
    description: string,
    textHTML: string,
    priceDay: 0,
    oldPriceDay: 0,
    value: 0,
    icon: string,
    priority: 0,
    inDevelopment: boolean,
    updatedAt: string
  }[],
  services: {
    title: string,
    img: string,
    text: string,
    price: number
  }[],
  serviceModalActive: boolean,
  userModal: boolean,
  noticeModal: boolean,
  noticeItems: {
    _id: string,
    type: string,
    value: string,
    link: string,
    updatedAt: string
  }[],
  selectRestaurantId: string
}

const initialState: CounterState = {
  userModal: false,
  selectRestaurantId: '',
  noticeModal: false,
  theme: 'light',
  layoutVisible: false,
  menuOpen: false,
  categories: null,
  products: [],
  waiters: [
    {
      id: 1,
      name: 'Edgar Jones',
      tables: [1, 2, 3, 13]
    },
    {
      id: 2,
      name: 'Edgar Jones',
      tables: [1, 2, 3, 13]
    },
    {
      id: 3,
      name: 'Edgar Jones',
      tables: [1, 2, 3, 13]
    },
  ],
  tables: [],
  orders: [],
  workers: null,
  subscriptions: [],
  services: [
    {
      title: 'Card Title',
      img: service,
      text: 'Some quick example text to build on the card title and make up the bulk of the card\'s content. card title and make up the bulk of the card\'s content. ',
      price: 1234
    },
    {
      title: 'Card Title',
      img: service,
      text: 'Some quick example text to build on the card title and make up the bulk of the card\'s content. card title and make up the bulk of the card\'s content. ',
      price: 1234
    },
    {
      title: 'Card Title',
      img: service,
      text: 'Some quick example text to build on the card title and make up the bulk of the card\'s content. card title and make up the bulk of the card\'s content. ',
      price: 1234
    },
    {
      title: 'Card Title',
      img: service,
      text: 'Some quick example text to build on the card title and make up the bulk of the card\'s content. card title and make up the bulk of the card\'s content. ',
      price: 1234
    },
    {
      title: 'Card Title',
      img: service,
      text: 'Some quick example text to build on the card title and make up the bulk of the card\'s content. card title and make up the bulk of the card\'s content. ',
      price: 1234
    },
    {
      title: 'Card Title',
      img: service,
      text: 'Some quick example text to build on the card title and make up the bulk of the card\'s content. card title and make up the bulk of the card\'s content. ',
      price: 1234
    },
  ],
  serviceModalActive: false,
  noticeItems: [

  ],

}

export const MainSlice = createSlice({
  name: 'Main',
  initialState,
  reducers: {
    themeToggle: (state, action: PayloadAction<'dark' | 'light'>) => {
      state.theme = action.payload;
      localStorage.setItem('theme', action.payload);
    },
    setLayoutVisible: (state, action: PayloadAction<boolean>) => {
      state.layoutVisible = action.payload;
    },
    openMenuToggle: (state, action:PayloadAction<boolean>) => {
      state.menuOpen = action.payload;
    },
    setServiceModalActive: (state, action: PayloadAction<boolean>) => {
      state.serviceModalActive = action.payload;
    },
    toggleUserModal: (state, {payload}) => {
      state.userModal = payload;
    },
    toggleNoticeModal: (state) => {
      state.noticeModal = !state.noticeModal;
    },
    setSelectRestaurantId: (state, {payload}) => {
      console.log(payload);
      state.selectRestaurantId = payload;
    },
    setWorkers: (state, {payload}) => {
      state.workers = [...payload];
    },
    setCategories: (state, {payload}) => {
      if(payload) {
        state.categories = [...payload];
      }
    },
    setSubscriptions: (state, {payload}) => {
      state.subscriptions = [...payload]
    },
    setProducts: (state, {payload}) => {
      state.products = [...payload[0]]
    },
    setOrders: (state, {payload}) => {
      state.orders = [...payload]
    },
    setNotice: (state, {payload}) => {
      state.noticeItems = [...payload]
    },
    setTables: (state, {payload}) => {
      state.tables = [...payload]
    }
  },
})

export const {setTables, setNotice, setOrders, setProducts, setSubscriptions, themeToggle, setLayoutVisible, openMenuToggle, setServiceModalActive, toggleUserModal, toggleNoticeModal, setSelectRestaurantId, setWorkers, setCategories } = MainSlice.actions

export default MainSlice.reducer
