import React, {useEffect, useState} from 'react';
import {styled} from "@mui/material/styles";
import styles from "../CalculatorBlock/CalculatorBlock.module.scss";
import Checkbox from "@mui/material/Checkbox";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {Typography} from "@mui/material";



const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 18,
  height: 18,
  boxShadow: 'none',
  backgroundColor: 'transparent',
  border: '1px solid',
  '.Mui-focusVisible &': {
    outlineOffset: 2,
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'transparent',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#FF9F43',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  border: 'none',
  '&:before': {
    display: 'block',
    height: 18,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#FF9F43',
  },
});
function CheckItem({title, id,  defaultChecked, addToChecked, checkedArray}: {id?: string, title: string, defaultChecked: boolean, addToChecked?: any, checkedArray?: any}) {
  const {theme} = useSelector((state: RootState) => state.MainSlice);

  const [checked, setCheck] = useState(false);

  useEffect(() => {
    if(addToChecked) {
      addToChecked(checked ? [...checkedArray, id] : [...checkedArray.filter((item: string) => item !== id)])
    }
  }, [checked])

  useEffect(() => {
    setCheck(defaultChecked)
  }, [])
  return (
    <div onClick={() => {
      if(!checkedArray || checkedArray.length > 1) {
        setCheck(!checked)
      }
    }} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '18px', cursor: 'pointer'}}>
      <p style={{fontWeight: 700, fontSize: '15px', lineHeight: '22px'}}>{title}</p>
      <Checkbox
        sx={{
          '&:hover': { bgcolor: 'transparent' },
        }}
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        inputProps={{ 'aria-label': 'Checkbox demo' }}
        className={`${styles.checkBox} ${theme === 'light' ? styles.light : styles.dark}`}
        checked={checked}
      />
    </div>

  );
}

export default CheckItem;
