import React, {useEffect, useState} from 'react';
import styles from './ResetPassword.module.scss';
import {Typography, useTheme} from "@mui/material";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import loader from "../../assets/img/loading.svg";
import Input from "../SignIn/Input";

function Index() {

  const theme = useSelector((state: RootState) => state.MainSlice.theme);
  const {user} = useSelector((state: RootState) => state.UserSlice);

  const [step, setStep] = useState(1);

  const [emailError, setEmailError] = useState({
    active: false,
    text: ''
  })
  const [passwordError, setPasswordError] = useState({
    active: false,
    text: ''
  })
  const [codeError, setCodeError] = useState({
    active: false,
    text: ''
  })
  const [confirmPasswordError, setConfirmPasswordError] = useState({
    active: false,
    text: ''
  })

  const resetErrors = () => {
    setEmailError({
      active: false,
      text: ''
    })
    setPasswordError({
      active: false,
      text: ''
    })
    setCodeError({
      active: false,
      text: ''
    })
    setConfirmPasswordError({
      active: false,
      text: ''
    })
  }

  const [email, changeEmail] = useState('');
  const [password, changePassword] = useState('');
  const [confirmPassword, changeConfirmPassword] = useState('');
  const [code, changeCode] = useState('');
  const {accessToken} = useSelector((state: RootState) => state.UserSlice);
  const [loading, setLoading] = useState(false);
  const [seconds, changeSeconds] = useState(59);

  const navigate = useNavigate();

  const MuiTheme = useTheme();

  const timer = () => {
    let timerCount = 59;
    const timerF = setInterval(() => {
      if(timerCount > 0) {
        timerCount--
        changeSeconds(timerCount)
      } else {
        clearInterval(timerF);
      }
    }, 1000)
  }

  useEffect(() => {
    if(user) {
      changeEmail(user.email);
    }
    if(step === 2) {
      timer();
    }
  }, [step])
  const sendEmailToReset = async () => {
    if (email.length === 0) {
      setEmailError({
        active: true,
        text: 'Поле не заполнено'
      })
    }
    else {
      try {
        setLoading(true)
        await axios.post('https://api.vkusclick.ru/v1/employees/change_director_password', {
          email
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        setStep(2)
        setLoading(false)
        resetErrors()
      } catch (e: any) {
        setLoading(false)
      }
    }
  }
  const repeatCode = () => {
    if(seconds === 0) {
      changeSeconds(59);
      timer();
      if(step === 2) {
        sendEmailToReset().then(r => console.log(r))
      }
    }
  }

  const sendCodeAndPassword = async () => {
    if(code.length === 0 || password.length === 0 || confirmPassword.length === 0) {
      if(code.length === 0) {
        setCodeError({
          active: true,
          text: 'Поле не заполнено'
        })
      }
      if(password.length === 0) {
        setPasswordError({
          active: true,
          text: 'Поле не заполнено'
        })
      }
      if (confirmPassword.length === 0) {
        setConfirmPasswordError({
          active: true,
          text: 'Поле не заполнено'
        })
      }
    }
    else if (password.length < 5) {
      setPasswordError({
        active: true,
        text: 'Пароль должен содержать более 5 символов'
      })
    } else if (password !== confirmPassword) {
      setConfirmPasswordError({
        active: true,
        text: 'Пароли должны совпадать'
      })
      setPasswordError({
        active: true,
        text: 'Пароли должны совпадать'
      })
    } else {
      try {
        setLoading(true)
        await axios.post('https://api.vkusclick.ru/v1/employees/change_director_password/confirmation', {
          email,
          password,
          code
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        setLoading(false)
        setStep(step + 1)
        resetErrors()
      } catch (e: any) {
        setLoading(false)
        const error = e.response.data.message;
        if (error === 'Invalid confirmation code. You may be blocked if the error repeats' || '"code" length must be at least 5 characters long') {
          setCodeError({
            active: true,
            text: 'Неверный код. Вы можете быть заблокированы если ошибка повторится'
          })
        }
      }
    }

  }

  return (
    <div className={styles.wrap}>
      <div className={styles.topImg}>
        <svg xmlns="http://www.w3.org/2000/svg" width="239" height="234" viewBox="0 0 239 234" fill="none">
          <rect x="88.5605" y="0.700195" width="149" height="149" rx="19.5" stroke="#FF9F43" strokeOpacity="0.16"/>
          <rect x="0.621094" y="33.7607" width="200" height="200" rx="10" fill="#FF9F43" fillOpacity="0.08"/>
        </svg>
      </div>
      <div className={styles.bottomImg}>
        <svg xmlns="http://www.w3.org/2000/svg" width="181" height="181" viewBox="0 0 181 181" fill="none">
          <rect x="1.30469" y="1.44336" width="178" height="178" rx="19" stroke="#FF9F43" strokeOpacity="0.16" strokeWidth="2" strokeDasharray="8 8"/>
          <rect x="22.8047" y="22.9434" width="135" height="135" rx="10" fill="#FF9F43" fillOpacity="0.08"/>
        </svg>
      </div>
      <div className={styles.content} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349'}}>
        {
          step === 1 ?
            <svg className={styles.stepOne} xmlns="http://www.w3.org/2000/svg" width="450" height="102" viewBox="0 0 450 102" fill="none">
              <path opacity="0.2" d="M64.3336 32.25C60.5396 32.2489 56.8346 33.3988 53.7079 35.5477C50.5812 37.6967 48.18 40.7436 46.8214 44.286C45.4629 47.8284 45.2109 51.6995 46.0988 55.3881C46.9868 59.0767 48.9728 62.4092 51.7945 64.9453V64.9453C52.9703 62.63 54.7642 60.6854 56.9775 59.3271C59.1907 57.9688 61.7368 57.2499 64.3336 57.25C62.7884 57.25 61.278 56.7918 59.9932 55.9334C58.7085 55.0749 57.7071 53.8548 57.1158 52.4272C56.5245 50.9997 56.3698 49.4288 56.6712 47.9134C56.9727 46.3979 57.7167 45.0058 58.8093 43.9132C59.9019 42.8206 61.294 42.0766 62.8095 41.7751C64.3249 41.4737 65.8958 41.6284 67.3233 42.2197C68.7509 42.811 69.971 43.8124 70.8295 45.0971C71.6879 46.3819 72.1461 47.8923 72.1461 49.4375C72.1461 51.5095 71.323 53.4966 69.8579 54.9618C68.3928 56.4269 66.4056 57.25 64.3336 57.25C66.9304 57.2499 69.4765 57.9688 71.6897 59.3271C73.903 60.6854 75.6969 62.63 76.8727 64.9453C79.6944 62.4092 81.6805 59.0767 82.5684 55.3881C83.4563 51.6995 83.2043 47.8284 81.8458 44.286C80.4872 40.7436 78.086 37.6967 74.9593 35.5477C71.8326 33.3988 68.1276 32.2489 64.3336 32.25Z" fill="#FF9F43"/>
              <path d="M64.3335 57.25C68.6482 57.25 72.146 53.7522 72.146 49.4375C72.146 45.1228 68.6482 41.625 64.3335 41.625C60.0188 41.625 56.521 45.1228 56.521 49.4375C56.521 53.7522 60.0188 57.25 64.3335 57.25ZM64.3335 57.25C61.7366 57.25 59.1904 57.9679 56.9771 59.3264C54.7639 60.6849 52.97 62.6297 51.7944 64.9453M64.3335 57.25C66.9304 57.25 69.4766 57.9679 71.6899 59.3264C73.9031 60.6849 75.697 62.6297 76.8726 64.9453M83.0835 51C83.0835 61.3553 74.6888 69.75 64.3335 69.75C53.9782 69.75 45.5835 61.3553 45.5835 51C45.5835 40.6447 53.9782 32.25 64.3335 32.25C74.6888 32.25 83.0835 40.6447 83.0835 51Z" stroke="#FF9F43" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M141.667 45L147.667 51L141.667 57" stroke="#4B465C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M141.667 45L147.667 51L141.667 57" stroke="white" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <g opacity="0.2">
                <path d="M243.652 36.9375H206.348C205.431 36.9375 204.688 37.6808 204.688 38.5977V63.4023C204.688 64.3192 205.431 65.0625 206.348 65.0625H243.652C244.569 65.0625 245.312 64.3192 245.312 63.4023V38.5977C245.312 37.6808 244.569 36.9375 243.652 36.9375Z" fill="#4B465C"/>
                <path d="M243.652 36.9375H206.348C205.431 36.9375 204.688 37.6808 204.688 38.5977V63.4023C204.688 64.3192 205.431 65.0625 206.348 65.0625H243.652C244.569 65.0625 245.312 64.3192 245.312 63.4023V38.5977C245.312 37.6808 244.569 36.9375 243.652 36.9375Z" fill="white" fillOpacity="0.2"/>
              </g>
              <path fillRule="evenodd" clipRule="evenodd" d="M205.938 38.5977C205.938 38.3711 206.121 38.1875 206.348 38.1875H243.652C243.879 38.1875 244.062 38.3711 244.062 38.5977V63.4023C244.062 63.6289 243.879 63.8125 243.652 63.8125H206.348C206.121 63.8125 205.938 63.6289 205.938 63.4023V38.5977ZM206.348 35.6875C204.74 35.6875 203.438 36.9904 203.438 38.5977V63.4023C203.438 65.0096 204.74 66.3125 206.348 66.3125H243.652C245.26 66.3125 246.562 65.0096 246.562 63.4023V38.5977C246.562 36.9904 245.26 35.6875 243.652 35.6875H206.348ZM210.938 43.75C210.385 43.75 209.938 44.1977 209.938 44.75C209.938 45.3023 210.385 45.75 210.938 45.75H239.062C239.615 45.75 240.062 45.3023 240.062 44.75C240.062 44.1977 239.615 43.75 239.062 43.75H210.938ZM210.938 50C210.385 50 209.938 50.4477 209.938 51C209.938 51.5523 210.385 52 210.938 52H239.062C239.615 52 240.062 51.5523 240.062 51C240.062 50.4477 239.615 50 239.062 50H210.938ZM209.938 57.25C209.938 56.6977 210.385 56.25 210.938 56.25H212.5C213.052 56.25 213.5 56.6977 213.5 57.25C213.5 57.8023 213.052 58.25 212.5 58.25H210.938C210.385 58.25 209.938 57.8023 209.938 57.25ZM218.75 56.25C218.198 56.25 217.75 56.6977 217.75 57.25C217.75 57.8023 218.198 58.25 218.75 58.25H231.25C231.802 58.25 232.25 57.8023 232.25 57.25C232.25 56.6977 231.802 56.25 231.25 56.25H218.75ZM236.5 57.25C236.5 56.6977 236.948 56.25 237.5 56.25H239.062C239.615 56.25 240.062 56.6977 240.062 57.25C240.062 57.8023 239.615 58.25 239.062 58.25H237.5C236.948 58.25 236.5 57.8023 236.5 57.25Z" fill="#4B465C"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M205.938 38.5977C205.938 38.3711 206.121 38.1875 206.348 38.1875H243.652C243.879 38.1875 244.062 38.3711 244.062 38.5977V63.4023C244.062 63.6289 243.879 63.8125 243.652 63.8125H206.348C206.121 63.8125 205.938 63.6289 205.938 63.4023V38.5977ZM206.348 35.6875C204.74 35.6875 203.438 36.9904 203.438 38.5977V63.4023C203.438 65.0096 204.74 66.3125 206.348 66.3125H243.652C245.26 66.3125 246.562 65.0096 246.562 63.4023V38.5977C246.562 36.9904 245.26 35.6875 243.652 35.6875H206.348ZM210.938 43.75C210.385 43.75 209.938 44.1977 209.938 44.75C209.938 45.3023 210.385 45.75 210.938 45.75H239.062C239.615 45.75 240.062 45.3023 240.062 44.75C240.062 44.1977 239.615 43.75 239.062 43.75H210.938ZM210.938 50C210.385 50 209.938 50.4477 209.938 51C209.938 51.5523 210.385 52 210.938 52H239.062C239.615 52 240.062 51.5523 240.062 51C240.062 50.4477 239.615 50 239.062 50H210.938ZM209.938 57.25C209.938 56.6977 210.385 56.25 210.938 56.25H212.5C213.052 56.25 213.5 56.6977 213.5 57.25C213.5 57.8023 213.052 58.25 212.5 58.25H210.938C210.385 58.25 209.938 57.8023 209.938 57.25ZM218.75 56.25C218.198 56.25 217.75 56.6977 217.75 57.25C217.75 57.8023 218.198 58.25 218.75 58.25H231.25C231.802 58.25 232.25 57.8023 232.25 57.25C232.25 56.6977 231.802 56.25 231.25 56.25H218.75ZM236.5 57.25C236.5 56.6977 236.948 56.25 237.5 56.25H239.062C239.615 56.25 240.062 56.6977 240.062 57.25C240.062 57.8023 239.615 58.25 239.062 58.25H237.5C236.948 58.25 236.5 57.8023 236.5 57.25Z" fill="white" fillOpacity="0.2"/>
              <path d="M302.333 45L308.333 51L302.333 57" stroke="#4B465C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M302.333 45L308.333 51L302.333 57" stroke="white" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <g opacity="0.2">
                <path d="M371.312 65.3555C369.515 63.5586 370.706 59.7891 369.788 57.582C368.87 55.375 365.354 53.4414 365.354 51C365.354 48.5586 368.831 46.7031 369.788 44.418C370.745 42.1328 369.515 38.4414 371.312 36.6445C373.108 34.8477 376.878 36.0391 379.085 35.1211C381.292 34.2031 383.226 30.6875 385.667 30.6875C388.108 30.6875 389.964 34.1641 392.249 35.1211C394.534 36.0781 398.226 34.8477 400.022 36.6445C401.819 38.4414 400.628 42.2109 401.546 44.418C402.464 46.625 405.979 48.5586 405.979 51C405.979 53.4414 402.503 55.2969 401.546 57.582C400.589 59.8672 401.819 63.5586 400.022 65.3555C398.226 67.1523 394.456 65.9609 392.249 66.8789C390.042 67.7969 388.108 71.3125 385.667 71.3125C383.226 71.3125 381.37 67.8359 379.085 66.8789C376.8 65.9219 373.108 67.1523 371.312 65.3555Z" fill="#4B465C"/>
                <path d="M371.312 65.3555C369.515 63.5586 370.706 59.7891 369.788 57.582C368.87 55.375 365.354 53.4414 365.354 51C365.354 48.5586 368.831 46.7031 369.788 44.418C370.745 42.1328 369.515 38.4414 371.312 36.6445C373.108 34.8477 376.878 36.0391 379.085 35.1211C381.292 34.2031 383.226 30.6875 385.667 30.6875C388.108 30.6875 389.964 34.1641 392.249 35.1211C394.534 36.0781 398.226 34.8477 400.022 36.6445C401.819 38.4414 400.628 42.2109 401.546 44.418C402.464 46.625 405.979 48.5586 405.979 51C405.979 53.4414 402.503 55.2969 401.546 57.582C400.589 59.8672 401.819 63.5586 400.022 65.3555C398.226 67.1523 394.456 65.9609 392.249 66.8789C390.042 67.7969 388.108 71.3125 385.667 71.3125C383.226 71.3125 381.37 67.8359 379.085 66.8789C376.8 65.9219 373.108 67.1523 371.312 65.3555Z" fill="white" fillOpacity="0.2"/>
              </g>
              <path d="M394.261 46.3125L382.796 57.25L377.073 51.7812M371.312 65.3555C369.515 63.5586 370.706 59.7891 369.788 57.582C368.87 55.375 365.354 53.4414 365.354 51C365.354 48.5586 368.831 46.7031 369.788 44.418C370.745 42.1328 369.515 38.4414 371.312 36.6445C373.108 34.8477 376.878 36.0391 379.085 35.1211C381.292 34.2031 383.226 30.6875 385.667 30.6875C388.108 30.6875 389.964 34.1641 392.249 35.1211C394.534 36.0781 398.226 34.8477 400.022 36.6445C401.819 38.4414 400.628 42.2109 401.546 44.418C402.464 46.625 405.979 48.5586 405.979 51C405.979 53.4414 402.503 55.2969 401.546 57.582C400.589 59.8672 401.819 63.5586 400.022 65.3555C398.226 67.1523 394.456 65.9609 392.249 66.8789C390.042 67.7969 388.108 71.3125 385.667 71.3125C383.226 71.3125 381.37 67.8359 379.085 66.8789C376.8 65.9219 373.108 67.1523 371.312 65.3555Z" stroke="#4B465C" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M394.261 46.3125L382.796 57.25L377.073 51.7812M371.312 65.3555C369.515 63.5586 370.706 59.7891 369.788 57.582C368.87 55.375 365.354 53.4414 365.354 51C365.354 48.5586 368.831 46.7031 369.788 44.418C370.745 42.1328 369.515 38.4414 371.312 36.6445C373.108 34.8477 376.878 36.0391 379.085 35.1211C381.292 34.2031 383.226 30.6875 385.667 30.6875C388.108 30.6875 389.964 34.1641 392.249 35.1211C394.534 36.0781 398.226 34.8477 400.022 36.6445C401.819 38.4414 400.628 42.2109 401.546 44.418C402.464 46.625 405.979 48.5586 405.979 51C405.979 53.4414 402.503 55.2969 401.546 57.582C400.589 59.8672 401.819 63.5586 400.022 65.3555C398.226 67.1523 394.456 65.9609 392.249 66.8789C390.042 67.7969 388.108 71.3125 385.667 71.3125C383.226 71.3125 381.37 67.8359 379.085 66.8789C376.8 65.9219 373.108 67.1523 371.312 65.3555Z" stroke="white" strokeOpacity="0.2" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            : step === 2 ?
              <svg className={styles.stepTwo} xmlns="http://www.w3.org/2000/svg" width="450" height="102" viewBox="0 0 450 102" fill="none">
                <g opacity="0.2">
                  <path d="M64.3336 32.25C60.5396 32.2489 56.8346 33.3988 53.7079 35.5477C50.5812 37.6967 48.18 40.7436 46.8214 44.286C45.4629 47.8284 45.2109 51.6995 46.0988 55.3881C46.9868 59.0767 48.9728 62.4092 51.7945 64.9453V64.9453C52.9703 62.63 54.7642 60.6854 56.9775 59.3271C59.1907 57.9688 61.7368 57.2499 64.3336 57.25C62.7884 57.25 61.278 56.7918 59.9932 55.9334C58.7085 55.0749 57.7071 53.8548 57.1158 52.4272C56.5245 50.9997 56.3698 49.4288 56.6712 47.9134C56.9727 46.3979 57.7167 45.0058 58.8093 43.9132C59.9019 42.8206 61.294 42.0766 62.8095 41.7751C64.3249 41.4737 65.8958 41.6284 67.3233 42.2197C68.7509 42.811 69.971 43.8124 70.8295 45.0971C71.6879 46.3819 72.1461 47.8923 72.1461 49.4375C72.1461 51.5095 71.323 53.4966 69.8579 54.9618C68.3928 56.4269 66.4056 57.25 64.3336 57.25C66.9304 57.2499 69.4765 57.9688 71.6897 59.3271C73.903 60.6854 75.6969 62.63 76.8727 64.9453C79.6944 62.4092 81.6805 59.0767 82.5684 55.3881C83.4563 51.6995 83.2043 47.8284 81.8458 44.286C80.4872 40.7436 78.086 37.6967 74.9593 35.5477C71.8326 33.3988 68.1276 32.2489 64.3336 32.25Z" fill="#4B465C"/>
                  <path d="M64.3336 32.25C60.5396 32.2489 56.8346 33.3988 53.7079 35.5477C50.5812 37.6967 48.18 40.7436 46.8214 44.286C45.4629 47.8284 45.2109 51.6995 46.0988 55.3881C46.9868 59.0767 48.9728 62.4092 51.7945 64.9453V64.9453C52.9703 62.63 54.7642 60.6854 56.9775 59.3271C59.1907 57.9688 61.7368 57.2499 64.3336 57.25C62.7884 57.25 61.278 56.7918 59.9932 55.9334C58.7085 55.0749 57.7071 53.8548 57.1158 52.4272C56.5245 50.9997 56.3698 49.4288 56.6712 47.9134C56.9727 46.3979 57.7167 45.0058 58.8093 43.9132C59.9019 42.8206 61.294 42.0766 62.8095 41.7751C64.3249 41.4737 65.8958 41.6284 67.3233 42.2197C68.7509 42.811 69.971 43.8124 70.8295 45.0971C71.6879 46.3819 72.1461 47.8923 72.1461 49.4375C72.1461 51.5095 71.323 53.4966 69.8579 54.9618C68.3928 56.4269 66.4056 57.25 64.3336 57.25C66.9304 57.2499 69.4765 57.9688 71.6897 59.3271C73.903 60.6854 75.6969 62.63 76.8727 64.9453C79.6944 62.4092 81.6805 59.0767 82.5684 55.3881C83.4563 51.6995 83.2043 47.8284 81.8458 44.286C80.4872 40.7436 78.086 37.6967 74.9593 35.5477C71.8326 33.3988 68.1276 32.2489 64.3336 32.25Z" fill="white" fillOpacity="0.2"/>
                </g>
                <path d="M64.3335 57.25C68.6482 57.25 72.146 53.7522 72.146 49.4375C72.146 45.1228 68.6482 41.625 64.3335 41.625C60.0188 41.625 56.521 45.1228 56.521 49.4375C56.521 53.7522 60.0188 57.25 64.3335 57.25ZM64.3335 57.25C61.7366 57.25 59.1904 57.9679 56.9771 59.3264C54.7639 60.6849 52.97 62.6297 51.7944 64.9453M64.3335 57.25C66.9304 57.25 69.4766 57.9679 71.6899 59.3264C73.9031 60.6849 75.697 62.6297 76.8726 64.9453M83.0835 51C83.0835 61.3553 74.6888 69.75 64.3335 69.75C53.9782 69.75 45.5835 61.3553 45.5835 51C45.5835 40.6447 53.9782 32.25 64.3335 32.25C74.6888 32.25 83.0835 40.6447 83.0835 51Z" stroke="#4B465C" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M64.3335 57.25C68.6482 57.25 72.146 53.7522 72.146 49.4375C72.146 45.1228 68.6482 41.625 64.3335 41.625C60.0188 41.625 56.521 45.1228 56.521 49.4375C56.521 53.7522 60.0188 57.25 64.3335 57.25ZM64.3335 57.25C61.7366 57.25 59.1904 57.9679 56.9771 59.3264C54.7639 60.6849 52.97 62.6297 51.7944 64.9453M64.3335 57.25C66.9304 57.25 69.4766 57.9679 71.6899 59.3264C73.9031 60.6849 75.697 62.6297 76.8726 64.9453M83.0835 51C83.0835 61.3553 74.6888 69.75 64.3335 69.75C53.9782 69.75 45.5835 61.3553 45.5835 51C45.5835 40.6447 53.9782 32.25 64.3335 32.25C74.6888 32.25 83.0835 40.6447 83.0835 51Z" stroke="white" strokeOpacity="0.2" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M141.667 45L147.667 51L141.667 57" stroke="#4B465C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M141.667 45L147.667 51L141.667 57" stroke="white" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path opacity="0.2" d="M243.652 36.9375H206.348C205.431 36.9375 204.688 37.6808 204.688 38.5977V63.4023C204.688 64.3192 205.431 65.0625 206.348 65.0625H243.652C244.569 65.0625 245.312 64.3192 245.312 63.4023V38.5977C245.312 37.6808 244.569 36.9375 243.652 36.9375Z" fill="#FF9F43"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M205.938 38.5977C205.938 38.3711 206.121 38.1875 206.348 38.1875H243.652C243.879 38.1875 244.062 38.3711 244.062 38.5977V63.4023C244.062 63.6289 243.879 63.8125 243.652 63.8125H206.348C206.121 63.8125 205.938 63.6289 205.938 63.4023V38.5977ZM206.348 35.6875C204.74 35.6875 203.438 36.9904 203.438 38.5977V63.4023C203.438 65.0096 204.74 66.3125 206.348 66.3125H243.652C245.26 66.3125 246.562 65.0096 246.562 63.4023V38.5977C246.562 36.9904 245.26 35.6875 243.652 35.6875H206.348ZM210.938 43.75C210.385 43.75 209.938 44.1977 209.938 44.75C209.938 45.3023 210.385 45.75 210.938 45.75H239.062C239.615 45.75 240.062 45.3023 240.062 44.75C240.062 44.1977 239.615 43.75 239.062 43.75H210.938ZM210.938 50C210.385 50 209.938 50.4477 209.938 51C209.938 51.5523 210.385 52 210.938 52H239.062C239.615 52 240.062 51.5523 240.062 51C240.062 50.4477 239.615 50 239.062 50H210.938ZM209.938 57.25C209.938 56.6977 210.385 56.25 210.938 56.25H212.5C213.052 56.25 213.5 56.6977 213.5 57.25C213.5 57.8023 213.052 58.25 212.5 58.25H210.938C210.385 58.25 209.938 57.8023 209.938 57.25ZM218.75 56.25C218.198 56.25 217.75 56.6977 217.75 57.25C217.75 57.8023 218.198 58.25 218.75 58.25H231.25C231.802 58.25 232.25 57.8023 232.25 57.25C232.25 56.6977 231.802 56.25 231.25 56.25H218.75ZM236.5 57.25C236.5 56.6977 236.948 56.25 237.5 56.25H239.062C239.615 56.25 240.062 56.6977 240.062 57.25C240.062 57.8023 239.615 58.25 239.062 58.25H237.5C236.948 58.25 236.5 57.8023 236.5 57.25Z" fill="#FF9F43"/>
                <path d="M302.333 45L308.333 51L302.333 57" stroke="#4B465C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M302.333 45L308.333 51L302.333 57" stroke="white" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <g opacity="0.2">
                  <path d="M371.312 65.3555C369.515 63.5586 370.706 59.7891 369.788 57.582C368.87 55.375 365.354 53.4414 365.354 51C365.354 48.5586 368.831 46.7031 369.788 44.418C370.745 42.1328 369.515 38.4414 371.312 36.6445C373.108 34.8477 376.878 36.0391 379.085 35.1211C381.292 34.2031 383.226 30.6875 385.667 30.6875C388.108 30.6875 389.964 34.1641 392.249 35.1211C394.534 36.0781 398.226 34.8477 400.022 36.6445C401.819 38.4414 400.628 42.2109 401.546 44.418C402.464 46.625 405.979 48.5586 405.979 51C405.979 53.4414 402.503 55.2969 401.546 57.582C400.589 59.8672 401.819 63.5586 400.022 65.3555C398.226 67.1523 394.456 65.9609 392.249 66.8789C390.042 67.7969 388.108 71.3125 385.667 71.3125C383.226 71.3125 381.37 67.8359 379.085 66.8789C376.8 65.9219 373.108 67.1523 371.312 65.3555Z" fill="#4B465C"/>
                  <path d="M371.312 65.3555C369.515 63.5586 370.706 59.7891 369.788 57.582C368.87 55.375 365.354 53.4414 365.354 51C365.354 48.5586 368.831 46.7031 369.788 44.418C370.745 42.1328 369.515 38.4414 371.312 36.6445C373.108 34.8477 376.878 36.0391 379.085 35.1211C381.292 34.2031 383.226 30.6875 385.667 30.6875C388.108 30.6875 389.964 34.1641 392.249 35.1211C394.534 36.0781 398.226 34.8477 400.022 36.6445C401.819 38.4414 400.628 42.2109 401.546 44.418C402.464 46.625 405.979 48.5586 405.979 51C405.979 53.4414 402.503 55.2969 401.546 57.582C400.589 59.8672 401.819 63.5586 400.022 65.3555C398.226 67.1523 394.456 65.9609 392.249 66.8789C390.042 67.7969 388.108 71.3125 385.667 71.3125C383.226 71.3125 381.37 67.8359 379.085 66.8789C376.8 65.9219 373.108 67.1523 371.312 65.3555Z" fill="white" fillOpacity="0.2"/>
                </g>
                <path d="M394.261 46.3125L382.796 57.25L377.073 51.7812M371.312 65.3555C369.515 63.5586 370.706 59.7891 369.788 57.582C368.87 55.375 365.354 53.4414 365.354 51C365.354 48.5586 368.831 46.7031 369.788 44.418C370.745 42.1328 369.515 38.4414 371.312 36.6445C373.108 34.8477 376.878 36.0391 379.085 35.1211C381.292 34.2031 383.226 30.6875 385.667 30.6875C388.108 30.6875 389.964 34.1641 392.249 35.1211C394.534 36.0781 398.226 34.8477 400.022 36.6445C401.819 38.4414 400.628 42.2109 401.546 44.418C402.464 46.625 405.979 48.5586 405.979 51C405.979 53.4414 402.503 55.2969 401.546 57.582C400.589 59.8672 401.819 63.5586 400.022 65.3555C398.226 67.1523 394.456 65.9609 392.249 66.8789C390.042 67.7969 388.108 71.3125 385.667 71.3125C383.226 71.3125 381.37 67.8359 379.085 66.8789C376.8 65.9219 373.108 67.1523 371.312 65.3555Z" stroke="#4B465C" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M394.261 46.3125L382.796 57.25L377.073 51.7812M371.312 65.3555C369.515 63.5586 370.706 59.7891 369.788 57.582C368.87 55.375 365.354 53.4414 365.354 51C365.354 48.5586 368.831 46.7031 369.788 44.418C370.745 42.1328 369.515 38.4414 371.312 36.6445C373.108 34.8477 376.878 36.0391 379.085 35.1211C381.292 34.2031 383.226 30.6875 385.667 30.6875C388.108 30.6875 389.964 34.1641 392.249 35.1211C394.534 36.0781 398.226 34.8477 400.022 36.6445C401.819 38.4414 400.628 42.2109 401.546 44.418C402.464 46.625 405.979 48.5586 405.979 51C405.979 53.4414 402.503 55.2969 401.546 57.582C400.589 59.8672 401.819 63.5586 400.022 65.3555C398.226 67.1523 394.456 65.9609 392.249 66.8789C390.042 67.7969 388.108 71.3125 385.667 71.3125C383.226 71.3125 381.37 67.8359 379.085 66.8789C376.8 65.9219 373.108 67.1523 371.312 65.3555Z" stroke="white" strokeOpacity="0.2" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              : <svg className={styles.setThree} xmlns="http://www.w3.org/2000/svg" width="450" height="102" viewBox="0 0 450 102" fill="none">
                <g opacity="0.2">
                  <path d="M64.3336 32.25C60.5396 32.2489 56.8346 33.3988 53.7079 35.5477C50.5812 37.6967 48.18 40.7436 46.8214 44.286C45.4629 47.8284 45.2109 51.6995 46.0988 55.3881C46.9868 59.0767 48.9728 62.4092 51.7945 64.9453V64.9453C52.9703 62.63 54.7642 60.6854 56.9775 59.3271C59.1907 57.9688 61.7368 57.2499 64.3336 57.25C62.7884 57.25 61.278 56.7918 59.9932 55.9334C58.7085 55.0749 57.7071 53.8548 57.1158 52.4272C56.5245 50.9997 56.3698 49.4288 56.6712 47.9134C56.9727 46.3979 57.7167 45.0058 58.8093 43.9132C59.9019 42.8206 61.294 42.0766 62.8095 41.7751C64.3249 41.4737 65.8958 41.6284 67.3233 42.2197C68.7509 42.811 69.971 43.8124 70.8295 45.0971C71.6879 46.3819 72.1461 47.8923 72.1461 49.4375C72.1461 51.5095 71.323 53.4966 69.8579 54.9618C68.3928 56.4269 66.4056 57.25 64.3336 57.25C66.9304 57.2499 69.4765 57.9688 71.6897 59.3271C73.903 60.6854 75.6969 62.63 76.8727 64.9453C79.6944 62.4092 81.6805 59.0767 82.5684 55.3881C83.4563 51.6995 83.2043 47.8284 81.8458 44.286C80.4872 40.7436 78.086 37.6967 74.9593 35.5477C71.8326 33.3988 68.1276 32.2489 64.3336 32.25Z" fill="#4B465C"/>
                  <path d="M64.3336 32.25C60.5396 32.2489 56.8346 33.3988 53.7079 35.5477C50.5812 37.6967 48.18 40.7436 46.8214 44.286C45.4629 47.8284 45.2109 51.6995 46.0988 55.3881C46.9868 59.0767 48.9728 62.4092 51.7945 64.9453V64.9453C52.9703 62.63 54.7642 60.6854 56.9775 59.3271C59.1907 57.9688 61.7368 57.2499 64.3336 57.25C62.7884 57.25 61.278 56.7918 59.9932 55.9334C58.7085 55.0749 57.7071 53.8548 57.1158 52.4272C56.5245 50.9997 56.3698 49.4288 56.6712 47.9134C56.9727 46.3979 57.7167 45.0058 58.8093 43.9132C59.9019 42.8206 61.294 42.0766 62.8095 41.7751C64.3249 41.4737 65.8958 41.6284 67.3233 42.2197C68.7509 42.811 69.971 43.8124 70.8295 45.0971C71.6879 46.3819 72.1461 47.8923 72.1461 49.4375C72.1461 51.5095 71.323 53.4966 69.8579 54.9618C68.3928 56.4269 66.4056 57.25 64.3336 57.25C66.9304 57.2499 69.4765 57.9688 71.6897 59.3271C73.903 60.6854 75.6969 62.63 76.8727 64.9453C79.6944 62.4092 81.6805 59.0767 82.5684 55.3881C83.4563 51.6995 83.2043 47.8284 81.8458 44.286C80.4872 40.7436 78.086 37.6967 74.9593 35.5477C71.8326 33.3988 68.1276 32.2489 64.3336 32.25Z" fill="white" fillOpacity="0.2"/>
                </g>
                <path d="M64.3335 57.25C68.6482 57.25 72.146 53.7522 72.146 49.4375C72.146 45.1228 68.6482 41.625 64.3335 41.625C60.0188 41.625 56.521 45.1228 56.521 49.4375C56.521 53.7522 60.0188 57.25 64.3335 57.25ZM64.3335 57.25C61.7366 57.25 59.1904 57.9679 56.9771 59.3264C54.7639 60.6849 52.97 62.6297 51.7944 64.9453M64.3335 57.25C66.9304 57.25 69.4766 57.9679 71.6899 59.3264C73.9031 60.6849 75.697 62.6297 76.8726 64.9453M83.0835 51C83.0835 61.3553 74.6888 69.75 64.3335 69.75C53.9782 69.75 45.5835 61.3553 45.5835 51C45.5835 40.6447 53.9782 32.25 64.3335 32.25C74.6888 32.25 83.0835 40.6447 83.0835 51Z" stroke="#4B465C" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M64.3335 57.25C68.6482 57.25 72.146 53.7522 72.146 49.4375C72.146 45.1228 68.6482 41.625 64.3335 41.625C60.0188 41.625 56.521 45.1228 56.521 49.4375C56.521 53.7522 60.0188 57.25 64.3335 57.25ZM64.3335 57.25C61.7366 57.25 59.1904 57.9679 56.9771 59.3264C54.7639 60.6849 52.97 62.6297 51.7944 64.9453M64.3335 57.25C66.9304 57.25 69.4766 57.9679 71.6899 59.3264C73.9031 60.6849 75.697 62.6297 76.8726 64.9453M83.0835 51C83.0835 61.3553 74.6888 69.75 64.3335 69.75C53.9782 69.75 45.5835 61.3553 45.5835 51C45.5835 40.6447 53.9782 32.25 64.3335 32.25C74.6888 32.25 83.0835 40.6447 83.0835 51Z" stroke="white" strokeOpacity="0.2" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M141.667 45L147.667 51L141.667 57" stroke="#4B465C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M141.667 45L147.667 51L141.667 57" stroke="white" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <g opacity="0.2">
                  <path d="M243.652 36.9375H206.348C205.431 36.9375 204.688 37.6808 204.688 38.5977V63.4023C204.688 64.3192 205.431 65.0625 206.348 65.0625H243.652C244.569 65.0625 245.312 64.3192 245.312 63.4023V38.5977C245.312 37.6808 244.569 36.9375 243.652 36.9375Z" fill="#4B465C"/>
                  <path d="M243.652 36.9375H206.348C205.431 36.9375 204.688 37.6808 204.688 38.5977V63.4023C204.688 64.3192 205.431 65.0625 206.348 65.0625H243.652C244.569 65.0625 245.312 64.3192 245.312 63.4023V38.5977C245.312 37.6808 244.569 36.9375 243.652 36.9375Z" fill="white" fillOpacity="0.2"/>
                </g>
                <path fillRule="evenodd" clipRule="evenodd" d="M205.938 38.5977C205.938 38.3711 206.121 38.1875 206.348 38.1875H243.652C243.879 38.1875 244.062 38.3711 244.062 38.5977V63.4023C244.062 63.6289 243.879 63.8125 243.652 63.8125H206.348C206.121 63.8125 205.938 63.6289 205.938 63.4023V38.5977ZM206.348 35.6875C204.74 35.6875 203.438 36.9904 203.438 38.5977V63.4023C203.438 65.0096 204.74 66.3125 206.348 66.3125H243.652C245.26 66.3125 246.562 65.0096 246.562 63.4023V38.5977C246.562 36.9904 245.26 35.6875 243.652 35.6875H206.348ZM210.938 43.75C210.385 43.75 209.938 44.1977 209.938 44.75C209.938 45.3023 210.385 45.75 210.938 45.75H239.062C239.615 45.75 240.062 45.3023 240.062 44.75C240.062 44.1977 239.615 43.75 239.062 43.75H210.938ZM210.938 50C210.385 50 209.938 50.4477 209.938 51C209.938 51.5523 210.385 52 210.938 52H239.062C239.615 52 240.062 51.5523 240.062 51C240.062 50.4477 239.615 50 239.062 50H210.938ZM209.938 57.25C209.938 56.6977 210.385 56.25 210.938 56.25H212.5C213.052 56.25 213.5 56.6977 213.5 57.25C213.5 57.8023 213.052 58.25 212.5 58.25H210.938C210.385 58.25 209.938 57.8023 209.938 57.25ZM218.75 56.25C218.198 56.25 217.75 56.6977 217.75 57.25C217.75 57.8023 218.198 58.25 218.75 58.25H231.25C231.802 58.25 232.25 57.8023 232.25 57.25C232.25 56.6977 231.802 56.25 231.25 56.25H218.75ZM236.5 57.25C236.5 56.6977 236.948 56.25 237.5 56.25H239.062C239.615 56.25 240.062 56.6977 240.062 57.25C240.062 57.8023 239.615 58.25 239.062 58.25H237.5C236.948 58.25 236.5 57.8023 236.5 57.25Z" fill="#4B465C"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M205.938 38.5977C205.938 38.3711 206.121 38.1875 206.348 38.1875H243.652C243.879 38.1875 244.062 38.3711 244.062 38.5977V63.4023C244.062 63.6289 243.879 63.8125 243.652 63.8125H206.348C206.121 63.8125 205.938 63.6289 205.938 63.4023V38.5977ZM206.348 35.6875C204.74 35.6875 203.438 36.9904 203.438 38.5977V63.4023C203.438 65.0096 204.74 66.3125 206.348 66.3125H243.652C245.26 66.3125 246.562 65.0096 246.562 63.4023V38.5977C246.562 36.9904 245.26 35.6875 243.652 35.6875H206.348ZM210.938 43.75C210.385 43.75 209.938 44.1977 209.938 44.75C209.938 45.3023 210.385 45.75 210.938 45.75H239.062C239.615 45.75 240.062 45.3023 240.062 44.75C240.062 44.1977 239.615 43.75 239.062 43.75H210.938ZM210.938 50C210.385 50 209.938 50.4477 209.938 51C209.938 51.5523 210.385 52 210.938 52H239.062C239.615 52 240.062 51.5523 240.062 51C240.062 50.4477 239.615 50 239.062 50H210.938ZM209.938 57.25C209.938 56.6977 210.385 56.25 210.938 56.25H212.5C213.052 56.25 213.5 56.6977 213.5 57.25C213.5 57.8023 213.052 58.25 212.5 58.25H210.938C210.385 58.25 209.938 57.8023 209.938 57.25ZM218.75 56.25C218.198 56.25 217.75 56.6977 217.75 57.25C217.75 57.8023 218.198 58.25 218.75 58.25H231.25C231.802 58.25 232.25 57.8023 232.25 57.25C232.25 56.6977 231.802 56.25 231.25 56.25H218.75ZM236.5 57.25C236.5 56.6977 236.948 56.25 237.5 56.25H239.062C239.615 56.25 240.062 56.6977 240.062 57.25C240.062 57.8023 239.615 58.25 239.062 58.25H237.5C236.948 58.25 236.5 57.8023 236.5 57.25Z" fill="white" fillOpacity="0.2"/>
                <path d="M302.333 45L308.333 51L302.333 57" stroke="#4B465C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M302.333 45L308.333 51L302.333 57" stroke="white" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path opacity="0.2" d="M371.312 65.3555C369.515 63.5586 370.706 59.7891 369.788 57.582C368.87 55.375 365.354 53.4414 365.354 51C365.354 48.5586 368.831 46.7031 369.788 44.418C370.745 42.1328 369.515 38.4414 371.312 36.6445C373.108 34.8477 376.878 36.0391 379.085 35.1211C381.292 34.2031 383.226 30.6875 385.667 30.6875C388.108 30.6875 389.964 34.1641 392.249 35.1211C394.534 36.0781 398.226 34.8477 400.022 36.6445C401.819 38.4414 400.628 42.2109 401.546 44.418C402.464 46.625 405.979 48.5586 405.979 51C405.979 53.4414 402.503 55.2969 401.546 57.582C400.589 59.8672 401.819 63.5586 400.022 65.3555C398.226 67.1523 394.456 65.9609 392.249 66.8789C390.042 67.7969 388.108 71.3125 385.667 71.3125C383.226 71.3125 381.37 67.8359 379.085 66.8789C376.8 65.9219 373.108 67.1523 371.312 65.3555Z" fill="#FF9F43"/>
                <path d="M394.261 46.3125L382.796 57.25L377.073 51.7812M371.312 65.3555C369.515 63.5586 370.706 59.7891 369.788 57.582C368.87 55.375 365.354 53.4414 365.354 51C365.354 48.5586 368.831 46.7031 369.788 44.418C370.745 42.1328 369.515 38.4414 371.312 36.6445C373.108 34.8477 376.878 36.0391 379.085 35.1211C381.292 34.2031 383.226 30.6875 385.667 30.6875C388.108 30.6875 389.964 34.1641 392.249 35.1211C394.534 36.0781 398.226 34.8477 400.022 36.6445C401.819 38.4414 400.628 42.2109 401.546 44.418C402.464 46.625 405.979 48.5586 405.979 51C405.979 53.4414 402.503 55.2969 401.546 57.582C400.589 59.8672 401.819 63.5586 400.022 65.3555C398.226 67.1523 394.456 65.9609 392.249 66.8789C390.042 67.7969 388.108 71.3125 385.667 71.3125C383.226 71.3125 381.37 67.8359 379.085 66.8789C376.8 65.9219 373.108 67.1523 371.312 65.3555Z" stroke="#FF9F43" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
        }
        {
          step === 1 ?
            <div className={styles.inputWrap}>
              <p className={styles.inputTitle}>Email</p>
              <Input changeFunc={changeEmail} value={email} placeholder={'Email'} type={'email'} errorFunc={setEmailError} error={emailError}/>
            </div>
            : step === 2 ?
              <>
                <div className={styles.inputWrap}>
                  <p className={styles.inputTitle}>Код подтверждения</p>
                  <Input changeFunc={changeCode} value={code} placeholder={'Код'} type={'text'} errorFunc={setCodeError} error={codeError}/>
                </div>
                <div className={styles.inputs}>
                  <div className={styles.inputWrap}>
                    <p className={styles.inputTitle}>Новый пароль</p>
                    <Input changeFunc={changePassword} value={password} placeholder={'············'} type={'password'} errorFunc={(error: {active: boolean, text: string}) => {
                      setPasswordError({...error})
                      setConfirmPasswordError({...error})
                    }} error={passwordError}/>
                  </div>
                  <div className={styles.inputWrap}>
                    <p className={styles.inputTitle}>Повторите новый пароль</p>
                    <Input changeFunc={changeConfirmPassword} value={confirmPassword} placeholder={'············'} type={'password'} errorFunc={(error: {active: boolean, text: string}) => {
                      setConfirmPasswordError({...error})
                      setPasswordError({...error})
                    }} error={confirmPasswordError}/>
                  </div>
                </div>
                <button onClick={repeatCode} style={{borderColor: theme === 'light' ? '#A8AAAE' : '#CFD3EC', color: theme === 'light' ? '#A8AAAE' : '#CFD3EC', cursor: seconds > 0 ? 'default' : 'pointer'}} className={styles.code} disabled={seconds > 0}>{seconds > 0 ? `Отправить код повторно 00:${seconds > 9 ? seconds : `0${seconds}`}` : 'Отправить код повторно'}</button>
              </>
              : <div className={styles.inputWrap}>
                <p className={styles.title}>Пароль успешно изменен!</p>
              </div>
        }
        <button onClick={() => {
          if(step === 3) {
            navigate('/sign-in')
          } else if (step === 1) {
            sendEmailToReset().then(r => console.log(r))
          } else if (step === 2) {
            sendCodeAndPassword().then(r => console.log(r))
          }
        }} style={{backgroundColor: "#ff9f43"}} className={styles.signBtn}><p>
          {
            loading ?
              <img src={loader} alt=""/> :
              step === 1 ?
              'Отправить код' :
              step === 2 ?
                'Применить' :
                'Хорошо!'
          }
        </p></button>
      </div>
    </div>
  );
}

export default Index;
