import React from 'react';
import PageTitle from "../../Components/PageTitle";
import AddToOrderContent from "../../Components/AddToOrderContent";
import {Typography} from "@mui/material";
import {Icon} from "@iconify/react";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";

function Index() {
  const {theme} = useSelector((state: RootState) => state.MainSlice);

  return (
    <div className={'popular-products-page'}>
      <div className="title-flex">
        <PageTitle title={'Добавить к заказу'}/>
        <button className={theme}>
          <span style={{boxShadow: theme === 'light'  ? `0px 2px 4px rgba(165, 163, 174, 0.3)` : '0px 2px 4px rgba(15, 20, 34, 0.4)'}}><p>Отображаются на странице оформления заказа в меню</p></span>
          <Icon color={theme === 'light' ? '#4B465C66' : '#8692D066'} icon="bi:info-circle" />
        </button>
      </div>
      <AddToOrderContent/>
    </div>
  );
}

export default Index;
