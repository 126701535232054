
export const pay = function ({payment, user}) {
  // eslint-disable-next-line no-undef
  var widget = new cp.CloudPayments();
  var amountValue = payment.price;

  const receipt = {
    "Items": [
      {
        "label": "Пополнение баланса организации", //наименование товара
        "price":  parseFloat(amountValue), //цена
        "quantity": 1, //количество
        "amount": parseFloat(amountValue),
        "vat": null, //ставка НДС
        "method": 0, // тег-1214 признак способа расчета - признак способа расчета
        "object": 0, // тег-1212 признак предмета расчета - признак предмета товара, работы, услуги, платежа, выплаты, иного предмета расчета
        "measurementUnit": "шт" //единица измерения 1
      },
    ],
    "taxationSystem": 1,
    "email":  user.email, //e-mail покупателя, если нужно отправить письмо с чеком
    "phone": user.phone, //телефон покупателя в любом формате, если нужно отправить сообщение со ссылкой на чек
    "amounts":
      {
        "electronic": parseFloat(amountValue), // Сумма оплаты электронными деньгами
        "advancePayment": 0.00, // Сумма из предоплаты (зачетом аванса) (2 знака после запятой)
        "credit": 0.00, // Сумма постоплатой(в кредит) (2 знака после запятой)
        "provision": 0.00 // Сумма оплаты встречным предоставлением (сертификаты, др. мат.ценности) (2 знака после запятой)
      }
  }

  console.log(receipt);

  widget.pay('charge', // или 'charge'
    { //options
      publicId: 'pk_1d2893c62fcc02b62e3761ff6e9b4', //id из личного кабинета
      description: 'Пополнение баланса организации', //назначение
      amount: parseFloat(amountValue), //сумма
      currency: 'RUB', //
      email: user.email,
      data: {
        companyId: user.companyId, //id компании
        directorId: user.id, //id директора
        CloudPayments: {
          item: {
            label: 'Пополнение баланса организации', //наименование товара
            price: parseFloat(amountValue), //цена
            quantity: 1, //количество
            amount: parseFloat(amountValue), //сумма
            vat: null, //ставка НДС
            method: 0, // тег-1214 признак способа расчета - признак способа расчета
            object: 0, // тег-1212 признак предмета расчета - признак предмета товара, работы, услуги, платежа, выплаты, иного предмета расчета
            measurementUnit: "шт" //единица измерения
          },
          CustomerReceipt: receipt,
        }
      },
      payer: {
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
        email: user.email
      }
    }
  ).then(() => {
    window.location.reload();
  })
};


