import React, { useEffect, useState } from 'react';
import styles from './CalculatorBlock.module.scss';
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";


const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 18,
  height: 18,
  boxShadow: 'none',
  backgroundColor: 'transparent',
  border: '1px solid',
  '.Mui-focusVisible &': {
    outlineOffset: 2,
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'transparent',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#FF9F43',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  border: 'none',
  '&:before': {
    display: 'block',
    height: 18,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#FF9F43',
  },
});

function Index({ basePrice, item, setActiveModule, activeModule, little, changeTotalPrice, totalPrice }: { basePrice?: number, setActiveModule?: any, activeModule?: any, item: any, little?: boolean, totalPrice: number, changeTotalPrice: any }) {

  const { theme } = useSelector((state: RootState) => state.MainSlice);
  const { company } = useSelector((state: RootState) => state.UserSlice);

  const [checked, setCheck] = useState(false);

  useEffect(() => {
    if (activeModule === item._id) {
      setCheck(true)
    } else {
      setCheck(false)
    }
  }, [activeModule])

  useEffect(() => {
    if (company) {
      company.subscriptionModulesId.map((module: any) => {
        if (item._id === module) {
          //if (item.name !== 'start_tariff') {
            setActiveModule(item._id)
          //}
        }
      })
    }
  }, [company, basePrice])

  return (
    <div onClick={() => {
      if (!item.inDevelopment) {
        if (activeModule === item._id) {
          if (item.type !== 'tariff') {
            setActiveModule('');
          }
        } else {
          setActiveModule(item._id);
        }
      }
    }} className={`${styles.block} ${item.inDevelopment ? styles.disabled : ''}`} style={{ backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 4px 18px rgba(75, 70, 92, 0.1)', borderColor: checked ? '#FF9F43' : theme === 'light' ? '#CFD3EC' : '#4B465C' }}>
      {
        !item.inDevelopment &&
        <Checkbox
          sx={{
            '&:hover': { bgcolor: 'transparent' },
          }}
          disableRipple
          color="default"
          checkedIcon={<BpCheckedIcon />}
          icon={<BpIcon />}
          inputProps={{ 'aria-label': 'Checkbox demo' }}
          className={`${styles.checkBox} ${theme === 'light' ? styles.light : styles.dark}`}
          checked={checked}
        />
      }
      <p className={styles.title}>{item.title}</p>
      <p className={styles.text} style={{ height: little ? '44px' : '', overflowY: little ? 'hidden' : 'auto' }}>{item.description}</p>
      
      {
        item.type === 'tariff' &&
        item.options.map((option: any) => (
          <p className={`${styles.option} ${option.available ? '' : styles.disable}`}>
            {
              option.available ? 
              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24"><path fill="#30c033" d="m9.55 18l-5.7-5.7l1.425-1.425L9.55 15.15l9.175-9.175L20.15 7.4L9.55 18Z"/></svg> :
              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24"><path fill="#b6b6b6" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6L6.4 19Z"/></svg>
            }
            {option.name}
          </p>
        ))
      }
      <div className={styles.bottom}>
        {
          item.inDevelopment ?
            <p>В разработке</p> :
            <>
              <p className={styles.price}>{item.priceDay} руб./день</p>
              <p className={styles.price}>{(item.priceDay * 30).toFixed(2)} руб./месяц</p>
            </>
        }
      </div>
    </div>
  );
}

export default Index;
