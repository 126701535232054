import React, {useEffect, useState} from 'react';
import styles from '../ServiceModal/ServiceModal.module.scss';
import styles2 from './BalanceModal.module.scss'
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {Typography} from "@mui/material";
import {Icon} from "@iconify/react";
import RangeInput from "../RangeInput/Index";
import axios from "axios";
import {pay} from "../../Hooks/pay";

function Index({setActive, nextActive, balance, activeModule}: {activeModule: any, setActive: any, nextActive: any, balance: any}) {

  const {theme, selectRestaurantId} = useSelector((state: RootState) => state.MainSlice);
  const {accessToken, user, company} = useSelector((state: RootState) => state.UserSlice);

  const [month, changeMonth] = useState(1)
  const [code, changeCode] = useState('');


  const [codeError, setCodeError] = useState({
    active: false,
    text: ''
  })

  return (
    <div className={styles.wrap}>
      <span className={styles.bg} onClick={() => setActive(false)}/>
      <div className={styles.content} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 4px 18px rgba(75, 70, 92, 0.1)', maxWidth: '550px'}}>
        <button onClick={() => setActive(false)} className={styles.close} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 2px 4px rgba(165, 163, 174, 0.3)' : '0px 4px 18px rgba(75, 70, 92, 0.1)'}}>
          <Icon width={20} icon={'tabler:x'} color={theme === 'light' ? '#afaeae' : '#7a7fb4'}/>
        </button>
        <p className={styles.title}>Оплата подписки</p>
        <input disabled style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}} type="text" className={styles2.inputMonth} value={'Кол-во месяцев: ' + (parseInt(String(month)) + 1)} placeholder={'Placeholder'}/>
        <RangeInput maxValue={11} minValue={0} value={month} changeValue={changeMonth} title={'Title'} abr={'Abr'} percent={false} loading={false}/>
        <p className={styles.price} style={{textAlign: 'right', margin: '32px 0'}}>{`${(balance * 30).toFixed(2)} руб/мес * ${parseInt(String(month)) + 1} мес = ${((balance * (parseInt(String(month)) + 1)) * 30).toFixed(2)} руб`}</p>
        
        { user?.role === 'demo' ? (
          <button title='Недоступно в демонстрационном заведении' className={styles.send} style={{cursor: 'not-allowed'}}>Оплатить</button>
        ) : (
        <button onClick={() => {
          setActive(false);
          pay({
            payment: {
              price: ((balance * (parseInt(String(month)) + 1)) * 30).toFixed(2),
              count: month + 1,
            },
            user: {
              firstName: user?.firstName,
              lastName: user?.lastName,
              phone: user?.phone,
              email: user?.email,
              companyId: company._id,
              id: user?._id
            }
          })
        }} className={styles.send}>Оплатить</button>
        )}
      </div>
    </div>
  );
}

export default Index;
