import React from 'react';
import styles from './CategoryListStatistic.module.scss';
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {Typography, useTheme} from "@mui/material";
import {Icon} from "@iconify/react";

function Index() {

  const {theme, categories} = useSelector((state: RootState) => state.MainSlice);
  const MuiTheme = useTheme();

  return (
    <div className={styles.content} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 4px 18px rgba(75, 70, 92, 0.1)'}}>
      <div className={styles.tableTop} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}}>
        <div className={styles.colLarge}>
          <p className={styles.title}>Название</p>
          <div className={styles.sortBtns}>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-up" />
            </button>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-down" />
            </button>
          </div>
        </div>
        <div className={styles.colLarge}>
          <p className={styles.title}>количество просмотров</p>
          <div className={styles.sortBtns}>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-up" />
            </button>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-down" />
            </button>
          </div>
        </div>
        <div className={styles.colLarge}>
          <p className={styles.title}>Статус</p>
          <div className={styles.sortBtns}>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-up" />
            </button>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-down" />
            </button>
          </div>
        </div>
        <div className={styles.colLarge}>
          <p className={styles.title}>Последнее изменение</p>
          <div className={styles.sortBtns}>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-up" />
            </button>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-down" />
            </button>
          </div>
        </div>
      </div>
      <div className={styles.tableContent}>
        {categories?.map(item => {
          return (
            <div style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}} key={item['_id']} className={styles.categoriesItem}>
              <div className={styles.colLarge}>
                <div className={styles.imgWrap}>
                  {item.image !== null ? <img src={item.image} alt={''}/> : <span className={styles.noImage} style={{backgroundColor: "#ff9f43"}}>НБ</span>}
                </div>
                <p className={styles.itemTitle}>{item.title}</p>
              </div>
              <div className={styles.colLarge}>
                <p className={styles.date}>50</p>
              </div>
              <div className={styles.colLarge}>
                <p className={`${styles.status} ${item.active ? styles.active : styles.inactive}`}>{item.active ? 'Активно' : 'Неактивно'}</p>
              </div>
              <div className={styles.colLarge}>
                <p className={styles.date}>{item.updatedAt}</p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
}

export default Index;
