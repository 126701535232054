import React, {useState} from 'react';
import styles from '../ServiceModal/ServiceModal.module.scss';
import styles2 from './BalanceModal.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {Typography} from "@mui/material";
import {Icon} from "@iconify/react";
import {setServiceModalActive} from "../../redux/MainSlice";
import MonthCountSelect from "../MonthCountSelect";
import RangeInput from "../RangeInput/Index";
import axios from "axios";

function Index({setActive, nextActive, balance}: {setActive: any, nextActive: any, balance: any}) {

  const {theme, selectRestaurantId} = useSelector((state: RootState) => state.MainSlice);
  const {user, accessToken, company} = useSelector((state: RootState) => state.UserSlice);

  const [month, changeMonth] = useState(1)
  const [code, changeCode] = useState('');

  const [promoPrice, setPrice] = useState(0);

  const [codeError, setCodeError] = useState({
    active: false,
    text: ''
  })

  const sendCode = async () => {
    if(code.trim() === '' && promoPrice === 0) {
      if(code.trim() === '') {
        setCodeError({
          active: true,
          text: 'Поле не заполнено'
        })
      }
      else if(promoPrice === 0) {
        setCodeError({
          active: true,
          text: 'Вы не применили промокод'
        })
      }
    } else {
      try {
        const result = await axios.post(`https://api.vkusclick.ru/v1/companies/${company._id}/promo_codes/activate`, {
          promoCode: code
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        if(result.status === 200) {
          setActive(false);
          window.location.reload()
        }
      } catch (e) {
        setCodeError({
          active: true,
          text: 'Ошибка. Попробуйте позже'
        })
      }
    }
  }

  const activate = async () => {
    if(code.trim() === '') {
      setCodeError({
        active: true,
        text: 'Поле не заполнено'
      })
    } else {
      try {
        const result = await axios.get(`https://api.vkusclick.ru/v1/companies/${company._id}/promo_codes/${code}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        if(result.status === 200) {
          console.log(result.data.value);
          setPrice(result.data.value)
        }
      } catch (e: any) {
        if(e.response.data.message === 'Promo code not found or already activated') {
          setCodeError({
            active: true,
            text: 'Промокод не найден или уже активирован'
          })
        } else {
          setCodeError({
            active: true,
            text: 'Ошибка. Попробуйте позже'
          })
        }

      }
    }
  }

  return (
    <div className={styles.wrap}>
      <span className={styles.bg} onClick={() => setActive(false)}/>
      <div className={styles.content} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 4px 18px rgba(75, 70, 92, 0.1)', maxWidth: '550px'}}>
        <button onClick={() => setActive(false)} className={styles.close} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 2px 4px rgba(165, 163, 174, 0.3)' : '0px 4px 18px rgba(75, 70, 92, 0.1)'}}>
          <Icon width={20} icon={'tabler:x'} color={theme === 'light' ? '#afaeae' : '#7a7fb4'}/>
        </button>
        <p className={styles.title}>Активация промокода</p>
        <div className={styles2.passwordInputWrap}>
          <div style={{width: '100%'}}>
            <input onChange={(e) => {
              changeCode(e.target.value);
              setCodeError({
                active: false,
                text: ''
              })
            }} value={code} style={{borderColor: codeError.active ? 'red' : theme === 'light' ? '#DBDADE' : '#434968'}} type="text" className={styles2.input} placeholder={'Промокод'}/>
            {
              codeError.active &&
              <p className={styles.error}>{codeError.text}</p>
            }
          </div>
          { user?.role === 'demo' ? (
            <button title='Недоступно в демонстрационном заведении' className={styles2.generatePassword} style={{cursor: 'not-allowed'}}>Применить</button>
          ) : (
            <button className={styles2.generatePassword} onClick={activate}>Применить</button>
          )}
        </div>
        {
          promoPrice > 0 &&
          <p className={styles.price} style={{textAlign: 'right', margin: '32px 0'}}>{`Вы ввели промокод на ${promoPrice} руб`}</p>
        }
        { user?.role === 'demo' ? (
            <button title='Недоступно в демонстрационном заведении' className={styles.send} style={{cursor: 'not-allowed'}}>Активировать</button>
        ) : (
          <button className={styles.send} onClick={() => {
            sendCode()
          }}>Активировать</button>
        )}
      </div>
    </div>
  );
}

export default Index;
