import React, {useEffect} from 'react';
import ErrorContent from "../../Components/ErrorContent";
import {useDispatch} from "react-redux";
import {setLayoutVisible} from "../../redux/MainSlice";

function Index({title, subtitle}: {title: string, subtitle: string}) {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLayoutVisible(false))
  }, [])

  return (
    <div className={'error-page'}>
      <ErrorContent title={title} subtitle={subtitle}/>
    </div>
  );
}

export default Index;
