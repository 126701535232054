import React from 'react';
import PageTitle from "../../Components/PageTitle";
import WorkersContent from "../../Components/WorkersContent";

function Index() {
  return (
    <div className={'workers-page'}>
      <PageTitle title={'Сотрудники'}/>
      <WorkersContent/>
    </div>
  );
}

export default Index;
