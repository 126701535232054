import React, {useCallback, useEffect, useReducer, useState} from 'react';
import styles from './PopularProductsContent.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {Typography} from "@mui/material";
import {Icon} from "@iconify/react";
import PopularProductItem from "../PopularProductItem";
import {getCategories, getPopularDishes, getProducts} from "../../Hooks/Hooks";
import {setCategories, setProducts} from "../../redux/MainSlice";
import axios from "axios";

function Index() {

  const {theme, products, selectRestaurantId, categories} = useSelector((state: RootState) => state.MainSlice);
  const {user, accessToken} = useSelector((state: RootState) => state.UserSlice);
  const [popularProducts, setPopularProducts] = useState<any>([]);
  const dispatch = useDispatch();
  const [selectedProducts, select] = useState([]);
  const [selectedProductsPopular, selectPopular] = useState([]);
  const [unpopular, setUnpopular] = useState([]);
  const [popular, setPopular] = useState([]);
  const [loading, setLoading] = useState(true);
  const [unpopularSearchValue, changeUnpopularSearchValue] = useState('');
  const [popularSearchValue, changePopularSearchValue] = useState('');


  useEffect(() => {
    if(accessToken && selectRestaurantId) {
      setLoading(true)
      getPopularDishes(accessToken, selectRestaurantId).then(response => {
        // @ts-ignore
        setPopularProducts([...response?.data[0].dishesId]);
        setLoading(false)
      })
      getCategories({accessToken: accessToken, id: selectRestaurantId}).then(response => {
        dispatch(setCategories(response.categories.sort((a: any, b: any) => {
          return a - b;
        }).reverse()))
        setLoading(false)
      })
    }
    setLoading(true)
    getProducts({id: selectRestaurantId}).then(response => {
      setLoading(false)
      dispatch(setProducts([response.type === 'error' ? [] : response.products]))
    })
  }, [accessToken, selectRestaurantId])

  const removeDishes = async () => {
    
      const result = await axios.put(`https://api.vkusclick.ru/v1/restaurants/${selectRestaurantId}/popular_dishes`, {
        //@ts-ignore
        dishesId: []
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      setPopularProducts([...result.data.dishesId]);
      selectPopular([])
    
  }

  const sendOneDish = async () => {
    
      const result = await axios.put(`https://api.vkusclick.ru/v1/restaurants/${selectRestaurantId}/popular_dishes`, {
        dishesId: [...popularProducts, ...selectedProducts]
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      setPopularProducts([...result.data.dishesId]);
      select([])
    
  }

  const removeOneDish = async () => {
    
      const result = await axios.put(`https://api.vkusclick.ru/v1/restaurants/${selectRestaurantId}/popular_dishes`, {
        //@ts-ignore
        dishesId: [...popularProducts.filter((item: any) => !selectedProductsPopular.includes(item))]
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      setPopularProducts([...result.data.dishesId]);
      selectPopular([])
    
  }

  const sendDishes = async () => {
    
      const mas = [...popular.map((item: any) => item && item._id), ...unpopular.map((item: any) => item && item._id)]
      const result = await axios.put(`https://api.vkusclick.ru/v1/restaurants/${selectRestaurantId}/popular_dishes`, {
        dishesId: [...mas]
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      setPopularProducts([...result.data.dishesId]);
      select([])
    
  }

  useEffect(() => {
    if(popularProducts && products && categories) {
      // @ts-ignore
      setUnpopular([...products.filter(item => !popularProducts.includes(item._id) && categories.map(id => id._id).includes(item.categoryId))])
      // @ts-ignore
      setPopular([...products.filter(item => popularProducts.includes(item._id) && categories.map(id => id._id).includes(item.categoryId))])
    }
  }, [products, popularProducts, categories])

  return (
    <div className={styles.content}  style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 4px 18px rgba(75, 70, 92, 0.1)'}}>
      <div className={styles.wrap}>
        <div className={styles.side}>
          <p className={styles.sideTitle}>Доступные блюда</p>
          <div className={styles.sideContent} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}}>
            <div className={styles.contentTop} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}}>
              <input onChange={(e) => changeUnpopularSearchValue(e.target.value)} value={unpopularSearchValue} type="text" className={styles.topInput} placeholder={'Поиск'} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}}/>
              <button onClick={sendOneDish} className={styles.swapOne} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}}>
                <Icon color={theme === 'light' ? '#4B465C' : '#CFD3EC'} icon={'tabler:chevron-right'}/>
              </button>
              <button onClick={() => sendDishes()} className={styles.swapAll} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}}>
                <Icon color={theme === 'light' ? '#4B465C' : '#CFD3EC'} icon={'tabler:chevrons-right'}/>
              </button>
            </div>
            <div className={styles.sideWrap}>
                {
                  !loading && selectedProducts &&
                  unpopular.map((item: any) => {
                      return item.title.toLowerCase().includes(unpopularSearchValue.toLowerCase()) && <PopularProductItem selectedProducts={selectedProducts} select={select} id={item._id} img={item.image} title={item.title}/>
                  })
                }
            </div>
          </div>
        </div>
        <div className={styles.side}>
          <p className={styles.sideTitle}>Выбранные блюда</p>
          <div className={styles.sideContent} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}}>
            <div className={styles.contentTop} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}}>
              <button onClick={removeDishes} className={styles.swapAll} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}}>
                <Icon color={theme === 'light' ? '#4B465C' : '#CFD3EC'} icon={'tabler:chevrons-left'}/>
              </button>
              <button onClick={removeOneDish} className={styles.swapOne} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}}>
                <Icon color={theme === 'light' ? '#4B465C' : '#CFD3EC'} icon={'tabler:chevron-left'}/>
              </button>
              <input onChange={(e) => changePopularSearchValue(e.target.value)} value={popularSearchValue} type="text" className={styles.topInput} placeholder={'Поиск'} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}}/>
            </div>
            <div className={styles.sideWrap}>
              {
                !loading &&
                popular.map((item: any) => {
                    return item.title.toLowerCase().includes(popularSearchValue.toLowerCase()) && <PopularProductItem selectedProducts={selectedProductsPopular} select={selectPopular} id={item._id} img={item.image} title={item.title}/>
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
