import React from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import styles from './ProductAddContent.module.scss'
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {Icon} from "@iconify/react";


const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.enter];

export const Tags = ({tags, setTags}: {tags: any, setTags: any}) => {


  const handleDelete = (i: any) => {
    setTags(tags.filter((tag: any, index: number) => index !== i));
  };

  const handleAddition = (tag: any) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag: any, currPos: any, newPos: any) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index: any) => {
    console.log('The tag at index ' + index + ' was clicked');
  };

  const theme = useSelector((state: RootState) => state.MainSlice.theme);

  return (
      <div style={{border: theme === 'light' ? '1px solid #DBDADE' : '1px solid #434968', borderRadius: '4px', marginBottom: '16px'}}>
        <ReactTags
          tags={tags}
          delimiters={delimiters}
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          handleDrag={handleDrag}
          handleTagClick={handleTagClick}
          inputFieldPosition="inline"
          placeholder={'Аллергены'}
          inline
          removeComponent={({className, onRemove}) => <Icon onClick={onRemove} icon={'tabler:x'}/>}
          // classNames={{
          //   tag: `${styles.item} ${theme === 'light' ? styles.light : styles.dark}`,
          //   selected: styles.allergens
          // }}
        />
      </div>
  );
};

