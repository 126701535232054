import React from 'react';
import ProductAddContent from "../../Components/ProductAddContent";

function Index() {
  return (
    <div className={'product-add-page'}>
      <ProductAddContent/>
    </div>
  );
}

export default Index;
