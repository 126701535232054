import { configureStore } from '@reduxjs/toolkit'
import MainSlice from "./MainSlice";
import UserSlice from './UserSlice';

export const store = configureStore({
  reducer: {
    MainSlice,
    UserSlice
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
