import React from 'react';
import PageTitle from "../../Components/PageTitle";
import DashboardContent from "../../Components/DashboardContent";

function Index() {
  return (
    <div className={'dashboard-page'}>
      <PageTitle title={'Сводка за текущий день'}/>
      <DashboardContent/>
    </div>
  );
}

export default Index;
