import React from 'react';
import RegistrationConfirm from "../../Components/RegistrationConfirm";

function Index() {
  return (
    <div className={'activation'}>
      <RegistrationConfirm/>
    </div>
  );
}

export default Index;
