import {useEffect, useState} from 'react';
import styles from './CategoriesContent.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {useTheme} from "@mui/material";
import {Icon} from "@iconify/react";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {getCategories} from "../../Hooks/Hooks";
import {setCategories} from "../../redux/MainSlice";
import loader from '../../assets/img/loader.svg'
import axios from "axios";
import DeleteModal from "../DeleteModal/Index";
import moment from "moment/moment";

function Index() {

  const {theme, categories, selectRestaurantId} = useSelector((state: RootState) => state.MainSlice);
  const {accessToken} = useSelector((state: RootState) => state.UserSlice);
  const MuiTheme = useTheme();

  const dispatch = useDispatch();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [searchValue, changeSearchValue] = useState('');

  const [modal, setModal] = useState(false);
  const [deleteId, setDeleteId] = useState<any>();

  useEffect(() => {
    if(accessToken && selectRestaurantId) {
      setLoading(true)
      getCategories({accessToken: accessToken, id: selectRestaurantId}).then(response => {
        dispatch(setCategories(response.categories.sort((a: any, b: any) => {
          return a - b;
        }).reverse()))
        setLoading(false)
      })
    }
  }, [location, selectRestaurantId])

  const removeItem = async () => {
    try {
      const result = await axios.delete(`https://api.vkusclick.ru/v1/restaurants/${selectRestaurantId}/categories/${deleteId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      dispatch(setCategories(categories?.filter(item => item._id !== deleteId)))
    } catch (e: any) {
    }
  }

  const navigate = useNavigate();

  function byField(field: any) {
    return (a: any, b: any) => a[field] > b[field] ? 1 : -1;
  }

  const [sortedCategories, setSortedCategories] = useState<any>([]);
  const [sortBy, changeSort] = useState({
    type: 'updatedAt',
    reverse: true,
  })

  useEffect(() => {
    if(categories) {
      if(sortBy.reverse) {
        setSortedCategories([...categories].sort(byField(sortBy.type)).reverse());
      } else {
        setSortedCategories([...categories].sort(byField(sortBy.type)))
      }
    }
  }, [categories, sortBy])

  return (
    <>
      {modal &&
        <DeleteModal removeFc={removeItem} text={'категорию'} setActive={setModal}/>
      }
      <div className={styles.content} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 4px 18px rgba(75, 70, 92, 0.1)'}}>
        <div className={styles.top}>
          <div className={styles.searchWrap} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}}>
            <Icon color={theme === 'light' ? '#4B465C66' : '#8692D066'} icon="material-symbols:search-rounded" />
            <input value={searchValue} onChange={(e) => changeSearchValue(e.target.value)} type="text" className={styles.search} placeholder={'Поиск'}/>
          </div>
          <NavLink to={'/categories/add'} className={styles.addBtn} style={{backgroundColor: "#ff9f43"}}>
            <Icon icon="ph:plus-bold" color={'#fff'}/>
            <p>Добавить категорию</p>
          </NavLink>
        </div>
        <div className={styles.tableTop} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}}>
          <div className={styles.colLarge}>
            <p className={styles.title} onClick={() => changeSort({type: 'title', reverse: sortBy.type !== 'title' ? true : !sortBy.reverse})}>Название</p>
            <div className={styles.sortBtns} onClick={() => changeSort({type: 'title', reverse: sortBy.type !== 'title' ? true : !sortBy.reverse})}>
              <button>
                <Icon color={sortBy.type === 'title' && !sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-up" />
              </button>
              <button>
                <Icon color={sortBy.type === 'title' && sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-down" />
              </button>
            </div>
          </div>
          <div className={styles.colLarge}>
            <p className={styles.title} onClick={() => changeSort({type: 'active', reverse: sortBy.type !== 'active' ? true : !sortBy.reverse})}>Статус</p>
            <div className={styles.sortBtns} onClick={() => changeSort({type: 'active', reverse: sortBy.type !== 'active' ? true : !sortBy.reverse})}>
              <button>
                <Icon color={sortBy.type === 'active' && !sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-up" />
              </button>
              <button>
                <Icon color={sortBy.type === 'active' && sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-down" />
              </button>
            </div>
          </div>
          <div className={styles.colLarge}>
            <p className={styles.title} onClick={() => changeSort({type: 'updatedAt', reverse: sortBy.type !== 'updatedAt' ? true : !sortBy.reverse})}>Последнее изменение</p>
            <div className={styles.sortBtns} onClick={() => changeSort({type: 'updatedAt', reverse: sortBy.type !== 'updatedAt' ? true : !sortBy.reverse})}>
              <button>
                <Icon color={sortBy.type === 'updatedAt' && !sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-up" />
              </button>
              <button>
                <Icon color={sortBy.type === 'updatedAt' && sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-down" />
              </button>
            </div>
          </div>
          <div className={styles.colSmall}>
            <p className={styles.title}>Действия</p>
          </div>
        </div>
        <div className={styles.tableContent}>
          {
            loading ?
              <img className={styles.loader} src={loader}/> :
              sortedCategories === null || sortedCategories?.length === 0 ?
                <p className={styles.notFound}>Ничего не найдено</p> :
                sortedCategories?.map((item: any) => {
                  if(searchValue !== '') {
                    return (
                      item.title.toLowerCase().includes(searchValue.toLowerCase()) ?
                        <div style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}} key={item['_id']} className={styles.categoriesItem}>
                          <div className={styles.colLarge}>
                            <div className={styles.imgWrap} style={{backgroundImage: `url(${item.image})`, backgroundSize: 'cover', backgroundPosition: 'center'}}></div>
                            <p className={styles.itemTitle}>{item.title}</p>
                          </div>
                          <div className={styles.colLarge}>
                            <p className={`${styles.status} ${item.active ? styles.active : styles.inactive}`}>{item.active ? 'Активно' : 'Неактивно'}</p>
                          </div>
                          <div className={styles.colLarge}>
                            <p className={styles.date}>{moment(item.updatedAt).format('DD.MM.YYYY HH:mm').toString()}</p>
                          </div>
                          <div className={styles.colSmall}>
                            <button onClick={() => navigate('/categories/edit?id=' + item._id)}>
                              <Icon icon="tabler:pencil" width={22} height={22} color={"#ff9f43"}/>
                            </button>
                            <button onClick={() => {
                              setDeleteId(item._id)
                              setModal(true);
                            }}>
                              <Icon icon="tabler:trash" width={22} height={22} color={MuiTheme.palette.error.light}/>
                            </button>
                          </div>
                        </div> : null
                    )
                  } else {
                    return (
                      <div style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}} key={item['_id']} className={styles.categoriesItem}>
                        <div className={styles.colLarge}>
                          <div className={styles.imgWrap} style={{backgroundImage: `url(${item.image})`, backgroundSize: 'cover', backgroundPosition: 'center'}}></div>
                          <p className={styles.itemTitle}>{item.title}</p>
                        </div>
                        <div className={styles.colLarge}>
                          <p className={`${styles.status} ${item.active ? styles.active : styles.inactive}`}>{item.active ? 'Активно' : 'Неактивно'}</p>
                        </div>
                        <div className={styles.colLarge}>
                          <p className={styles.date}>{moment(item.updatedAt).format('DD.MM.YYYY HH:mm').toString()}</p>
                        </div>
                        <div className={styles.colSmall}>
                          <button onClick={() => navigate('/categories/edit?id=' + item._id)}>
                            <Icon icon="tabler:pencil" width={22} height={22} color={"#ff9f43"}/>
                          </button>
                          <button onClick={() => {
                            setDeleteId(item._id)
                            setModal(true);
                            }
                          }>
                            <Icon icon="tabler:trash" width={22} height={22} color={MuiTheme.palette.error.light}/>
                          </button>
                        </div>
                      </div>
                    )
                  }
                })
          }
        </div>
      </div>
    </>
  );
}

export default Index;
