import React, {useState} from 'react';
import styles from './OrdersListStatistic.module.scss';
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {Icon} from "@iconify/react";
import {NavLink} from "react-router-dom";
import {Typography} from "@mui/material";

function Index() {

  const {theme, orders} = useSelector((state: RootState) => state.MainSlice)

  const [page, setPage] = useState(1);

  const pages = [1, 2, 3, 4, 5]

  return (
    <div className={styles.content}  style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 4px 18px rgba(75, 70, 92, 0.1)'}}>
      <div className={styles.tableTop} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}}>
        <div className={styles.colSmall}>
          <p className={styles.title}># заказа</p>
          <div className={styles.sortBtns}>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-up" />
            </button>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-down" />
            </button>
          </div>
        </div>
        <div className={styles.colLarge}>
          <p className={styles.title}>стол</p>
          <div className={styles.sortBtns}>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-up" />
            </button>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-down" />
            </button>
          </div>
        </div>
        <div className={styles.colLarge}>
          <p className={styles.title}>Сумма, руб</p>
          <div className={styles.sortBtns}>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-up" />
            </button>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-down" />
            </button>
          </div>
        </div>
        <div className={styles.colLarge}>
          <p className={styles.title}>Количество позиций</p>
          <div className={styles.sortBtns}>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-up" />
            </button>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-down" />
            </button>
          </div>
        </div>
        <div className={styles.colLarge}>
          <p className={styles.title}>Статус</p>
          <div className={styles.sortBtns}>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-up" />
            </button>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-down" />
            </button>
          </div>
        </div>
        <div className={styles.colLarge}>
          <p className={styles.title}>Дата создания</p>
          <div className={styles.sortBtns}>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-up" />
            </button>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-down" />
            </button>
          </div>
        </div>
      </div>
      <div className={styles.tableContent}>
        {orders?.map(item => {
          return (
            <div style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}} key={item._id} className={styles.categoriesItem}>
              <div className={styles.colLarge}>
                <NavLink to={'/orders-list/order?id=' + item._id} className={styles.orderNum}>#{item.orderNumber}</NavLink>
              </div>
              <div className={styles.colLarge}>
                <p className={styles.tableNum}>{item.table}</p>
              </div>
              <div className={styles.colLarge}>
                <p className={styles.price}>{item.totalCost}</p>
              </div>
              <div className={styles.colLarge}>
                <p className={styles.lastChangeDate}>{item.totalItemsCount}</p>
              </div>
              <div className={styles.colLarge}>
                <p className={`${styles.status} ${item.status === 'Ожидает' ? styles.wait : item.status === 'Принят' ? styles.accepted : item.status === 'Завершен' ? styles.complete : item.status === 'Готовится' ? styles.getReady : item.status === 'Отменен' ? styles.canceled : ''}`}>{item.status}</p>
              </div>
              <div className={styles.colLarge}>
                <p className={styles.date}>{item.updatedAt}</p>
              </div>

            </div>
          )
        })}
      </div>
      <div className={styles.pagination}>
        <button onClick={() => {
          if(page > 1) {
            setPage(page - 1)
          }
        }}  style={{backgroundColor: theme === 'light' ? 'rgba(75, 70, 92, 0.08)' : 'rgba(194,192,197,0.08)'}}>
          <Icon color={theme === 'light' ? '#4B465C' : '#CFD3EC'} icon={'tabler:chevron-left'} width={16}/>
        </button>
        {
          pages.map(item => <button onClick={() => setPage(item)} className={page === item ? styles.active : ''} key={item} style={{backgroundColor: theme === 'light' ? 'rgba(75, 70, 92, 0.08)' : 'rgba(194,192,197,0.08)'}}>{item}</button>)
        }
        <button onClick={() => {
          if(page < pages.length) {
            setPage(page + 1)
          }
        }} style={{backgroundColor: theme === 'light' ? 'rgba(75, 70, 92, 0.08)' : 'rgba(194,192,197,0.08)'}}>
          <Icon color={theme === 'light' ? '#4B465C' : '#CFD3EC'} icon={'tabler:chevron-right'} width={16}/>
        </button>
      </div>
    </div>
  );
}

export default Index;
