import React from 'react';
import ProductEditContent from "../../Components/ProductEditContent/Index";

function Index() {
  return (
    <div className={'product-edit-page'}>
      <ProductEditContent/>
    </div>
  );
}

export default Index;
