import React from 'react';
import QrGenerationContent from "../../Components/QrGenerationContent";
import PageTitle from "../../Components/PageTitle";

function Index() {
  return (
    <div className={'qr-page'}>
      <PageTitle title={'Генерация QR-кодов'}/>
      <QrGenerationContent/>
    </div>
  );
}

export default Index;
