import React from 'react';
import PageTitle from "../../Components/PageTitle";
import CategoryStatisticContent from "../../Components/CategoryStatisticContent";

function Index() {
  return (
    <div className={'category-statistic-page'}>
      <PageTitle title={'Статистика по категориям'}/>
      <CategoryStatisticContent/>
    </div>
  );
}

export default Index;
