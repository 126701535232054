import React from 'react';
import styles from '../ServiceModal/ServiceModal.module.scss';
import styles2 from './BalanceModal.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {Typography} from "@mui/material";
import {Icon} from "@iconify/react";

function Index({setActive}: {setActive: any}) {

  const {theme} = useSelector((state: RootState) => state.MainSlice);

  return (
    <div className={styles.wrap}>
      <span className={styles.bg} onClick={() => setActive(false)}/>
      <div className={styles.content} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 4px 18px rgba(75, 70, 92, 0.1)', padding: '32px 79px 69px'}}>
        <button onClick={() => setActive(false)} className={styles.close} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 2px 4px rgba(165, 163, 174, 0.3)' : '0px 4px 18px rgba(75, 70, 92, 0.1)'}}>
          <Icon width={20} icon={'tabler:x'} color={theme === 'light' ? '#afaeae' : '#7a7fb4'}/>
        </button>
        <p className={styles.title} style={{textAlign: 'center'}}>Ошибка</p>
        <p className={styles.text} style={{textAlign: 'center'}}>Нельзя изменять модули подписки чаще одного раза в день!</p>
      </div>
    </div>
  );
}

export default Index;
