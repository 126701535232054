import React from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import styles from "../../Components/ProductAddContent/ProductAddContent.module.scss";
import {Icon} from "@iconify/react";
import PageTitle from "../../Components/PageTitle";
import WorkerAddContent from "../../Components/WorkerAddContent";

function Index() {

  const {theme} = useSelector((state: RootState) => state.MainSlice);

  return (
    <div className={'worker-add-page'} >
      <div className={styles.top}>
        {/* eslint-disable-next-line no-restricted-globals */}
        <button onClick={() => history.back()}>
          <Icon width={24} height={24} color={theme === 'dark' ? '#CFD3EC' : '#4B4B4B'} icon="tabler:arrow-narrow-left" />
        </button>
        <PageTitle title={'Добавить сотрудника'}/>
      </div>
      <WorkerAddContent/>
    </div>
  );
}

export default Index;
