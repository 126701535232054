import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {NavLink} from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {Icon} from "@iconify/react";
import styles from './Accordion.module.scss';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `none`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  backgroundColor: 'transparent',
  color: 'inherit'
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  padding: '10px 20px',
  transition: '.3s',
  borderRadius: 6,
  '&.Mui-expanded ' : {
    backgroundColor: '#8692D00F',
    'p': {
      fontWeight: '600'
    }
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(-90deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0)',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: 'transparent',
  padding: 0,
  marginTop: '6px',
}));

export default function AccordionComponent({title, childrens, icon, disabled, expandedDefault}: {icon: string, title: string, childrens: {title: string, link: string}[], disabled: boolean, expandedDefault?: boolean}) {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  React.useEffect(() => {
    if(expandedDefault) {
      setExpanded('panel1');
    }
  }, [])

  const localTheme = useSelector((state: RootState) => state.MainSlice.theme)

  return (
    <div>
      <Accordion sx={{backgroundColor: 'transparent !important'}} disabled={disabled} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary expandIcon={<KeyboardArrowDownIcon sx={{fill: localTheme === 'light' ? '#6F6B7D' : '#CFD3EC'}}/>} aria-controls="panel1d-content" id="panel1d-header">
          <p className={styles.title}><Icon width={22} height={22} icon={icon} />{title}</p>
        </AccordionSummary>
        <AccordionDetails>
          {childrens.map(item => {
            return <NavLink className={({isActive}) => isActive ? `${styles.link} ${styles.active}` : styles.link} key={item.title} to={item.link}>{item.title}</NavLink>
          })}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
