import React, {useEffect, useState} from 'react';
import styles from './SelectTable.module.scss';
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {useTheme} from "@mui/material";
import axios from "axios";

const uniq = require('lodash.uniq')

function Index({selectedIndex,changeSelectedIndex, selectedTables, selectWaiterIndex, setAssignTables, bannedTables, getSettings}: {selectedIndex: any,changeSelectedIndex: any,getSettings: any, selectedTables: any, selectWaiterIndex: any, setAssignTables: any, bannedTables: any}) {

  const {tables, theme, selectRestaurantId, workers} = useSelector((state: RootState) => state.MainSlice);
  const {user, accessToken} = useSelector((state: RootState) => state.UserSlice);


  const MuiTheme = useTheme();

  const setTable = async () => {
    if(!selectedTables || !selectedTables.includes(selectedIndex)) {
      let mas = [...selectedIndex];
      mas = mas.reduce( (acc, item) => {
        if (!bannedTables.includes(item)) acc.push(item);
        return acc;
      } , []);

      const result = await axios.post(`https://api.vkusclick.ru/v1/restaurants/${selectRestaurantId}/tables_assign`, {
        officiantId: selectWaiterIndex,
        tables: [...selectedIndex]
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      await axios.get(`https://api.vkusclick.ru/v1/restaurants/${selectRestaurantId}/tables_assign`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }).then(response => {
        setAssignTables(response.data);
      })
      getSettings()
    } else if (!selectedTables) {
    }
  }

  useEffect(() => {
    console.log(selectedTables);
  }, [selectWaiterIndex])

  return (
    <>
      {
        workers?.filter(item => item.role === 'officiant')[0] &&
        <div className={styles.wrap}>
          <div className={styles.content}>
            <p className={styles.title}>Столы</p>
            <p className={styles.subtitle}>Выбор стола:</p>

            <div className={styles.tables}>
              {tables.map((item) => {
                return (
                  <div onClick={() => {
                    if(!selectedTables.includes(item)) {
                      changeSelectedIndex((current: any) => current.includes(item) ? [...current.filter((num: any) => num !== item)] : [...current, item])
                    }
                  }} style={{borderColor: theme === 'light' ? '#4B465C66' : '#8692D066', color: theme === 'light' ? 'rgba(75,70,92,0.52)' : 'rgba(134,146,208,0.69)'}} className={`${styles.table} ${selectedTables && selectedTables.includes(item) ? styles.already : ''} ${selectedIndex.includes(item) && styles.selected} ${bannedTables.includes(item) && styles.unattended}`} key={item}>
                    {item}
                  </div>
                )
              })}
            </div>

              { user?.role === 'demo' ? (
                <button title='Недоступно в демонстрационном заведении' className={styles.btn} style={{backgroundColor: "#ff9f43", color: '#fff', cursor: 'not-allowed'}}>Назначить</button>            
              ) : (
                <button onClick={setTable} style={{backgroundColor: "#ff9f43", color: '#fff'}} className={styles.btn}>Назначить</button>
              )}
          </div>
          <div className={styles.info}>
            <div className={styles.item}>
              <div style={{borderColor: theme === 'light' ? '#4B465C66' : '#8692D066', color: theme === 'light' ? 'rgba(75,70,92,0.52)' : 'rgba(134,146,208,0.69)'}} className={`${styles.table} ${styles.selected}`}>
                1
              </div>
              <p className={styles.infoText}><span>-</span> выбранный стол</p>
            </div>
            <div className={styles.item}>
              <div style={{borderColor: theme === 'light' ? '#4B465C66' : '#8692D066', color: theme === 'light' ? 'rgba(75,70,92,0.52)' : 'rgba(134,146,208,0.69)'}} className={`${styles.table} ${styles.unattended}`}>
                1
              </div>
              <p className={styles.infoText}><span>-</span> необслуживаемые столы</p>
            </div>
            <div className={styles.item}>
              <div style={{borderColor: theme === 'light' ? '#4B465C66' : '#8692D066', color: theme === 'light' ? 'rgba(75,70,92,0.52)' : 'rgba(134,146,208,0.69)'}} className={`${styles.table}`}>
                1
              </div>
              <p className={styles.infoText}><span>-</span> стол не назначен</p>
            </div>
            <div className={styles.item}>
              <div style={{borderColor: theme === 'light' ? '#4B465C66' : '#8692D066', color: theme === 'light' ? 'rgba(75,70,92,0.52)' : 'rgba(134,146,208,0.69)'}} className={`${styles.table} ${styles.already}`}>
                1
              </div>
              <p className={styles.infoText}><span>-</span> уже назначен</p>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default Index;
