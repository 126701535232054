import React from 'react';
import CalculatorContent from "../../Components/CalculatorContent";

function Index() {
  return (
    <div className={'calculator-page'}>
      <CalculatorContent/>
    </div>
  );
}

export default Index;
