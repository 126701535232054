import React from 'react';
import PageTitle from "../../Components/PageTitle";
import HelpContent from "../../Components/HelpContent";

function Index() {
  return (
    <div className={'help-page'}>
      <PageTitle title={'Поддержка'}/>
      <HelpContent/>
    </div>
  );
}

export default Index;
