import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import styles from "../../TablesContent/SelectTable/SelectTable.module.scss";

function Tables({selectedIndex, changeSelectedIndex}: {selectedIndex: any, changeSelectedIndex: any}) {
  const {tables, theme} = useSelector((state: RootState) => state.MainSlice);

  useEffect(() => {
    console.log(selectedIndex);}, [])

  return (
    <div className={styles.wrap}>
      <div className={styles.content} style={{border: 'none'}}>
        <p className={styles.subtitle}>Выбор стола:</p>
        <div className={styles.tables}>
          {tables.map(item => {
            return (
              <div onClick={() => {
                  changeSelectedIndex((current: any) => current.includes(item) ? current.length > 1 ? [...current.filter((num: any) => num !== item)] : [...current] : [...current, item])
              }} style={{borderColor: theme === 'light' ? '#4B465C66' : '#8692D066', color: theme === 'light' ? 'rgba(75,70,92,0.52)' : 'rgba(134,146,208,0.69)'}} className={`${styles.table} ${selectedIndex.includes(item) && styles.selected}`} key={item}>
                {item}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}

export default Tables;
