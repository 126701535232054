import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import styles from './HelpAccordion.module.scss';
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {Icon} from "@iconify/react";
import {useEffect, useState} from "react";
import axios from "axios";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: 'none',
}));

export default function HelpAccordion() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const {theme} = useSelector((state: RootState) => state.MainSlice);

  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState<{
    _id: string,
    question: string,
    answer: string,
    priority: 0,
    updatedAt: string
  }[]>();

  useEffect(() => {
    const getQA = async () => {
      setLoading(true);
      try {
        const result = await axios.get(`https://api.vkusclick.ru/v1/frequently_asked_questions`)
        setQuestions([...result.data])
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
    getQA()
  }, [])

  return (
    <div>
      {questions &&
        questions.map(item => {
          return (
            <Accordion key={item['_id']} className={styles.accordion} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 2px 4px rgba(165, 163, 174, 0.3)' : '0px 2px 4px rgba(15, 20, 34, 0.4)'}} expanded={expanded === item._id} onChange={handleChange( item._id)}>
              <AccordionSummary className={styles.accordionTitle}
                                expandIcon={<Icon width={20} color={theme === 'light' ? '#4B465C' : '#CFD3EC'} className={styles.icon} icon={'tabler:chevron-right'}/>}
                                aria-controls="panel1d-content" id="panel1d-header">
                <p style={{ color: theme === 'light' ? '#4B465C' : '#CFD3EC'}} color={theme === 'light' ? '#4B465C' : '#CFD3EC'}>{item.question}</p>
              </AccordionSummary>
              <AccordionDetails className={styles.details}>
                <p className={styles.text} style={{whiteSpace: "pre-wrap", color: theme === 'light' ? '#4B465C' : '#CFD3EC', opacity: '0.8'}} color={theme === 'light' ? '#4B465C' : '#CFD3EC'}>
                  {item.answer}
                </p>
              </AccordionDetails>
            </Accordion>
          )
        })
      }
    </div>
  );
}
