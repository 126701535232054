import React, {useEffect, useState} from 'react';
import styles from './TablesContent.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import SelectWaiter from "./SelectWaiter";
import SelectTable from "./SelectTable";
import axios from "axios";
import {getTables, getWorkers} from "../../Hooks/Hooks";
import {setTables, setWorkers} from "../../redux/MainSlice";

function Index() {

  const theme = useSelector((state: RootState) => state.MainSlice.theme);
  const {selectRestaurantId, workers} = useSelector((state: RootState) => state.MainSlice);
  const {accessToken} = useSelector((state: RootState) => state.UserSlice);

  const [assignTables, setAssignTables] = useState([]);

  const [selectWaiterIndex, setIndex] = useState<string>();
  const [selectedTables, setSelectedTables] = useState<any>([])
  const [tablesCountStart, setTablesCountStart] = useState(0);
  const [tablesCountEnd, setTablesCountEnd] = useState(0)
  const [selectedIndex, changeSelectedIndex] = useState<any>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedTables([])
    changeSelectedIndex([])
    assignTables.map((item: any) => {
      if(item.officiantId !== selectWaiterIndex) {
        setSelectedTables((currentState: any) => [...currentState, ...item.tables])
      } else {
        changeSelectedIndex((currentState: any) => [...currentState, ...item.tables])
      }
    })
    // @ts-ignore
  }, [selectWaiterIndex, assignTables])

  const [bannedTables, setBannedTables] = useState([]);
  const [loading, setLoading] = useState(false);

  const getSettings = () => {
    if(accessToken && selectRestaurantId) {
      getTables({accessToken: accessToken, id: selectRestaurantId}).then(response => {
        let mas = [];
        //@ts-ignore
        let start = response?.tables.tablesCountStart
        for (start; start <= response?.tables.tablesCountEnd; start++) {
          mas.push(start);
        }
        dispatch(setTables([...mas]));
        if(response.tables.unattendedTables) {
          //@ts-ignore
          setBannedTables([...response.tables.unattendedTables])
        }
        setTablesCountStart(response?.tables.tablesCountStart)
        setTablesCountEnd(response?.tables.tablesCountEnd)
      })
    }
  }

  useEffect(() => {
    if(!workers) {
      if(accessToken && selectRestaurantId !== '') {
        getWorkers({accessToken: accessToken, id: selectRestaurantId}).then(response => {
          dispatch(setWorkers(response.workers))
          if(response?.workers?.filter(item => item.role === 'officiant')[0]) {
            setIndex(response?.workers?.filter(item => item.role === 'officiant')[0]._id)
          }
        })
        getSettings()
      }
    } else {
      if(workers.filter(item => item.role === 'officiant')[0]) {
        setIndex(workers.filter(item => item.role === 'officiant')[0]._id)
        getSettings()
      } else {
      }
    }
  }, [accessToken, selectRestaurantId])

  useEffect(() => {
    if(accessToken && selectRestaurantId) {
      axios.get(`https://api.vkusclick.ru/v1/restaurants/${selectRestaurantId}/tables_assign`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }).then(response => {
        setAssignTables(response.data);
      })
    }
  }, [accessToken, selectRestaurantId, selectWaiterIndex])

  return (
    <>
      {
        !loading &&
        <div className={styles.content} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 4px 18px rgba(75, 70, 92, 0.1)'}}>
          {workers?.filter(item => item.role === 'officiant')[0] ?
            <>
              <SelectWaiter bannedTables={bannedTables} selectedIndex={selectedIndex} getSettings={getSettings} tablesCountStart={tablesCountStart} tablesCountEnd={tablesCountEnd} selectWaiterIndex={selectWaiterIndex} setIndex={setIndex} assignTables={assignTables} selectedTables={selectedTables}/>
              <SelectTable changeSelectedIndex={changeSelectedIndex} selectedIndex={selectedIndex} getSettings={getSettings} setAssignTables={setAssignTables} selectWaiterIndex={selectWaiterIndex}
                           selectedTables={selectedTables} bannedTables={bannedTables}/>
            </> : <p>Официанты не найдены</p>
          }
        </div>
      }
    </>
  );
}

export default Index;
