import React from 'react';
import styles from './ErrorContent.module.scss';
import {Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

function Index({title, subtitle}: {title: string, subtitle: string}) {

  const navigate = useNavigate();

  return (
    <div className={styles.content}>
      <div className={styles.wrap}>
        <p className={styles.title}>{title}</p>
        <p className={styles.subtitle}>{subtitle}</p>
        {/* eslint-disable-next-line no-restricted-globals */}
        <button onClick={() => navigate('/sign-in')} className={styles.back}>Вернуться назад</button>
      </div>
    </div>
  );
}

export default Index;
