import React from 'react';
import PageTitle from "../../Components/PageTitle";
import ServicesListContent from "../../Components/ServicesListContent";

function Index() {
  return (
    <div className={'calculator-page'}>
      <PageTitle title={'Список услуг'}/>
      <ServicesListContent/>
    </div>
  );
}

export default Index;
