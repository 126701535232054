import React, { useEffect, useState } from 'react';
import styles from './WorkersContent.module.scss';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Typography, useTheme } from "@mui/material";
import { Icon } from "@iconify/react";
import { NavLink } from "react-router-dom";
import { getDate, getWorkers } from "../../Hooks/Hooks";
import { setWorkers } from "../../redux/MainSlice";
import loader from '../../assets/img/loader.svg';
import axios from "axios";
import DeleteModal from '../DeleteModal/Index'
import moment from "moment/moment";

function Index() {

  const { workers, theme, selectRestaurantId } = useSelector((state: RootState) => state.MainSlice);
  const { accessToken, user } = useSelector((state: RootState) => state.UserSlice);
  const MuiTheme = useTheme();
  const dispatch = useDispatch();

  const [searchValue, changeSearchValue] = useState('');

  const [loading, setLoading] = useState(true);

  const [selectId, setSelectId] = useState('');

  useEffect(() => {
    if (accessToken && selectRestaurantId !== '') {
      setLoading(true)
      getWorkers({ accessToken: accessToken, id: selectRestaurantId }).then(response => {
        dispatch(setWorkers(response.workers))
        setLoading(false)
      })
    }
  }, [accessToken, selectRestaurantId])

  const removeUser = async () => {
    try {
      await axios.delete(`https://api.vkusclick.ru/v1/restaurants/${selectRestaurantId}/employees/${selectId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      if (workers) {
        dispatch(setWorkers([...workers.filter(item => item['_id'] !== selectId)]));
      }
    } catch (e) {

    }

  }

  const [modal, setModal] = useState(false);

  function byField(field: any) {
    return (a: any, b: any) => a[field] > b[field] ? 1 : -1;
  }

  const [sortedWorkers, setSortedWorkers] = useState<any>([]);
  const [sortBy, changeSort] = useState({
    type: 'updatedAt',
    reverse: true,
  })

  useEffect(() => {
    if (workers) {
      if (sortBy.reverse) {
        setSortedWorkers([...workers].sort(byField(sortBy.type)).reverse());
      } else {
        setSortedWorkers([...workers].sort(byField(sortBy.type)))
      }
    }
  }, [workers, sortBy])

  return (
    <>
      {modal && <DeleteModal removeFc={removeUser} setActive={setModal} text={'сотрудника'} />}
      <div className={styles.content} style={{ backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 4px 18px rgba(75, 70, 92, 0.1)' }}>
        <div className={styles.top}>
          <div className={styles.searchWrap} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }}>
            <Icon color={theme === 'light' ? '#4B465C66' : '#8692D066'} icon="material-symbols:search-rounded" />
            <input value={searchValue} onChange={(e) => changeSearchValue(e.target.value)} type="text" className={styles.search} placeholder={'Поиск'} />
          </div>
          <NavLink to={'/workers/add'} className={styles.addBtn} style={{ backgroundColor: "#ff9f43" }}>
            <Icon icon="ph:plus-bold" color={'#fff'} />
            <p>Добавить сотрудника</p>
          </NavLink>
        </div>
        <div className={styles.tableTop} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }}>
          <div className={styles.colLarge}>
            <p className={styles.title} onClick={() => changeSort({ type: 'firstName', reverse: sortBy.type !== 'firstName' ? true : !sortBy.reverse })}>Имя Фамилия</p>
            <div className={styles.sortBtns}>
              <div className={styles.sortBtns} onClick={() => changeSort({ type: 'firstName', reverse: sortBy.type !== 'firstName' ? true : !sortBy.reverse })}>
                <button>
                  <Icon color={sortBy.type === 'firstName' && !sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-up" />
                </button>
                <button>
                  <Icon color={sortBy.type === 'firstName' && sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-down" />
                </button>
              </div>
            </div>
          </div>
          <div className={styles.colLarge}>
            <p className={styles.title} onClick={() => changeSort({ type: 'role', reverse: sortBy.type !== 'role' ? true : !sortBy.reverse })}>Должность</p>
            <div className={styles.sortBtns} onClick={() => changeSort({ type: 'role', reverse: sortBy.type !== 'role' ? true : !sortBy.reverse })}>
              <button>
                <Icon color={sortBy.type === 'role' && !sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-up" />
              </button>
              <button>
                <Icon color={sortBy.type === 'role' && sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-down" />
              </button>
            </div>
          </div>
          <div className={styles.colLarge}>
            <p className={styles.title} onClick={() => changeSort({ type: 'email', reverse: sortBy.type !== 'email' ? true : !sortBy.reverse })}>Email</p>
            <div className={styles.sortBtns} onClick={() => changeSort({ type: 'email', reverse: sortBy.type !== 'email' ? true : !sortBy.reverse })}>
              <button>
                <Icon color={sortBy.type === 'email' && !sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-up" />
              </button>
              <button>
                <Icon color={sortBy.type === 'email' && sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-down" />
              </button>
            </div>
          </div>
          <div className={styles.colLarge}>
            <p className={styles.title} onClick={() => changeSort({ type: 'updatedAt', reverse: sortBy.type !== 'updatedAt' ? true : !sortBy.reverse })}>Последнее изменение</p>
            <div className={styles.sortBtns} onClick={() => changeSort({ type: 'updatedAt', reverse: sortBy.type !== 'updatedAt' ? true : !sortBy.reverse })}>
              <button>
                <Icon color={sortBy.type === 'updatedAt' && !sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-up" />
              </button>
              <button>
                <Icon color={sortBy.type === 'updatedAt' && sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-down" />
              </button>
            </div>
          </div>
          <div className={styles.colSmall}>
            <p className={styles.title}>Действия</p>
          </div>
        </div>
        <div className={styles.tableContent}>
          {
            !loading ?
              sortedWorkers?.map((item: any, i: number) => {
                if (searchValue.trim() === '') {
                  // @ts-ignore
                  return (
                    <div style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }} key={item['_id']} className={styles.categoriesItem}>
                      <div className={styles.colLarge}>
                        <div className={styles.imgWrap}>
                          <span className={styles.noImage} style={{ backgroundColor: "#ff9f43" }}>{item.firstName[0]}{item.lastName[0]}</span>
                        </div>
                        <p className={styles.name}>{item.firstName} {item.lastName}</p>
                      </div>
                      <div className={styles.colLarge}>
                        <p className={`${styles.work}`}>{item.role === 'director' ? 'Директор' : item.role === 'manager' ? 'Менеджер' : item.role === 'officiant' ? 'Официант' : item.role === 'demo' ? 'Менеджер' : 'Администратор'}</p>
                      </div>
                      <div className={styles.colLarge}>
                        <p className={styles.email}>{item.email}</p>
                      </div>
                      <div className={styles.colLarge}>
                        <p className={styles.date}>{moment(item.updatedAt).format('DD.MM.YYYY HH:mm').toString()}</p>
                      </div>
                      <div className={styles.colSmall}>
                        {
                          item.role === 'director' && user?.role !== 'director' ?
                            null :
                            user?.role !== item.role || user?.role === 'director' ?
                              <NavLink
                                to={item.role === 'manager' ? '/workers/edit/manager?id=' + item['_id'] : '/workers/edit?id=' + item['_id']}>
                                <Icon icon="tabler:pencil" width={22} height={22} color={"#ff9f43"} />
                              </NavLink> : null
                        }
                        {
                          user?.role === 'director' && item.role !== 'director' ?
                            <button onClick={() => {
                              setModal(true)
                              setSelectId(item._id)
                            }
                            }>
                              <Icon icon="tabler:trash" width={22} height={22} color={MuiTheme.palette.error.light} />
                            </button> :
                            user?.role === 'manager' && item.role === 'officiant' ?
                              <button onClick={() => {
                                setModal(true)
                                setSelectId(item._id)
                              }
                              }>
                                <Icon icon="tabler:trash" width={22} height={22} color={MuiTheme.palette.error.light} />
                              </button> : null
                        }

                      </div>
                    </div>
                  )
                } else {
                  return item.firstName.includes(searchValue) || item.lastName.includes(searchValue) ? (
                    <div style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }} key={item['_id']}
                      className={styles.categoriesItem}>
                      <div className={styles.colLarge}>
                        <div className={styles.imgWrap}>
                          <span className={styles.noImage}
                            style={{ backgroundColor: "#ff9f43" }}>{item.firstName[0]}{item.lastName[0]}</span>
                        </div>
                        <p className={styles.name}>{item.firstName} {item.lastName}</p>
                      </div>
                      <div className={styles.colLarge}>
                        <p className={`${styles.work}`}>{item.role === 'director' ? 'Директор' : item.role === 'manager' ? 'Менеджер' : item.role === 'officiant' ? 'Официант' : item.role === 'demo' ? 'Менеджер' : 'Администратор'}</p>
                      </div>
                      <div className={styles.colLarge}>
                        <p className={styles.email}>{item.email}</p>
                      </div>
                      <div className={styles.colLarge}>
                        <p className={styles.date}>{moment(item.updatedAt).format('DD.MM.YYYY HH:mm').toString()}</p>
                      </div>
                      <div className={styles.colSmall}>
                        {
                          item.role === 'director' && user?.role !== 'director' ?
                            null :
                            <NavLink
                              to={item.role === 'manager' ? '/workers/edit/manager?id=' + item['_id'] : '/workers/edit?id=' + item['_id']}>
                              <Icon icon="tabler:pencil" width={22} height={22} color={"#ff9f43"} />
                            </NavLink>
                        }

                        {
                          user?.role === 'director' ?
                            <button onClick={() => {
                              setModal(true)
                              setSelectId(item._id)
                            }
                            }>
                              <Icon icon="tabler:trash" width={22} height={22} color={MuiTheme.palette.error.light} />
                            </button> :
                            user?.role === 'manager' && item.role === 'officiant' ?
                              <button onClick={() => {
                                setModal(true)
                                setSelectId(item._id)
                              }
                              }>
                                <Icon icon="tabler:trash" width={22} height={22} color={MuiTheme.palette.error.light} />
                              </button> : null
                        }

                      </div>
                    </div>
                  ) : null;
                }
              }) : <div className={styles.loading}><img alt={''} src={loader} /></div>
          }
        </div>
      </div>
    </>
  );
}

export default Index;
