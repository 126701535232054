import React from 'react';
import {Typography} from "@mui/material";
import styles from './PageTitle.module.scss';
import {Icon} from "@iconify/react";
import {RootState} from "../../redux/store";
import {useSelector} from "react-redux";

function Index({title}: {title: string}) {

  const theme = useSelector((state: RootState) => state.MainSlice.theme);

  return (
    <>
      <div className={styles.titleWrap}>
        <p className={styles.title}>{title}</p>

      </div>
    </>
  );
}

export default Index;
