import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
);



export function Chart() {
  const {theme} = useSelector((state: RootState) => state.MainSlice);

  const options = {
    responsive: true,
    bezierCurve: false,
    tension: 0.4,
    plugins: {
      legend: {
        position: 'top' as const,
        align: 'end' as const,
        boxWidth: 10,
        labels: {
          usePointStyle: true,
          pointStyle: 'circle'
        }
      },
      datalabels: {
        formatter: (value: any, ctx: any) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map((data: any) => {
            sum += data;
            return 0;
          });
          let percentage = (value*100 / sum).toFixed(0)+"%";
          return percentage;
        },
        color: 'transparent',
      },
    },
    scales: {
      y: {
        max: 500,
        min: 0,
        ticks: {
          stepSize: 100,
          color: theme === 'light' ? '#4B465C' : '#CFD3EC'
        }
      },
      x: {
        min: 0,
        ticks: {
          color: theme === 'light' ? '#4B465C' : '#CFD3EC'
        }
      }
    },
    maintainAspectRatio: false
  };

  const labels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: [100, 250, 300, 10, 100, 78, 25, 299, 145, 74, 352, 200],
        borderColor: '#FFBD1F',
        backgroundColor: '#FFBD1F',
      },
      {
        label: 'Dataset 2',
        data: [200, 350, 70, 85, 65, 133, 321, 74, 14, 350, 100, 120],
        borderColor: '#836AF9',
        backgroundColor: '#836AF9',
      },
    ],
  };

  return (
    <div className={'chart'} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 4px 18px rgba(75, 70, 92, 0.1)'}}>
      <Line style={{maxWidth: '1128px'}} width={1128} height={260} options={options} data={data} />
    </div>
  );
}
