import {createSlice} from '@reduxjs/toolkit'


export interface UserState {
  user: {
    balance: number,
    _id: string,
    companyId: string,
    restaurantsId: string[],
    firstName: string,
    lastName: string,
    role: string,
    phone: string,
    email: string,
    updatedAt: string,
    img: string,
    emailConfirmed: boolean
  } | null,
  isAuth: boolean,
  company?: any,
  accessToken: string | null,
  restaurants: any[] | null;
  refreshToken: string | null,
}

const initialState: UserState = {
  user: null,
  isAuth: false,
  accessToken: null,
  refreshToken: null,
  restaurants: null
}

export const UserSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    setUser: (state, {payload}) => {
      if(payload) {
        state.user = {...payload, id: payload['_id'], balance: 23434.25}
      } else {
        state.user = null;
      }
    },
    setAuth: (state, {payload}) => {
      state.isAuth = payload;
    },
    addRestaurant: (state, {payload}) => {
      if(state.user) {
        state.user.restaurantsId = [...state.user.restaurantsId, {...payload}]
      }
    },
    setCompany: (state, {payload}) => {
      if(payload) {
        state.company = {...payload};
      }
    },
    setTokens: (state, {payload}) => {
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
    },
    setRestaurants: (state, {payload}) => {
      state.restaurants = [...payload[0]];
    }
  },
})

export const { setUser, setAuth, addRestaurant, setCompany, setTokens, setRestaurants } = UserSlice.actions

export default UserSlice.reducer
