import {useEffect, useState} from 'react';
import {Icon} from "@iconify/react";
import {NavLink, useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material";
import styles from './ProductsContent.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {getCategories, getProducts} from "../../Hooks/Hooks";
import {setCategories, setProducts} from "../../redux/MainSlice";
import loader from "../../assets/img/loader.svg";
import axios from "axios";
import DeleteModal from "../DeleteModal/Index";
import moment from "moment";

function Index() {

  const theme = useSelector((state: RootState) => state.MainSlice.theme);

  const MuiTheme = useTheme();

  const {products, selectRestaurantId, categories} = useSelector((state: RootState) => state.MainSlice);
  const {accessToken} = useSelector((state: RootState) => state.UserSlice);

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if(accessToken && selectRestaurantId) {
      setLoading(true)
      getProducts({id: selectRestaurantId}).then(response => {
        dispatch(setProducts([response.type === 'error' ? [] : response.products]))
        setLoading(false);
      })
      getCategories({accessToken: accessToken, id: selectRestaurantId}).then(response => {
        dispatch(setCategories([...response.type === 'error' ? [] : response.categories]))
      })
    }
  }, [selectRestaurantId])

  const [modal, setModal] = useState(false);

  const deleteProduct = async () => {
    try {
      const result = await axios.delete(`https://api.vkusclick.ru/v1/restaurants/${selectRestaurantId}/dishes/${selectId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      dispatch(setProducts([products.filter(item => item._id !== selectId)]))
    } catch (e) {

    }
  }

  const [searchValue, changeSearchValue] = useState('')
  const [selectId, setId] = useState<string>('');

  function byField(field: any) {
    return (a: any, b: any) => a[field] > b[field] ? 1 : -1;
  }

  const [sortedProducts, setSortedProducts] = useState<any>([]);
  const [sortBy, changeSort] = useState({
    type: 'updatedAt',
    reverse: true,
  })

  useEffect(() => {
    if(products && categories) {
      if(sortBy.reverse) {
        setSortedProducts([...products.map((item: any) => {
          // @ts-ignore
          return {...item, categoryTitle: categories?.find(id => id._id === item.categoryId) && categories?.find(id => id._id === item.categoryId).title ? categories?.find(id => id._id === item.categoryId).title : '-'}
        })].sort(byField(sortBy.type)).reverse());
      } else {
        setSortedProducts([...products.map((item: any) => {
          // @ts-ignore
          return {...item, categoryTitle: categories?.find(id => id._id === item.categoryId) && categories?.find(id => id._id === item.categoryId).title ? categories?.find(id => id._id === item.categoryId).title : '-'}
        })].sort(byField(sortBy.type)))
      }
    }
  }, [products, sortBy, categories])


  return (
    <>
      {
        modal && <DeleteModal removeFc={deleteProduct} setActive={setModal} text={'блюдо'}/>
      }
      <div className={styles.content} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 4px 18px rgba(75, 70, 92, 0.1)'}}>
        <div className={styles.top}>
          <div className={styles.searchWrap} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}}>
            <Icon color={theme === 'light' ? '#4B465C66' : '#8692D066'} icon="material-symbols:search-rounded" />
            <input onChange={(e) => changeSearchValue(e.target.value)} value={searchValue} type="text" className={styles.search} placeholder={'Поиск'}/>
          </div>
          <NavLink to={'/products/add'} className={styles.addBtn} style={{backgroundColor: "#ff9f43"}}>
            <Icon icon="ph:plus-bold" color={'#fff'}/>
            <p>Добавить блюдо</p>
          </NavLink>
        </div>
        <div className={styles.tableTop} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}}>
          <div className={styles.colLarge}>
            <p className={styles.title} onClick={() => changeSort({type: 'title', reverse: sortBy.type !== 'title' ? true : !sortBy.reverse})}>Название</p>
            <div className={styles.sortBtns} onClick={() => changeSort({type: 'title', reverse: sortBy.type !== 'title' ? true : !sortBy.reverse})}>
              <button>
                <Icon color={sortBy.type === 'title' && !sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-up" />
              </button>
              <button>
                <Icon color={sortBy.type === 'title' && sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-down" />
              </button>
            </div>
          </div>
          <div className={styles.colLarge}>
            <p className={styles.title} onClick={() => changeSort({type: 'categoryTitle', reverse: sortBy.type !== 'categoryTitle' ? true : !sortBy.reverse})}>Категория</p>
            <div className={styles.sortBtns} onClick={() => changeSort({type: 'categoryTitle', reverse: sortBy.type !== 'categoryTitle' ? true : !sortBy.reverse})}>
              <button>
                <Icon color={sortBy.type === 'categoryTitle' && !sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-up" />
              </button>
              <button>
                <Icon color={sortBy.type === 'categoryTitle' && sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-down" />
              </button>
            </div>
          </div>
          <div className={`${styles.colLarge} ${styles.colPrice}`}>
            <p className={styles.title} onClick={() => changeSort({type: 'price', reverse: sortBy.type !== 'price' ? true : !sortBy.reverse})}>Цена</p>
            <div className={styles.sortBtns} onClick={() => changeSort({type: 'price', reverse: sortBy.type !== 'price' ? true : !sortBy.reverse})}>
              <button>
                <Icon color={sortBy.type === 'price' && !sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-up" />
              </button>
              <button>
                <Icon color={sortBy.type === 'price' && sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-down" />
              </button>
            </div>
          </div>
          <div className={styles.colLarge}>
            <p className={styles.title} onClick={() => changeSort({type: 'active', reverse: sortBy.type !== 'active' ? true : !sortBy.reverse})}>Статус</p>
            <div className={styles.sortBtns} onClick={() => changeSort({type: 'active', reverse: sortBy.type !== 'active' ? true : !sortBy.reverse})}>
              <button>
                <Icon color={sortBy.type === 'active' && !sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-up" />
              </button>
              <button>
                <Icon color={sortBy.type === 'active' && sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-down" />
              </button>
            </div>
          </div>
          <div className={styles.colLarge}>
            <p className={styles.title} onClick={() => changeSort({type: 'updatedAt', reverse: sortBy.type !== 'updatedAt' ? true : !sortBy.reverse})}>Последнее изменение</p>
            <div className={styles.sortBtns} onClick={() => changeSort({type: 'updatedAt', reverse: sortBy.type !== 'updatedAt' ? true : !sortBy.reverse})}>
              <button>
                <Icon color={sortBy.type === 'updatedAt' && !sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-up" />
              </button>
              <button>
                <Icon color={sortBy.type === 'updatedAt' && sortBy.reverse ? '#9499a6' : '#5C5F66'} icon="tabler:chevron-down" />
              </button>
            </div>
          </div>
          <div className={styles.colSmall}>
            <p className={styles.title}>Действия</p>
          </div>
        </div>
        <div className={styles.tableContent}>
          {
            loading ?
              <img className={styles.loader} src={loader}/> :
              sortedProducts?.length === 0 ?
                <p className={styles.notFound}>Ничего не найдено</p> :
                sortedProducts?.map((item: any) => {
                  return (
                    item.title.toLowerCase().includes(searchValue.toLowerCase()) &&
                    <div style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}} key={item._id} className={styles.categoriesItem}>
                      <div className={styles.colLarge}>
                        <div className={styles.imgWrap} style={{backgroundImage: item.image !== '' ? `url(https://s3.vkusclick.ru/${item.image})` : 'none', backgroundSize: 'cover', backgroundPosition: 'center'}}>
                          {item.image === '' && <span className={styles.noImage} style={{backgroundColor: "#ff9f43"}}>{item.title[0]}</span>}
                        </div>
                        <p className={styles.itemTitle}>{item.title}</p>
                      </div>
                      <div className={styles.colLarge}>
                        {/*@ts-ignore*/}
                        <p className={styles.category}>{item.categoryTitle}</p>
                      </div>
                      <div className={`${styles.colLarge} ${styles.colPrice}`}>
                        <p className={styles.date}>{item.price}</p>
                      </div>
                      <div className={styles.colLarge}>
                        <p className={`${styles.status} ${item.active ? styles.active : styles.inactive}`}>{item.active ? 'Активно' : 'Неактивно'}</p>
                      </div>

                      <div className={styles.colLarge}>
                        <p className={styles.date}>{moment(item.updatedAt).format('DD.MM.YYYY HH:mm').toString()}</p>
                      </div>
                      <div className={styles.colSmall}>
                        <button onClick={() => navigate('/products/edit?id=' + item._id)}>
                          <Icon icon="tabler:pencil" width={22} height={22} color={"#ff9f43"}/>
                        </button>
                        <button onClick={() => {
                          setModal(true);
                          setId(item._id);
                        }}>
                          <Icon icon="tabler:trash" width={22} height={22} color={MuiTheme.palette.error.light}/>
                        </button>
                      </div>
                    </div>
                  )
                })
          }
        </div>
      </div>
    </>
  );
}

export default Index;
