import React, {useEffect, useState} from 'react';
import DatePickerComponent from "./DatePicker";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import styles from './OrdersStatisticContent.module.scss'
import {Icon} from "@iconify/react";
import {Chart} from "./Chart";
import OrdersListStatistic from "../OrdersListStatistic";
import moment from "moment/moment";
import dayjs from "dayjs";

function Index() {

  const {theme} = useSelector((state: RootState) => state.MainSlice);

  const [fromDate1, setFromDate1] = useState(false);
  const [fromDate2, setFromDate2] = useState(false);
  const [toDate1, setToDate1] = useState(false);
  const [toDate2, setToDate2] = useState(false);

  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();



  useEffect(() => {
    const date = new Date();
    setStartDate(dayjs(date));
    setEndDate(dayjs(date));
  }, [])

  return (
    <div className={styles.content}>
      <div className={styles.inputs}>
        <div className={styles.inputWrap}>
          <p className={styles.inputTitle}>Дата от:</p>
          <div onClick={() => setFromDate1(true)} className={styles.inputIconWrap} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968', backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349'}}>
            <Icon icon={'tabler:calendar'}/>
            <input value={moment(new Date(startDate)).format('DD.MM.YYYY').toString()} type="text" className={styles.input} placeholder={'Placeholder'}/>
          </div>
          <div className={styles.pickerContent} style={{backgroundColor: theme === 'light' ? '#fff' : '#2F3349', width: '266px', boxShadow: theme === 'light' ? '0px 4px 16px rgba(165, 163, 174, 0.45)' : 'rgba(15, 20, 34, 0.4) 0px 2px 4px', borderRadius: '6px'}}>
            <DatePickerComponent value={startDate} setValue={setStartDate} visible={fromDate1} setVisible={setFromDate1}/>
          </div>
        </div>
        <div className={styles.inputWrap}>
          <p className={styles.inputTitle}>Дата до:</p>
          <div onClick={() => setToDate1(true)} className={styles.inputIconWrap} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968', backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349'}}>
            <Icon icon={'tabler:calendar'}/>
            <input value={moment(new Date(endDate)).format('DD.MM.YYYY').toString()} type="text" className={styles.input} placeholder={'Placeholder'}/>
          </div>
          <div className={styles.pickerContent} style={{backgroundColor: theme === 'light' ? '#fff' : '#2F3349', width: '266px', boxShadow: theme === 'light' ? '0px 4px 16px rgba(165, 163, 174, 0.45)' : 'rgba(15, 20, 34, 0.4) 0px 2px 4px', borderRadius: '6px'}}>
            <DatePickerComponent value={endDate} setValue={setEndDate} visible={toDate1} setVisible={setToDate1}/>
          </div>
        </div>
      </div>
      <Chart/>
      <div className={styles.inputs}>
        <div className={styles.inputWrap}>
          <p className={styles.inputTitle}>Дата от:</p>
          <div onClick={() => setFromDate2(true)} className={styles.inputIconWrap} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968', backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349'}}>
            <Icon icon={'tabler:calendar'}/>
            <input type="text" className={styles.input} placeholder={'Placeholder'}/>
          </div>
          <div className={styles.pickerContent} style={{backgroundColor: theme === 'light' ? '#fff' : '#2F3349', width: '266px', boxShadow: theme === 'light' ? '0px 4px 16px rgba(165, 163, 174, 0.45)' : 'rgba(15, 20, 34, 0.4) 0px 2px 4px', borderRadius: '6px'}}>
            {/*<DatePickerComponent visible={fromDate2} setVisible={setFromDate2}/>*/}
          </div>
        </div>
        <div className={styles.inputWrap}>
          <p className={styles.inputTitle}>Дата до:</p>
          <div onClick={() => setToDate2(true)} className={styles.inputIconWrap} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968', backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349'}}>
            <Icon icon={'tabler:calendar'}/>
            <input type="text" className={styles.input} placeholder={'Placeholder'}/>
          </div>
          <div className={styles.pickerContent} style={{backgroundColor: theme === 'light' ? '#fff' : '#2F3349', width: '266px', boxShadow: theme === 'light' ? '0px 4px 16px rgba(165, 163, 174, 0.45)' : 'rgba(15, 20, 34, 0.4) 0px 2px 4px', borderRadius: '6px'}}>
            {/*<DatePickerComponent visible={toDate2} setVisible={setToDate2}/>*/}
          </div>
        </div>
      </div>
      <OrdersListStatistic/>
    </div>
  );
}

export default Index;
