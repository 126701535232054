import React from "react";
import styles from "./RangeInput.module.scss";

const RangeInput = ({
                      maxValue,
                      minValue,
                      value,
                      changeValue,
                      title,
                      abr,
                      percent,
                      contr,
                      loading,
                    }: {
  maxValue: number;
  minValue: number;
  value: any;
  changeValue: any;
  title: string;
  abr: string;
  percent: boolean;
  contr?: number;
  loading: boolean;
}) => {
  const inputRef: any = React.useRef();

  const [valueText, changeText] = React.useState(value);
  const [edit, changeEdit] = React.useState(false);
  const percentRef: any = React.useRef();

  React.useEffect(() => {
    changeText(value);
  }, [value]);

  const setValue = () => {
    changeValue({
      value:
        valueText > maxValue
          ? maxValue
          : valueText < minValue || isNaN(valueText)
            ? minValue
            : valueText,
    });
    changeText(value);
  };

  const applyValueKey = (e: any) => {
    if (e.key === "Enter") {
      !percent ? inputRef.current.blur() : percentRef.current.blur();
      setValue();
    }
  };

  const sep = " ";

  return (
    <div className={loading ? `${styles.RangeInput} ${styles.disable}` : styles.RangeInput}>
      <input
        className={styles.Range}
        style={{
          background: value === "0" ? 'rgb(123, 123, 123) 9.09091%' : `linear-gradient(to right, #FF9F43 0%, #FF9F43 ${((100/maxValue)*value).toFixed(1)}%, rgb(123, 123, 123) 9.09091% ${((100/maxValue)*value).toFixed(1)}%, rgb(123, 123, 123) 9.09091% 100%)`
        }}
        max={maxValue}
        min={minValue}
        value={value}
        disabled={loading}
        onChange={(e) => changeValue(e.target.value)}
        type="range"
      />
    </div>
  );
};

export default RangeInput;
