import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import './App.scss';
import {GlobalStyles, ThemeProvider} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "./redux/store";
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import SignInPage from "./Pages/SignInPage";
import RegistrationPage from "./Pages/RegistrationPage";
import ResetPasswordPage from "./Pages/ResetPasswordPage";
import Header from "./Layout/Header";
import {muiTheme} from "./Theme";
import Layout from "./Layout";
import CategoriesPage from "./Pages/CategoriesPage";
import CategorieAddPage from "./Pages/CategorieAddPage";
import {setLayoutVisible, themeToggle} from "./redux/MainSlice";
import ProductsPage from "./Pages/ProductsPage";
import ProductAddPage from "./Pages/ProductAddPage";
import loader from './assets/img/loader.svg'
import TablesPage from "./Pages/TablesPage";
import QrGenerationPage from "./Pages/QrGenerationPage";
import OrdersListPage from "./Pages/OrdersListPage";
import OrderPage from "./Pages/OrderPage";
import WorkersPage from "./Pages/WorkersPage";
import WorkerAddPage from "./Pages/WorkerAddPage";
import WorkerEditPage from "./Pages/WorkerEditPage";
import ManagerEditPage from "./Pages/ManagerEditPage";
import PopularProductsPage from "./Pages/PopularProductsPage";
import AddToOrderPage from "./Pages/AddToOrderPage";
import CalculatorPage from "./Pages/CalculatorPage";
import TryDemoPage from "./Pages/TryDemoPage";
import ServiceListPage from "./Pages/ServiceListPage";
import SettingsPlacePage from "./Pages/SettingsPlacePage";
import HelpPage from "./Pages/HelpPage";
import AddRestaurantPage from "./Pages/AddRestaurantPage";
import ErrorPage from "./Pages/ErrorPage";
import OrdersStatisticPage from "./Pages/OrdersStatisticPage";
import ProductsStatisticPage from "./Pages/ProductsStatisticPage";
import CategoryStatisticPage from "./Pages/CategoryStatisticPage";
import ActivateAccountPage from "./Pages/ActivateAccountPage";
import {getAccessToken, getAuth} from "./Hooks/Hooks";
import {setCompany, setRestaurants, setTokens, setUser} from "./redux/UserSlice";
import CategoryEditPage from "./Pages/CategoryEditPage";
import ProductEditPage from "./Pages/ProductEditPage";
import DashboardPage from './Pages/DashboardPage';
import { YMInitializer } from 'react-yandex-metrika';

function App() {

  const theme = useSelector((state: RootState) => state.MainSlice.theme);
  const {user, company} = useSelector((state: RootState) => state.UserSlice);

  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if((location.pathname !== '/reset-password' && location.pathname !== '/try-demo' && location.pathname !== '/registration' && location.pathname !== '/sign-in' && location.pathname !== '/add-restaurant' && location.pathname !== '/activation')) {
      dispatch(setLayoutVisible(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const [start, setStart] = useState(true);

  useEffect(() => {
    dispatch(themeToggle(localStorage.getItem('theme') ? localStorage.getItem('theme') === 'light' ? 'light' : 'dark' : 'light' ));
    if(user === null) {
      setLoading(true);
      if(localStorage.getItem('refreshToken')) {
        if(start) {
          getAccessToken({refreshToken: localStorage.getItem('refreshToken') as string}).then(
            tokens => {
                if(tokens instanceof Error) {
                  if(location.pathname !== '/sign-in' && location.pathname !== '/try-demo' && location.pathname !== '/activation' && location.pathname !== '/registration' && location.pathname !== '/add-restaurant') {
                    if(!localStorage.getItem('refreshToken')) {
                      navigate('/auth-error')
                    } else {
                      navigate('/sign-in')
                      setLoading(false)

                    }
                  }
                  setLoading(false)
                } else {
                  dispatch(setTokens({...tokens}))
                  getAuth({accessToken: tokens.accessToken}).then(
                    response => {
                      if (response.type !== 'error') {
                        dispatch(setUser({...response.user}));
                        if(response.user.role === 'director') {
                          dispatch(setCompany({...response.company}));
                        }
                        if (response.user?.restaurantsId.length !== 0) {
                          dispatch(setRestaurants([response.restaurants]));
                        }
                        if(!response.user?.emailConfirmed && location.pathname !== '/activation' && response.user?.role === 'director' && location.pathname !== '/registration') {
                          navigate('/activation')
                        } else if (response.user?.restaurantsId.length === 0 && response.user?.emailConfirmed) {
                          navigate('/add-restaurant')
                        }
                      } else {
                      }
                      setLoading(false)
                    });
                }
            }
          )
          setInterval(() => {
            getAccessToken({refreshToken: localStorage.getItem('refreshToken') as string}).then(
              tokens => {
                  if(tokens instanceof Error) {
                    if(location.pathname !== '/sign-in' && location.pathname !== '/try-demo' && location.pathname !== '/activation' && location.pathname !== '/registration' && location.pathname !== '/add-restaurant') {
                      if(!localStorage.getItem('refreshToken')) {
                        navigate('/auth-error')
                      } else {
                        navigate('/sign-in')
                        setLoading(false)

                      }
                    }
                    setLoading(false)
                  } else {
                    dispatch(setTokens({...tokens}))
                    getAuth({accessToken: tokens.accessToken}).then(
                      response => {
                        if (response.type !== 'error') {
                          dispatch(setUser({...response.user}));
                          if(response.user.role === 'director') {
                            dispatch(setCompany({...response.company}));
                          }
                          if (response.user?.restaurantsId.length !== 0) {
                            dispatch(setRestaurants([response.restaurants]));
                          }
                          if(!response.user?.emailConfirmed && location.pathname !== '/activation' && response.user?.role === 'director') {
                            navigate('/activation')
                          } else if (response.user?.restaurantsId.length === 0 && response.user?.emailConfirmed) {
                            navigate('/add-restaurant')
                          }
                        } else {
                        }
                        setLoading(false)
                      });
                  }
              }
            )
            setStart(false);
          }, 180000)
        }
      }
      else {
        setLoading(false)
        if(location.pathname !== '/sign-in' && location.pathname !== '/try-demo' && location.pathname !== '/activation' && location.pathname !== '/registration' && location.pathname !== '/add-restaurant' && location.pathname !== '/reset-password') {
          navigate('/sign-in')
        }
      }

    }
    else if(location.pathname !== '/sign-in' && location.pathname !== '/try-demo' && location.pathname !== '/add-restaurant' && location.pathname !== '/registration' && location.pathname !== '/activation') {
      if (!user.emailConfirmed && user.role === 'director') {
        navigate('/activation');
      } else if(user.restaurantsId.length === 0) {
        navigate('/add-restaurant');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useCallback(() => {
    if(company?.blocked && location.pathname !== '/help' && location.pathname !== '/calculator') {
      navigate('/calculator');
    }
  }, [location])

  const[startBlock, setStartBlock] = useState(true);

  const [companyTariff, setCompanyTariff] = useState(''); 

  useEffect(() => {
    if(company && company.subscriptionModulesId) {
      company.subscriptionModulesId.map((moduleId: any) => {
        if(moduleId === '653ade5c8f5d7381aba0ee28') {
          setCompanyTariff('start_tariff');
        }
        if(moduleId === '653ade5c8f5d7381aba0ee29') {
          setCompanyTariff('optimal_tariff');
        }
        if(moduleId === '644b1634d059fa38049e6b55') {
          setCompanyTariff('advanced_tariff');
        }
      })  
    }
  }, [company])

  useEffect(() => {
    if(company?.blocked && location.pathname !== '/calculator') {
      if(startBlock) {
        navigate('/calculator');
        setStartBlock(false);
      }
    } else if (location.pathname === '/calculator') {
      setStartBlock(false);
    }
  }, [company])

  return (
  <>
    <YMInitializer accounts={[94417584]} options={{webvisor: true, defer: true}} version="2"/>
    <ThemeProvider theme={muiTheme}>
      <GlobalStyles styles={{body: {backgroundColor: theme === 'light' ? '#F8F7FA' : '#25293C'}}}/>
      <div className={`App ${theme}`}>
        {!loading ? <div className="App-content">
          <Layout/>
          <div className="layout-content">
            <div className="container">
              <Header/>
              <Routes>
                    <Route path={'/'} element={<Navigate to={company?.blocked ? '/calculator' : companyTariff === 'start_tariff' ? '/products' : '/orders-list'}/>}/>
                    <Route path={'/sign-in'} element={<SignInPage/>}/>
                    <Route path={'/try-demo'} element={<TryDemoPage/>}/>
                    <Route path={'/registration'} element={<RegistrationPage/>}/>
                    <Route path={'/reset-password'} element={<ResetPasswordPage/>}/>
                    <Route path={'/categories'} element={<CategoriesPage/>}/>
                    <Route path={'/categories/add'} element={<CategorieAddPage/>}/>
                    <Route path={'/categories/edit'} element={<CategoryEditPage/>}/>
                    <Route path={'/dashboard'} element={<DashboardPage/>}/>
                    <Route path={'/products'} element={<ProductsPage/>}/>
                    <Route path={'/products/add'} element={<ProductAddPage/>}/>
                    <Route path={'/products/edit'} element={<ProductEditPage/>}/>
                    <Route path={'/tables'} element={<TablesPage/>}/>
                    <Route path={'/qr'} element={<QrGenerationPage/>}/>
                    <Route path={'/orders-list'} element={<OrdersListPage/>}/>
                    <Route path={'/orders-list/order'} element={<OrderPage/>}/>
                    <Route path={'/workers'} element={<WorkersPage/>}/>
                    <Route path={'/workers/add'} element={<WorkerAddPage/>}/>
                    <Route path={'/workers/edit'} element={<WorkerEditPage/>}/>
                    <Route path={'/workers/edit/manager'} element={<ManagerEditPage/>}/>
                    <Route path={'/popular-products'} element={<PopularProductsPage/>}/>
                    <Route path={'/add-to-order'} element={<AddToOrderPage/>}/>
                    <Route path={'/calculator'} element={<CalculatorPage/>}/>
                    <Route path={'/list-services'} element={<ServiceListPage/>}/>
                    <Route path={'/settings-place'} element={<SettingsPlacePage/>}/>
                    <Route path={'/help'} element={<HelpPage/>}/>
                    <Route path={'/activation'} element={<ActivateAccountPage/>}/>
                    <Route path={'/add-restaurant'} element={<AddRestaurantPage/>}/>
                    <Route path={'/orders-statistic'} element={<OrdersStatisticPage/>}/>
                    <Route path={'/products-statistic'} element={<ProductsStatisticPage/>}/>
                    <Route path={'/category-statistic'} element={<CategoryStatisticPage/>}/>
                    <Route path={'/server-error'} element={<ErrorPage title={'Ошибка сервера :О'} subtitle={'Наша команда уже в курсе проблемы. В ближайшее время мы исправим ее'}/>}/>
                    <Route path={'/auth-error'} element={<ErrorPage title={'Ошибка авторизации :/'} subtitle={'У вас нет разрешения на просмотр этой страницы с использованием учетных данных, которые вы указали при входе в систему'}/>}/>
                    <Route path={'/*'} element={<ErrorPage title={'Страница не найдена :('} subtitle={'Ошибка в адресе или такой страницы не существует'}/>}/>
                  </Routes>
            </div>
          </div>
        </div> : <div className={'loader'}><img src={loader}/></div>}
      </div>
    </ThemeProvider>
  </>
  );
}

export default App;
