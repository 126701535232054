import React, {useEffect, useRef, useState} from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import image from '../../assets/img/categories-slider-img.jpg';
import styles from './CategorieImageSlider.module.scss';

import 'swiper/css';
import {useTheme} from "@mui/material";
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import {Icon} from "@iconify/react";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {getCategoryImages} from "../../Hooks/Hooks";

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 18,
  height: 18,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: 'transparent',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outlineOffset: 2,
  },
  'input ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#FF9F43',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 18,
    height: 18,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input ~ &': {
    backgroundColor: '#FF9F43',
  },
});

function Index({activeImage, setActiveSlide, activeSlide, setActiveImage}: {activeImage: any, setActiveSlide: any, activeSlide: number | null | boolean, setActiveImage: any}) {

  const [index, setIndex] = useState<number | null>(0);

  const [slides, setSlides] = useState<string[]>([]);
  const {accessToken} = useSelector((state: RootState) => state.UserSlice);
  const {selectRestaurantId, theme} = useSelector((state: RootState) => state.MainSlice);

  useEffect(() => {
    if(accessToken && selectRestaurantId && activeImage !== null) {
      getCategoryImages({id: selectRestaurantId}).then(response => {
        setSlides([...response.images])
        if(activeImage && activeImage !== '') {
          response.images.map((item: any, i: number) => {
            if(item === activeImage) {
              setIndex(i);
              setActiveSlide(i)
            }
          })
        } else {
          setIndex(0);
          setActiveSlide(0)
        }
      });
    }
  }, [accessToken, selectRestaurantId, activeImage])

  const MuiTheme = useTheme();

  const nextRef: any = useRef();
  const swiperRef: any = useRef();

  useEffect(() => {
    if(!activeImage && slides) {
      setActiveImage(slides[0]);
    }
  }, [activeImage, slides])

  return (
    <div className={styles.swiperWrapper}>
      {
        slides.length > 0 ?
        <>
          <Swiper
            spaceBetween={20}
            slidesPerView={'auto'}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            navigation={{
              nextEl: nextRef?.current
            }}

            className={styles.slider}
          >
            {slides.map((slide: string, i: number) => {
              return (
                <SwiperSlide key={slide} className={slide === activeImage ? `${styles.slide} ${styles.active}` : styles.slide}>
                  <div style={{border: slide === activeImage ? `1px solid ${"#ff9f43"}` : '1px solid transparent' }} className={styles.slideContent} onClick={() => {
                    setActiveImage(slides[i])
                    setActiveSlide(i)
                  }}>
                    <img src={slide} alt=""/>
                    <Checkbox
                      sx={{
                        '&:hover': { bgcolor: 'transparent' },
                      }}
                      disableRipple
                      color="default"
                      checkedIcon={<BpCheckedIcon />}
                      icon={<BpIcon />}
                      inputProps={{ 'aria-label': 'Checkbox demo' }}
                      className={slide === activeImage? `${styles.checkBox} ${styles.active}` : styles.checkBox}
                      checked={slide === activeImage}
                    />
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
          <button className={`${styles.next} ${theme === 'dark' ? styles.dark : ''}`} ref={nextRef} onClick={() => {
                swiperRef.current?.slideNext()
          }}>
            <Icon color={'#4B4B4B'} icon={'tabler:arrow-narrow-right'} width={24} height={24}/>
          </button>
          <button className={`${styles.prev} ${theme === 'dark' ? styles.dark : ''}`} ref={nextRef} onClick={() => {
            swiperRef.current?.slidePrev();
            if(index) {
                setIndex(index - 1);
            }
          }}>
            <Icon color={'#4B4B4B'} icon={'tabler:arrow-narrow-left'} width={24} height={24}/>
          </button>
        </> : null
      }

    </div>
  );
}

export default Index;
