import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {setTokens} from "../../redux/UserSlice";
import ym from 'react-yandex-metrika';

function Index() {

    const email = 'demo@vkusclick.ru';
    const password = 'demo';

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const sendAuth = async () => {
        try {
            const result = await axios.post('https://api.vkusclick.ru/v1/employees/login', {
                email, password
            });
            if (result) {
                dispatch(setTokens({
                    accessToken: result.data.accessToken,
                    refreshToken: result.data.refreshToken
                }))

                ym('reachGoal', 'loginToDemo');
                localStorage.setItem('refreshToken', result.data.refreshToken);
                navigate('/');
            }
        } catch (e: any) {
            console.log(e);
            navigate('/');
        }
    }

    useEffect(() => {
        sendAuth();
    }, [])

    return null;

}


export default Index;