import React from 'react';
import PageTitle from "../../Components/PageTitle";
import TablesContent from "../../Components/TablesContent";

function Index() {
  return (
    <div className={'tables-page'}>
      <PageTitle title={'Назначение столов'}/>
      <TablesContent/>
    </div>
  );
}

export default Index;
