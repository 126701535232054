import React from 'react';
import SignIn from "../../Components/SignIn";

function Index() {
  return (
    <div className={'sign-page'}>
      <SignIn/>
    </div>
  );
}

export default Index;
