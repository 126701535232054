import { useEffect, useState } from 'react'

import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MuiFormControl, { FormControlProps } from '@mui/material/FormControl'
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getCategories } from "../../Hooks/Hooks";
import { setCategories } from "../../redux/MainSlice";
import { useLocation } from "react-router-dom";


const CategorySelect = ({ change, defaultValue }: { change: any, defaultValue?: string }) => {
  const [value, setValue] = useState<string>('')
  const handleChange = (event: SelectChangeEvent) => {
    change(event.target.value as string)
    setValue(event.target.value as string)
  }

  const localTheme = useSelector((state: RootState) => state.MainSlice.theme);
  const { selectRestaurantId, categories } = useSelector((state: RootState) => state.MainSlice);
  const { accessToken } = useSelector((state: RootState) => state.UserSlice);

  const dispatch = useDispatch();

  const FormControl = styled(MuiFormControl)<FormControlProps>(({ theme }) => ({
    '& > .MuiInputBase-root': {
      '&.MuiInput-root:before, &.MuiInput-root:after': {
        border: 0
      }
    },
    '& .MuiInputBase-input': {
      fontFamily: '"Public Sans", sans-serif',
      fontSize: 13,
      borderRadius: 4,
      position: 'relative',
      color: localTheme === 'dark' ? '#CFD3EC' : '#4B465C',
      padding: '7px 34px 7px 10px',
      backgroundColor: 'transparent',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      border: localTheme === 'light' ? '1px solid #DBDADE' : `1px solid #434968`,
      fontStyle: 'normal',
      '&:focus-within': {
        borderRadius: 4,
        borderColor: theme.palette.warning.light,
      }
    }
  }))

  useEffect(() => {
    if (accessToken && selectRestaurantId) {
      getCategories({ accessToken: accessToken, id: selectRestaurantId }).then(response => {
        dispatch(setCategories(response.categories.sort((a: any, b: any) => {
          return a - b;
        }).reverse()))
        if (!defaultValue) {
          if (!value) {
            setValue(response.categories.filter((item: any) => item.active)[0]._id)
            change(response.categories.filter((item: any) => item.active)[0]._id)
          }
        } else {
          setValue(defaultValue)
          change(defaultValue)
        }
      })
    }
  }, [accessToken, selectRestaurantId])


  return (
    <div className='demo-space-x'>
      <FormControl fullWidth >
        <Select
          value={value}
          input={<InputBase />}
          onChange={handleChange}
          id='demo-customized-select'
          labelId='demo-customized-select-label'
          IconComponent={(props) => <KeyboardArrowDownIcon sx={{ fill: localTheme === 'light' ? '#6F6B7D' : '#CFD3EC' }} {...props} />}
        >
          {
            categories?.map(item => {
              if (item.active) {
                return (
                  <MenuItem key={item._id} value={item._id}>{item.title}</MenuItem>
                )
              }
            })
          }
        </Select>
      </FormControl>
    </div>
  )
}

export default CategorySelect
