import {useEffect, useState} from 'react'

import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MuiFormControl, { FormControlProps } from '@mui/material/FormControl'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {themeToggle} from "../../redux/MainSlice";


const SettingsPlaceSelect = ({changeFunc, value}: {changeFunc: any, value: string}) => {

  const handleChange = (event: SelectChangeEvent) => {
    changeFunc(event.target.value as string)
  }

  const localTheme = useSelector((state: RootState) => state.MainSlice.theme);

  const FormControl = styled(MuiFormControl)<FormControlProps>(({ theme }) => ({
    '& > .MuiInputBase-root': {
      '&.MuiInput-root:before, &.MuiInput-root:after': {
        border: 0
      }
    },
    '& .MuiInputBase-input': {
      fontFamily: '"Public Sans", sans-serif',
      fontSize: 13,
      borderRadius: 4,
      position: 'relative',
      color: localTheme === 'dark' ? '#CFD3EC' : '#4B465C',
      padding: '7px 34px 7px 10px',
      backgroundColor: 'transparent',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      border: localTheme === 'light' ? '1px solid #DBDADE' : `1px solid #434968`,
      fontStyle: 'normal',
      '&:focus-within': {
        borderRadius: 4,
        borderColor: theme.palette.warning.light,
      }
    }
  }))

  return (
    <div className='demo-space-x'>
      <FormControl fullWidth sx={{width: '100%'}}>
        <Select
          value={value}
          input={<InputBase />}
          onChange={handleChange}
          id='demo-customized-select'
          labelId='demo-customized-select-label'
          IconComponent={(props) => <KeyboardArrowDownIcon sx={{fill: localTheme === 'light' ? '#6F6B7D' : '#CFD3EC'}} {...props}/>}
        >
          <MenuItem value='dark' style={{fontFamily: '"Public Sans", sans-serif'}}>
            Темная
          </MenuItem>
          <MenuItem value={'light'} style={{fontFamily: '"Public Sans", sans-serif'}}>Светлая</MenuItem>
        </Select>
      </FormControl>
    </div>
  )
}

export default SettingsPlaceSelect
