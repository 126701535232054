import React from 'react';
import PageTitle from "../../Components/PageTitle";
import SettingsPlaceContent from "../../Components/SettingsPlaceContent";

function Index() {
  return (
    <div className={'settings-place-page'}>
      <PageTitle title={'Заведение'}/>
      <SettingsPlaceContent/>
    </div>
  );
}

export default Index;
