import React, {useEffect, useState} from 'react';
import styles from './QrTables.module.scss';
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import Select from "./Select";
import {useTheme} from "@mui/material";
import Tables from "./Tables";
import {getQrSettings} from "../../../Hooks/Hooks";
import axios from "axios";
import Input from "../../SignIn/Input";

function Index({value, setValue, selectedIndex, changeSelectedIndex, settings, setSettings, start, end, changeStart, changeEnd, getSettings}: {getSettings: any, changeStart: any, changeEnd: any, start: number, end: number, value: string, setValue: any, selectedIndex: any, changeSelectedIndex: any, settings: any, setSettings: any}) {

  const {theme, selectRestaurantId} = useSelector((state: RootState) => state.MainSlice);
  const {user, accessToken} = useSelector((state: RootState) => state.UserSlice);

  const MuiTheme = useTheme();

  const [titleError, setTitleError] = useState({
    active: false,
    text: ''
  })

  const [startError, setStartError] = useState({
    active: false,
    text: '1'
  })

  const [endError, setEndError] = useState({
    active: false,
    text: ''
  })

  const [descriptionError, setDescriptionError] = useState({
    active: false,
    text: ''
  })
  const sendOptions = async () => {
    if(end < start || end.toString().length === 0 || settings.title.length === 0 || settings.description.length === 0) {
      if(end < start) {
        setEndError({
          active: true,
          text: 'Число не может быть меньше числа в поле "Номера столов от"'
        })
      }
      if(end.toString().length === 0) {
        setEndError({
          active: true,
          text: 'Поле не заполнено'
        })
      }
      if(settings.title.length === 0) {
        setTitleError({
          active: true,
          text: 'Поле не зполнено'
        })
      }
      if(settings.description.length === 0) {
        setDescriptionError({
          active: true,
          text: 'Поле не зполнено'
        })
      }
    } else {
      await axios.put(`https://api.vkusclick.ru/v1/restaurants/${selectRestaurantId}/quick_response_code_settings`, {
        title: settings.title,
        description: settings.description,
        style: value
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      await axios.put(`https://api.vkusclick.ru/v1/restaurants/${selectRestaurantId}/tables_settings`, {
        tablesCountStart: start,
        tablesCountEnd: end,
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      getSettings();
    }
  }

  return (
    <div className={styles.content} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 4px 18px rgba(75, 70, 92, 0.1)'}}>
      <div className={styles.inputs}>
        <div className={styles.inputWrap}>
          <p className={styles.inputTitle}>Количество столов в заведении:</p>
          <Input changeFunc={(text: string) => changeEnd(text.replace(/\D/, ''))} value={end.toString()} placeholder={"До"} type={"text"} errorFunc={setEndError} error={endError}/>
        </div>
        <div className={styles.inputWrap}>
          <p className={styles.inputTitle}>Заголовок</p>
          <Input changeFunc={(text: string) => setSettings({...settings, title: text})} value={settings.title} placeholder={"Заголовок"} type={"text"} errorFunc={setTitleError} error={titleError}/>
        </div>
      </div>
      <div className={styles.inputs}>
        <div className={styles.inputWrap}>
          <p className={styles.inputTitle}>Описание</p>
          <Input changeFunc={(text: string) => setSettings({...settings, description: text})} value={settings.description} placeholder={"Описание"} type={"text"} errorFunc={setDescriptionError} error={descriptionError}/>
        </div>
        <div className={styles.inputWrap}>
          <p className={styles.inputTitle}>Вид QR-кода</p>
          <Select value={value} setValue={setValue}/>
        </div>
      </div>
      <div className={styles.inputs} style={{alignItems: 'flex-end', marginBottom: '32px'}}>
        { user?.role === 'demo' ? (
          <button title='Недоступно в демонстрационном заведении' className={styles.btn} style={{backgroundColor: "#ff9f43", color: '#fff', cursor: 'not-allowed'}}>Сохранить</button>            
        ) : (
          <button onClick={sendOptions} className={styles.btn} style={{backgroundColor: "#ff9f43", color: '#fff'}}> 
            Сохранить
          </button>
        )}
      </div>
      <Tables selectedIndex={selectedIndex} changeSelectedIndex={changeSelectedIndex}/>
    </div>
  );
}

export default Index;
