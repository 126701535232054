import React from 'react';
import AddRestaurant from "../../Components/AddRestaurant";

function Index() {
  return (
    <div className={'add-restaurant-page'}>
      <AddRestaurant/>
    </div>
  );
}

export default Index;
