import React, { useEffect, useState } from 'react';
import styles from '../AddCategorieContent/AddCategorieContent.module.scss';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Typography, useTheme } from "@mui/material";
import PageTitle from "../PageTitle";
import { Icon } from "@iconify/react";
import CategorieImageSlider from "../CategorieImageSlider";
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Input from "../SignIn/Input";
import { getCategories, isValidDate } from "../../Hooks/Hooks";
import { setCategories } from "../../redux/MainSlice";
import qs from "qs";
import BasicTimePicker from "../TimePicker";
import dayjs from "dayjs";
import { TimePicker } from 'antd';

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 30,
  height: 18,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: '3px 3px 0 3px',
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#FF9F43',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 12,
    height: 12,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

function Index() {

  const { theme, selectRestaurantId, categories } = useSelector((state: RootState) => state.MainSlice);
  const { user, accessToken } = useSelector((state: RootState) => state.UserSlice);
  const MuiTheme = useTheme();

  const [checked, setCheck] = useState(true);

  const [categoryInfo, setCategoryInfo] = useState({
    title: '',
    priority: '',
    active: `${checked}`,
  })

  const navigate = useNavigate();

  const [activeSlide, setActiveSlide] = useState<number | null | false>(null);
  const [activeImage, setActiveImage] = useState('')

  const [titleError, setTitleError] = useState({
    active: false,
    text: ''
  })

  const [priorityError, setPriorityError] = useState({
    active: false,
    text: ''
  })

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (!categories) {
      if (accessToken && selectRestaurantId) {
        getCategories({ accessToken: accessToken, id: selectRestaurantId }).then(response => {
          dispatch(setCategories(response.categories.sort((a: any, b: any) => {
            return a - b;
          }).reverse()))
        })
      }
    } else {
      const id = qs.parse(location.search.substring(1)).id;
      const current = categories.find(item => item._id === id);
      if (current) {
        setCategoryInfo({
          title: current.title,
          active: `${current.active}`,
          priority: current.priority.toString()
        })
        if (current.activeTimeEnd && current.activeTimeStart && current.activeTimeEnd !== 'null' && current.activeTimeStart !== 'null') {
          setFromValue(dayjs(new Date(JSON.parse(current.activeTimeStart))))
          setInputFromValue(dayjs(new Date(JSON.parse(current.activeTimeStart))).format('HH:mm'))

          setToValue(dayjs(new Date(JSON.parse(current.activeTimeEnd))))
          setInputToValue(dayjs(new Date(JSON.parse(current.activeTimeEnd))).format('HH:mm'))
        }
        setCheck(current.active)
        if (current?.image) {
          setActiveImage(current?.image)
        } else {
        }
      }
    }
  }, [categories, accessToken, selectRestaurantId])


  const sendCategory = async () => {
    if (categoryInfo.title.trim() === '' || parseInt(categoryInfo.priority) > 9999 || (timeFromValue && !isValidDate(new Date(timeToValue)) || (timeToValue && !isValidDate(new Date(timeFromValue))) || (new Date(timeToValue) < new Date(timeFromValue)))) {
      if (categoryInfo.title.trim() === '') {
        setTitleError({
          active: true,
          text: 'Поле не заполнено'
        })
      }
      if (parseInt(categoryInfo.priority) > 9999) {
        setPriorityError({
          active: true,
          text: 'Приоритет не может быть больше 9999'
        })
      }
      if (!isValidDate(new Date(timeToValue))) {
        setErrorToDate(true)
      }
      if (!isValidDate(new Date(timeFromValue))) {
        setErrorFromDate(true)
      }
      if (new Date(timeToValue) < new Date(timeFromValue)) {
        setErrorToDate(true)
        setErrorFromDate(true)
      }
    } else if (selectRestaurantId && accessToken) {
      try {
        let obj = {};
        const required = {
          title: categoryInfo.title,
          imageUrl: activeImage,
          active: `${checked}`,
          activeTimeStart: 'null',
          activeTimeEnd: 'null'
        }
        if ((isValidDate(new Date(timeToValue)) && isValidDate(new Date(timeFromValue))) || categoryInfo.priority !== '') {
          if (isValidDate(new Date(timeToValue)) && isValidDate(new Date(timeFromValue))) {
            obj = { ...required, activeTimeStart: JSON.stringify(timeFromValue), activeTimeEnd: JSON.stringify(timeToValue) }
          }
          if (categoryInfo.priority !== '') {
            obj = { ...required, ...obj, priority: categoryInfo.priority }
          }
        } else {
          obj = { ...required }
        }
        const result = await axios.put(`https://api.vkusclick.ru/v1/restaurants/${selectRestaurantId}/categories/${qs.parse(location.search.substring(1)).id}`, {
          ...obj
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        if (result.status === 200) {
          navigate('/categories')
        }
      } catch (e) {

      }

    }
  }

  const [timeFromValue, setFromValue] = useState<any>();
  const [timeToValue, setToValue] = useState<any>();
  const [errorFromDate, setErrorFromDate] = useState<boolean>(false);
  const [errorToDate, setErrorToDate] = useState<boolean>(false);

  const [inputFromValue, setInputFromValue] = useState('');
  const [inputToValue, setInputToValue] = useState('');


  const changeInputTime = (value: any, inputValue: string, setInputValue: any) => {
    if(value.length < inputValue.length) {
      setInputValue(value);
    } else {
      if(value.length === 2 && inputValue[2] !== ':') {
        if(value[0] < 2 && value[1] < 10) {
          setInputValue(`${value}:`)
        } else if (value[0] === '2' && value[1] < 4) {
          setInputValue(`${value}:`)
        }
      } else if(value.length < 6 || value === '') {
        if(parseInt(value[0]) < 2 || value === '') {
          if(value[1] && value[1] !== ':') {
            if(parseInt(value[1]) < 10) {
              if(value[3]) {
                if(parseInt(value[3]) < 6) {
                  if(value[4]) {
                    if(parseInt(value[4]) < 10) {
                      setInputValue(value)
                    }
                  } else {
                    setInputValue(value)
                  }
                }
              } else {
                setInputValue(value)
              }
            }
          } else {
            setInputValue(value)
          }
        } else if(parseInt(value[0]) === 2) {
          if(value[1] && value[1] !== ':') {
            if(parseInt(value[1]) < 4) {
              if(value[3]) {
                if(parseInt(value[3]) < 6) {
                  if(value[4]) {
                    if(parseInt(value[4]) < 10) {
                      setInputValue(value)
                    }
                  } else {
                    setInputValue(value)
                  }
                }
              } else {
                setInputValue(value)
              }
            }
          } else {
            setInputValue(value)
          }
        }
      }
    }
  }


  const onFromInputBlur = () => {
    if (inputFromValue.length === 5) {
      const date = new Date();
      date.setHours(parseInt(`${inputFromValue[0]}${inputFromValue[1]}`))
      date.setMinutes(parseInt(`${inputFromValue[3]}${inputFromValue[4]}`))
      setFromValue(dayjs(date))
      setErrorFromDate(false)
    } else if (inputFromValue === '') {
      setErrorFromDate(false)
      setFromValue(null)
    } else {
      setErrorFromDate(true)
    }
  }

  const onToInputBlur = () => {
    if (inputToValue.length === 5) {
      const date = new Date();
      date.setHours(parseInt(`${inputToValue[0]}${inputToValue[1]}`))
      date.setMinutes(parseInt(`${inputToValue[3]}${inputToValue[4]}`))
      setToValue(dayjs(date))
      setErrorToDate(false)
    } else if (inputToValue === '') {
      setErrorToDate(false)
      setToValue(null)
    } else {
      setErrorToDate(true)
    }
  }

  return (
    <>
      <div className={styles.top}>
        {/* eslint-disable-next-line no-restricted-globals */}
        <button onClick={() => history.back()}>
          <Icon width={24} height={24} color={theme === 'dark' ? '#CFD3EC' : '#4B4B4B'} icon="tabler:arrow-narrow-left" />
        </button>
        <PageTitle title={'Редактировать категорию'} />
      </div>
      <div className={styles.content} style={{ backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 4px 18px rgba(75, 70, 92, 0.1)' }}>
        <div className={styles.contentWrap}>
          <div className={styles.slider}>
            <p className={styles.sliderTitle}>Изображение</p>

            <div className={styles.sliderWrap} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }}>
              <CategorieImageSlider activeImage={activeImage} setActiveImage={setActiveImage} activeSlide={activeSlide} setActiveSlide={setActiveSlide} />
            </div>
          </div>
          <div className={styles.info}>
            <div className={styles.inputWrap}>
              <p className={styles.inputTitle}>Название<span>*</span></p>
              <Input changeFunc={(text: string) => setCategoryInfo({ ...categoryInfo, title: text })} value={categoryInfo.title} placeholder={'Название'} type={'text'} errorFunc={setTitleError} error={titleError} />
            </div>
            <div className={styles.infoBottom}>
              <div className={styles.inputWrap}>
                <div className="title-flex mini">
                  <p className={styles.inputTitle}>Приоритет</p>
                  <button className={theme} style={{zIndex: 9}}>
                    <span style={{ boxShadow: theme === 'light' ? `0px 2px 4px rgba(165, 163, 174, 0.3)` : '0px 2px 4px rgba(15, 20, 34, 0.4)' }}><p>Чем выше значение приоритета, тем первее отображается элемент </p></span>
                    <Icon color={theme === 'light' ? '#4B465C66' : '#8692D066'} icon="bi:info-circle" />
                  </button>
                </div>
                <Input changeFunc={(text: string) => setCategoryInfo({ ...categoryInfo, priority: text.replace(/\D/, '') })} value={categoryInfo.priority} placeholder={'Приоритет'} type={'text'} errorFunc={setPriorityError} error={priorityError} />
              </div>
              <div className={styles.inputWrap}>
                <p className={styles.inputTitle}>Активность</p>
                <div className={styles.flex}>
                  <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} defaultChecked={checked} onChange={(e) => {
                      setCheck(e.target.checked);
                    }
                    } />}
                    label=""
                  />
                  <p>{checked ? 'Вкл' : 'Выкл'}</p>
                </div>
              </div>
            </div>
            <div className={`${styles.dates} ${theme} date-picker`}>
              <div className={`${styles.inputWrap} ${errorFromDate ? 'error' : ''}`} >
                <p className={styles.inputTitle}>Время активности от</p>
                <input style={{ background: 'transparent', borderColor: errorFromDate ? 'red' : theme === 'light' ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)', borderRadius: '6px', maxWidth: '140px' }} placeholder={'00:00'} onBlur={onFromInputBlur} value={inputFromValue} onChange={(e) => changeInputTime(e.target.value, inputFromValue, setInputFromValue)} />
              </div>
              <div className={`${styles.inputWrap} ${errorToDate ? 'error' : ''}`} style={{ marginLeft: '20px' }}>
                <p className={styles.inputTitle}>Время активности до</p>
                <input style={{ background: 'transparent', borderColor: errorToDate ? 'red' : theme === 'light' ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)', borderRadius: '6px', maxWidth: '140px' }} placeholder={'00:00'} onBlur={onToInputBlur} value={inputToValue} onChange={(e) => changeInputTime(e.target.value, inputToValue, setInputToValue)} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.btns}>
          <div className={styles.rightSide}>
            <button onClick={() => navigate('/categories')} className={styles.close} style={{ borderColor: theme === 'light' ? '#A8AAAE' : '#A8AAAE', color: theme === 'light' ? '#A8AAAE' : '#A8AAAE' }}>Отменить</button>
            { user?.role === 'demo' ? (
              <button title='Недоступно в демонстрационном заведении' className={styles.add} style={{backgroundColor: "#ff9f43", cursor: 'not-allowed'}}>Сохранить</button>
            ) : (
              <button className={styles.add} onClick={sendCategory} style={{ backgroundColor: "#ff9f43" }}>Сохранить</button>
            )}
          </div>
        </div>
      </div>
    </>

  );
}

export default Index;
