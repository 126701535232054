import React from 'react';
import PageTitle from "../../Components/PageTitle";
import OrdersListContent from "../../Components/OrdersListContent";

function Index() {
  return (
    <div className={'orders-list-page'}>
      <PageTitle title={'Заказы'}/>
      <OrdersListContent/>
    </div>
  );
}

export default Index;
