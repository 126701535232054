import React, {useEffect, useState} from 'react';
import styles from './CalculatorContent.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import PageTitle from "../PageTitle";
import {Icon} from "@iconify/react";
import {Typography, useTheme} from "@mui/material";
import CalculatorBlock from "../CalculatorBlock";
import {setServiceModalActive, setSubscriptions} from "../../redux/MainSlice";
import ServiceModal from "../ServiceModal";
import BalanceModal from "../BalanceModal";
import ErrorModal from "../ErrorModal";
import axios from "axios";
import PromoModal from "../PromoModal";


function Index() {

  const {subscriptions, serviceModalActive} = useSelector((state: RootState) => state.MainSlice);
  const {user, company, accessToken} = useSelector((state: RootState) => state.UserSlice);

  const [modal, setModal] = useState(false);
  const [promocodeModal, setPromocodeModal] = useState(false);
  const [error, setError] = useState(false);

  const MuiTheme = useTheme();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [totalPrice, setTotalPrice] = useState(0);
  const [nowPrice, setNowPrice] = useState(0);
  const [activeModule, setActiveModule] = useState('')
  const [activeTariff, setActiveTariff] = useState('')
  const [baseId, setBaseId] = useState('');

  useEffect(() => {
    const getSubs = async () => {
      setLoading(true);
      try {
        const result = await axios.get(`https://api.vkusclick.ru/v1/subscription_modules`)
        dispatch(setSubscriptions([...result.data.reverse()]))
        setLoading(false);
      } catch (e) {

      }
    }
    getSubs()
  }, [])

  useEffect(() => {
    if(subscriptions) {
      const price2 = subscriptions.find((item: any) => item._id === activeModule)?.priceDay
      const price1 = subscriptions.find((item: any) => item._id === activeTariff)?.priceDay
      if(price1) {
        if(price2) {
          setTotalPrice(price1 + price2)
        } else {
          setTotalPrice(price1)
        }
      }
    }
  }, [activeModule, activeTariff, subscriptions])

  const [basePrice, setBasePrice] = useState(0);

  useEffect(() => {
    if(company && subscriptions) {
      company.subscriptionModulesId.map((module: any) => {
        subscriptions.map(item => {
          if(item._id === module) {
            setNowPrice(basePrice + item.priceDay);
          }
          if(item.type === 'tariff') {
            if(company.subscriptionModulesId.length === 1) {
              setNowPrice(item.priceDay);
            }
          }
        })
      })
      if(company.subscriptionModulesId.length === 1) {
        setNowPrice(basePrice);
      }
    }
  }, [company, subscriptions, basePrice])

  const sendModules = async () => {
    const obj = {
      subscriptionModulesId: [
        baseId,
      ]
    };
    if(activeModule !== '') {
      obj.subscriptionModulesId = [
        activeTariff,
        activeModule
      ]
    }
    try {
      const result = await axios.put(`https://api.vkusclick.ru/v1/companies/${company._id}`, {
        ...obj
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      if(result.status === 200) {
        window.location.reload();
      }
      dispatch(setServiceModalActive(false))
    } catch (e: any) {
      if(e.response.status === 429) {
        dispatch(setServiceModalActive(false))
        setError(true)
      }
    }
  }

  return (
    <>
      {
        promocodeModal && <PromoModal balance={totalPrice} setActive={setPromocodeModal} nextActive={setError}/>
      }
      {
        serviceModalActive && <ServiceModal sendModules={sendModules} setActive={(active: boolean) => dispatch(setServiceModalActive(active))}/>
      }
      {
        modal && <BalanceModal balance={totalPrice} setActive={setModal} nextActive={setError} activeModule={activeModule}/>
      }
      {
        error && <ErrorModal setActive={setError}/>
      }
      <div className={styles.top}>
        <PageTitle title={'Калькулятор подписки'}/>
        <div className={styles.buttons}>     
          <button onClick={() => setPromocodeModal(true)} className={styles.topBtn} style={{backgroundColor: "#ff9f43", color: '#fff'}}>
            Активировать промокод
          </button>
          <button onClick={() => setModal(true)} className={styles.topBtn} style={{backgroundColor: "#ff9f43", color: '#fff'}}>
            <Icon color={'#fff'} icon={'tabler:plus'}/>
            Пополнить баланс
          </button>
        </div>
      </div>
      {
        company?.blocked &&
        <p className={styles.blocked_error}>Ваша организация и все заведения заблокированы до пополнения баланса</p>
      }
      <p className={styles.moduleTitle}>Тарифы</p>
      <div className={styles.content}>
        {
          subscriptions.map((item) => {
            if(item.type === 'tariff') {
              return (
                <CalculatorBlock key={item._id} activeModule={activeTariff} setActiveModule={setActiveTariff} basePrice={basePrice} item={item} totalPrice={totalPrice} changeTotalPrice={setTotalPrice}/>
              )
            }
          })
        }
      </div>
      <p className={styles.moduleTitle}>Модули расширения</p>
      <div className={styles.content}>
        {
          subscriptions.map((item) => {
            if(item.type === 'single') {
              return (
                <CalculatorBlock basePrice={basePrice} setActiveModule={setActiveModule} activeModule={activeModule} item={item} little={true} totalPrice={totalPrice} changeTotalPrice={setTotalPrice}/>
              )
            }
          })
        }
      </div>
      <div className={styles.bottom}>
        <div className={styles.prices}>
          <p className={styles.price}><span>Текущий расход: </span><span className={styles.priceOpacity}>{nowPrice.toFixed(2)} руб./день</span><span> или </span><span className={styles.priceOpacity}>{(nowPrice * 30).toFixed(2)} руб./мес</span></p>
          <p className={styles.price}><span>Прогноз расхода: </span><span className={styles.priceOpacity}>{totalPrice.toFixed(2)} руб./день</span><span> или </span><span className={styles.priceOpacity}>{(totalPrice * 30).toFixed(2)} руб./мес</span></p>
        </div>
        { user?.role === 'demo' ? (
          <button title='Недоступно в демонстрационном заведении' style={{cursor: 'not-allowed'}}>Применить</button>
        ) : (
          <button onClick={() => dispatch(setServiceModalActive(true))}>Применить</button>
        )}    
      </div>
    </>
  );
}

export default Index;
