import {useEffect, useState} from 'react';
import OrderContent from "../../Components/OrderContent";
import PageTitle from "../../Components/PageTitle";
import {useLocation} from "react-router-dom";
import qs from 'qs';
import styles from "../../Components/AddCategorieContent/AddCategorieContent.module.scss";
import {Icon} from "@iconify/react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {formatNumber} from "../../Components/OrdersListContent";
import {getOrders} from "../../Hooks/Hooks";
import {setOrders} from "../../redux/MainSlice";

function Index() {

  const location = useLocation();
  const [item, setItem] = useState<any>();

  const {theme, orders, selectRestaurantId} = useSelector((state: RootState) => state.MainSlice);
  const {accessToken} = useSelector((state: RootState) => state.UserSlice);

  const dispatch = useDispatch();

  useEffect(() => {
    if(orders.length > 0) {
      setItem(orders.filter(item => item._id === qs.parse(location.search.substring(1)).id)[0]);
    } else if(accessToken && selectRestaurantId) {
      getOrders({id: selectRestaurantId, accessToken: accessToken}).then(response => {
        dispatch(setOrders(response?.data))
      })
    }
  }, [location, orders, accessToken, selectRestaurantId, location])


  return (
    <div className={'order-page'}>
      <div className={styles.top}>
        {/* eslint-disable-next-line no-restricted-globals */}
        <button onClick={() => history.back()}>
          <Icon width={24} height={24} color={theme === 'dark' ? '#CFD3EC' : '#4B4B4B'} icon="tabler:arrow-narrow-left" />
        </button>
        {
          item ?
            <PageTitle title={'Заказ #' + formatNumber(item.orderNumber)}/>
            : null
        }
      </div>
      <OrderContent/>
    </div>
  );
}

export default Index;
