import React from 'react';
import AddCategorieContent from "../../Components/AddCategorieContent";

function Index() {
  return (
    <div className={"categories-add-page"}>
      <AddCategorieContent/>
    </div>
  );
}

export default Index;
