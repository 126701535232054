import React from 'react';
import styles from './ServiceBlock.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {Typography} from "@mui/material";
import {setServiceModalActive} from "../../redux/MainSlice";

function Index({item}: {item: any}) {

  const {theme} = useSelector((state: RootState) => state.MainSlice);

  const dispatch = useDispatch();

  return (
    <div className={`${styles.block}`} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 4px 18px rgba(75, 70, 92, 0.1)'}}>
      <img src={item.img} alt=""/>
      <div className={styles.content}>
        <p className={styles.title}>{item.title}</p>
        <p className={styles.text}>{item.text}</p>
        <div className={styles.bottom}>
          <button onClick={() => dispatch(setServiceModalActive(true))}>Заказать</button>
          <p className={styles.price}>{item.price} руб.</p>
        </div>
      </div>
    </div>
  );
}

export default Index;
