import React from 'react';
import styles from './HelpContent.module.scss';
import HelpAccordion from "../HelpAccordion";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {Typography} from "@mui/material";
import {Icon} from "@iconify/react";
import politics from '../../assets/documents/Politics.pdf'
import oferta from '../../assets/documents/Oferta.pdf'
import acception from '../../assets/documents/Acception.pdf'

function Index() {

  const {theme} = useSelector((state: RootState) => state.MainSlice);

  return (
    <div className={styles.content} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 4px 18px rgba(75, 70, 92, 0.1)'}}>
      <div className={styles.sides}>
        <div className={styles.side}>
          <p className={styles.title}>Часто задаваемые вопросы</p>
          <HelpAccordion/>
        </div>
        <div className={styles.side}>
          <div className={styles.contacts}>
            <a href={'tel:+79886450050'} className={styles.block} style={{backgroundColor: theme === 'light' ? 'rgba(75, 70, 92, 0.04)' : 'rgba(134, 146, 208, 0.06)'}}>
              <div className={styles.iconWrap}>
                <Icon width={28} color={'#FF9F43'} icon={'tabler:phone'}/>
              </div>
              <p className={styles.blockText}>
                +7 (988) 645 00 50
              </p>
            </a>
            <a href={'mailto:support@vkusclick.ru'} className={styles.block} style={{backgroundColor: theme === 'light' ? 'rgba(75, 70, 92, 0.04)' : 'rgba(134, 146, 208, 0.06)'}}>
              <div className={styles.iconWrap}>
                <Icon width={28} color={'#FF9F43'} icon={'tabler:mail'}/>
              </div>
              <p className={styles.blockText}>
                support@vkusclick.ru
              </p>
            </a>
          </div>
          <p className={styles.title}>Контакты</p>
          <p className={styles.contactsText}>ИНН: 0530011784<br/>
            ОГРН: 1220500012105<br/>
            ООО "Хурики"<br/>
            Адрес: 368659, Россия, Дагестан, Махачкала, ул. Гагарина дом 54</p>
          <p className={styles.title}>Документы</p>
          <a href={politics} download className={styles.contactsLink}>Политика конфиденциальности</a>
          <a href={acception} download className={styles.contactsLink}>Пользовательское соглашение</a>
          <a href={oferta} download className={styles.contactsLink}>Публичная оферта</a>
        </div>
      </div>
    </div>
  );
}

export default Index;
