import React from 'react';
import {Icon} from "@iconify/react";
import {Typography, useTheme} from "@mui/material";
import styles from './ProductsListStatistic.module.scss';
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";

function Index() {

  const theme = useSelector((state: RootState) => state.MainSlice.theme);

  const MuiTheme = useTheme();

  const {products} = useSelector((state: RootState) => state.MainSlice);

  return (
    <div className={styles.content} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 4px 18px rgba(75, 70, 92, 0.1)'}}>
      <div className={styles.tableTop} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}}>
        <div className={styles.colLarge}>
          <p className={styles.title}>Название</p>
          <div className={styles.sortBtns}>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-up" />
            </button>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-down" />
            </button>
          </div>
        </div>
        <div className={styles.colLarge}>
          <p className={styles.title}>Цена</p>
          <div className={styles.sortBtns}>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-up" />
            </button>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-down" />
            </button>
          </div>
        </div>
        <div className={styles.colLarge}>
          <p className={styles.title}>Просмотры</p>
          <div className={styles.sortBtns}>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-up" />
            </button>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-down" />
            </button>
          </div>
        </div>
        <div className={styles.colLarge}>
          <p className={styles.title}>Статус</p>
          <div className={styles.sortBtns}>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-up" />
            </button>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-down" />
            </button>
          </div>
        </div>
        <div className={styles.colLarge}>
          <p className={styles.title}>Последнее изменение</p>
          <div className={styles.sortBtns}>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-up" />
            </button>
            <button>
              <Icon color={'#5C5F66'} icon="tabler:chevron-down" />
            </button>
          </div>
        </div>
      </div>
      <div className={styles.tableContent}>
        {/*{products?.map(item => {*/}
        {/*  return (*/}
        {/*    <div style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}} key={item.id} className={styles.categoriesItem}>*/}
        {/*      <div className={styles.colLarge}>*/}
        {/*        <div className={styles.imgWrap}>*/}
        {/*          {item.imgUrl !== null ? <img src={item.imgUrl} alt={''}/> : <span className={styles.noImage} style={{backgroundColor: "#ff9f43"}}>НБ</span>}*/}
        {/*        </div>*/}
        {/*        <p className={styles.itemTitle}>{item.title}</p>*/}
        {/*      </div>*/}
        {/*      <div className={styles.colLarge}>*/}
        {/*        <p className={styles.date}>{item.price}</p>*/}
        {/*      </div>*/}
        {/*      <div className={styles.colLarge}>*/}
        {/*        <p className={styles.date}>{item.count}</p>*/}
        {/*      </div>*/}
        {/*      <div className={styles.colLarge}>*/}
        {/*        <p className={`${styles.status} ${item.status === 'активно' ? styles.active : styles.inactive}`}>{item.status}</p>*/}
        {/*      </div>*/}

        {/*      <div className={styles.colLarge}>*/}
        {/*        <p className={styles.date}>{item.changeDate}</p>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  )*/}
        {/*})}*/}
      </div>
    </div>
  );
}

export default Index;
