import React from 'react';
import CategoryEdit from "../../Components/CategoryEdit";

function Index() {
  return (
    <div className={'category-edit'}>
      <CategoryEdit/>
    </div>
  );
}

export default Index;
