import React from 'react';
import styles from './ServiceModal.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {Typography} from "@mui/material";
import {Icon} from "@iconify/react";
import {setServiceModalActive} from "../../redux/MainSlice";

function Index({setActive, sendModules}: {setActive: any, sendModules: any}) {

  const {theme} = useSelector((state: RootState) => state.MainSlice);

  const dispatch = useDispatch();

  return (
    <div className={styles.wrap}>
      <span className={styles.bg} onClick={() => dispatch(setServiceModalActive(false))}/>
      <div className={styles.content} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 4px 18px rgba(75, 70, 92, 0.1)'}}>
        <button onClick={() => dispatch(setServiceModalActive(false))} className={styles.close} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 2px 4px rgba(165, 163, 174, 0.3)' : '0px 4px 18px rgba(75, 70, 92, 0.1)'}}>
          <Icon width={20} icon={'tabler:x'} color={theme === 'light' ? '#afaeae' : '#7a7fb4'}/>
        </button>
        <p style={{textAlign: 'center'}} className={styles.title}>Вы уверены что хотите сохранить изменения?</p>
        <div className={styles.btns}>
          <div className={styles.rightSide}>
            <button onClick={() => setActive(false)} className={styles.closeBtn} style={{borderColor: theme === 'light' ? '#A8AAAE' : '#A8AAAE', color: theme === 'light' ? '#A8AAAE' : '#A8AAAE'}}>Отменить</button>
            <button className={styles.add} onClick={sendModules} style={{backgroundColor: '#FF9F43'}}>Сохранить</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
