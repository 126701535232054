import React, {useLayoutEffect, useState} from 'react';
import Menu from "./Menu";
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../redux/store";

function Index() {

  const location = useLocation();

  const [visible, setVisible] = useState(true);

  const {layoutVisible} = useSelector((state: RootState) => state.MainSlice)

  useLayoutEffect(() => {
    const path = location.pathname;
    if(!(path !== '/reset-password' && path !== '/registration' && path !== '/sign-in' && path !== '/add-restaurant') || !layoutVisible) {
      setVisible(false);}
    else {
      setVisible(true);
    }
  }, [location, layoutVisible])

  return (
    <div className={'layout'}>
      {
        visible &&
          <>
            <Menu/>
          </>
      }

    </div>
  );
}

export default Index;
