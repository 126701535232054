import React from 'react';
import PageTitle from "../../Components/PageTitle";
import OrdersStatisticContent from "../../Components/OrdersStatisticContent";

function Index() {
  return (
    <div className={'orders-statistic-page'}>
      <PageTitle title={'Статистика по заказам'}/>
      <OrdersStatisticContent/>

    </div>
  );
}

export default Index;
