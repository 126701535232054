import html2canvas from "html2canvas";
import downloadjs from 'downloadjs';

const exportAsImage = async (element: any, imageFileName: any, fileExt: string) => {
  const canvas = await html2canvas(element, {scale: 4, backgroundColor: 'transparent'});
  const dataURL = canvas.toDataURL('image/' + fileExt);
  downloadjs(dataURL, `${imageFileName}.${fileExt}`, `image/${fileExt}`);
};

const downloadImage = (blob: any, fileName: any) => {
  const fakeLink: HTMLAnchorElement = window.document.createElement("a");
  fakeLink.setAttribute('style', "display:none;");
  fakeLink.download = fileName;

  fakeLink.href = blob;

  document.body.appendChild(fakeLink);
  fakeLink.click();
  document.body.removeChild(fakeLink);

  fakeLink.remove();
};

export default exportAsImage;
