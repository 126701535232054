import React, { useEffect, useState } from 'react';
import styles from './SettingsPlaceContent.module.scss';
import { FormControlLabel, IconButton, Switch, SwitchProps, Typography, styled } from "@mui/material";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import SettingsPlaceSelect from "../SettingsPlaceSelect";
import PopularProductItem from "../PopularProductItem";
import axios from "axios";
import Input from "../SignIn/Input";
import CategorieImageSlider from "../CategorieImageSlider";
import { getCategories, getProducts } from "../../Hooks/Hooks";
import { setCategories, setProducts } from "../../redux/MainSlice";
import PageTitle from "../PageTitle";
import SettingsImageSlider from "../SettingsImageSlider";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 30,
  height: 18,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: '3px 3px 0 3px',
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#FF9F43',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 12,
    height: 12,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

function Index() {

  const { theme, selectRestaurantId } = useSelector((state: RootState) => state.MainSlice);
  const { company, user, accessToken } = useSelector((state: RootState) => state.UserSlice);

  const [selectedItems, setSelected] = useState<{
    _id: string,
    title: string,
    priority: number,
    active: true,
    image: string,
    updatedAt: string
  }[]>([]
  );
  const [unselctedItems, setUnselected] = useState<{
    _id: string,
    title: string,
    priority: number,
    active: true,
    image: string,
    updatedAt: string
  }[]>([]);


  const [settings, changeSettings] = useState({
    title: '',
    internalTitle: '',
    address: '',
    description: '',
    defaultTheme: '',
    accentColorHEX: '',
    mainPageMenuImage: '',
    mainPageMenuCategoriesId: [],
    wiFiSSID: '',
    wiFiPassword: ''
  })

  const [titleError, setTitleError] = useState({
    active: false,
    text: ''
  })

  const [addressError, setAddressError] = useState({
    active: false,
    text: ''
  })
  const dispatch = useDispatch();


  const [activeSlide, setActiveSlide] = useState<number | null>(null);
  const [activeImage, setActiveImage] = useState('')

  const [checked, setCheck] = useState(false);

  useEffect(() => {
    if (accessToken && selectRestaurantId) {
      try {
        axios.get(`https://api.vkusclick.ru/v1/restaurants/${selectRestaurantId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }).then(response => {
          changeSettings({ ...response.data });
          if (response?.data.mainPageMenuImage) {
            if (response?.data.mainPageMenuImage) {
              setActiveImage(response?.data.mainPageMenuImage)
            }
          }
          setCheck(response?.data.isShowcaseMode)
          getProducts({ id: selectRestaurantId }).then(products => {
            dispatch(setProducts([products.type === 'error' ? [] : products.products]))
            getCategories({ accessToken: accessToken, id: selectRestaurantId }).then(responseCat => {
              dispatch(setCategories(responseCat.categories.sort((a: any, b: any) => {
                return a - b;
              }).reverse()))
              if (response.data.mainPageMenuCategoriesId) {
                setSelected([...responseCat.categories.filter((item: any) => response.data.mainPageMenuCategoriesId.includes(item._id))])
                // @ts-ignore
                setUnselected([...responseCat.categories.filter(item => !response.data.mainPageMenuCategoriesId.includes(item._id)).filter(item => products.products.map(id => id.categoryId).includes(item._id))])
              } else {
                setUnselected([...responseCat.categories.sort((a: any, b: any) => {
                  return a - b;
                }).reverse().filter((item: any) => products.products.map((id: any) => id.categoryId).includes(item._id))])
              }
            })
          })
        })
      } catch (e) {

      }

    }
  }, [selectRestaurantId, accessToken])

  const sendSettings = async () => {
    const mas = selectedItems.map(item => item._id);
    const obj = {
      ...settings, mainPageMenuImage: activeImage, mainPageMenuCategoriesId: [...mas]
    };
    // @ts-ignore
    delete obj.updatedAt
    // @ts-ignore
    delete obj._id
    // @ts-ignore
    delete obj.code
    // @ts-ignore
    Object.entries(obj).map(a => Object.entries(a[1] || {}).filter(b => b[1].length).length ? a : delete obj[a[0]]);
    if (settings.title.trim() === '' || settings.address.trim() === '') {
      if (settings.title.trim() === '') {
        setTitleError({
          active: true,
          text: 'Поле не заполнено'
        })
      }
      if (settings.address.trim() === '') {
        setAddressError({
          active: true,
          text: 'Поле не зполнено'
        })
      }
    } else {
      await axios.put(`https://api.vkusclick.ru/v1/restaurants/${selectRestaurantId}`, {
        ...obj,
        isShowcaseMode: checked
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }).then(() => {
        window.location.reload()
      })
    }
  }

  const [selected, select] = useState([])
  const [unselected, unselect] = useState([])
  const [companyTariff, setCompanyTariff] = useState('start_tariff'); 

  useEffect(() => {
    if(company) {
      company.subscriptionModulesId.map((moduleId: any) => {
        if(moduleId === '653ade5c8f5d7381aba0ee28') {
          setCompanyTariff('start_tariff');
        }
        if(moduleId === '653ade5c8f5d7381aba0ee29') {
          setCompanyTariff('optimal_tariff');
        }
        if(moduleId === '644b1634d059fa38049e6b55') {
          setCompanyTariff('advanced_tariff');
        }
      })  
    }
  }, [company])

  const sendAll = () => {
    if (unselctedItems.length > 0) {
      setSelected([...selectedItems, ...unselctedItems])
      // @ts-ignore
      setUnselected([])
      select([])
      unselect([])
    }
  }

  const removeAll = () => {
    if (selectedItems.length > 0) {
      // @ts-ignore
      setSelected([])
      // @ts-ignore
      setUnselected([...unselctedItems, ...selectedItems])
      select([])
      unselect([])
    }
  }

  const sendOne = () => {
    if (unselctedItems.length > 0 && selected.length > 0) {
      // @ts-ignore
      setSelected([...selectedItems, ...unselctedItems.filter(item => selected.includes(item._id))])
      // @ts-ignore
      setUnselected([...unselctedItems.filter(item => !selected.includes(item._id))])
      select([])
      unselect([])
    }
  }

  const removeOne = () => {
    if (selectedItems.length > 0 && unselected.length > 0) {
      // @ts-ignore
      setSelected([...selectedItems.filter(item => !unselected.includes(item._id))])
      // @ts-ignore
      setUnselected([...unselctedItems, ...selectedItems.filter(item => unselected.includes(item._id))])
      select([])
      unselect([])
    }
  }

  const [selectSearch, changeSelectSearch] = useState('');
  const [unselectSearch, changeUnselectSearch] = useState('');

  return (
    <div className={styles.content} style={{ backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 4px 18px rgba(75, 70, 92, 0.1)' }}>
      <div className={styles.sides}>
        <div className={styles.side}>
          <div className={styles.inputWrap}>
            <div className={styles.blockTop}>
              <p>Название заведения<span>*</span></p>
            </div>
            <Input changeFunc={(text: string) => changeSettings({ ...settings, title: text })} value={settings.title} placeholder={'Название'} type={'text'} errorFunc={setTitleError} error={titleError} />
          </div>
          <div className={styles.inputWrap}>
            <div className={styles.blockTop}>
              <div className="title-flex mini">
                <p>Внутреннее название заведения</p>
                <button className={theme}>
                  <span style={{ boxShadow: theme === 'light' ? `0px 2px 4px rgba(165, 163, 174, 0.3)` : '0px 2px 4px rgba(15, 20, 34, 0.4)' }}><p>Произвольное названия для собственного удобства. Не показывается гостям</p></span>
                  <Icon color={theme === 'light' ? '#4B465C66' : '#8692D066'} icon="bi:info-circle" />
                </button>
              </div>
            </div>
            <input onChange={(e) => changeSettings({ ...settings, internalTitle: e.target.value })} value={settings.internalTitle} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }} type="text" className={styles.input} placeholder={'Название'} />
          </div>
          <div className={styles.inputWrap}>
            <div className={styles.blockTop}>
              <p>Адрес<span>*</span></p>
            </div>
            <Input changeFunc={(text: string) => changeSettings({ ...settings, address: text })} value={settings.address} placeholder={'Адрес'} type={'text'} errorFunc={setAddressError} error={addressError} />
          </div>
          <div className={styles.inputWrap}>
            <div className={styles.blockTop}>
              <p>Описание</p>
            </div>
            <textarea onChange={(e) => changeSettings({ ...settings, description: e.target.value })} value={settings.description} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }} className={styles.input} placeholder={'Описание'} />
          </div>
        </div>
        <div className={styles.side}>
          <div className={styles.inputWrap}>
            <div className={styles.blockTop}>
              <p>Изображение для кнопки меню</p>
            </div>
            <div className={styles.sliderWrap} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }}>
              <SettingsImageSlider activeImage={activeImage} setActiveImage={setActiveImage} activeSlide={activeSlide} setActiveSlide={setActiveSlide} />
            </div>
          </div>
          <div className={styles.inputFlex}>
            <div className={styles.inputWrap}>
              <div className={styles.blockTop}>
                <p>Выбор темы меню по умолчанию</p>
              </div>
              <SettingsPlaceSelect changeFunc={(theme: string) => changeSettings({ ...settings, defaultTheme: theme })} value={settings.defaultTheme} />
            </div>
            <div className={styles.inputWrap}>
              <div className={styles.blockTop}>
                <p>Режим витрины</p>
              </div>
              <div className={styles.check}>
                { companyTariff === 'start_tariff' ? (
                  <>
                    <FormControlLabel
                      control={<IOSSwitch sx={{ m: 1, marginLeft: '13px' }} disabled={true} checked={true} />}
                      label=""
                    />
                    <p>Активно</p>
                  </>
                ) : (
                  <>
                    <FormControlLabel
                      control={<IOSSwitch sx={{ m: 1, marginLeft: '13px' }} checked={checked} onChange={(e) => {
                        setCheck(e.target.checked);
                      }
                      } />}
                      label=""
                    />
                    <p>{checked ? 'Активно' : 'Неактивно'}</p>
                  </>
                )
                }
                </div>
            </div>
          </div>

          <div className={styles.inputWrap}>
            <div className={styles.blockTop}>
              <p>Название сети WiFi</p>
            </div>
            <input onChange={(e) => changeSettings({ ...settings, wiFiSSID: e.target.value })} value={settings.wiFiSSID} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }} type="text" className={styles.input} placeholder={'Название'} />
          </div>
          <div className={styles.inputWrap}>
            <div className={styles.blockTop}>
              <p>Пароль сети WiFi</p>
            </div>
            <input onChange={(e) => changeSettings({ ...settings, wiFiPassword: e.target.value })} value={settings.wiFiPassword} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }} type="text" className={styles.input} placeholder={'Пароль'} />
          </div>
        </div>
      </div>
      <p className={styles.checkTitle}>
        <div className="title-flex">
          Категории на главной странице меню
          <button className={theme}>
            <span style={{ boxShadow: theme === 'light' ? `0px 2px 4px rgba(165, 163, 174, 0.3)` : '0px 2px 4px rgba(15, 20, 34, 0.4)' }}><p>Можно выбрать до двух категорий, которые отображаются на главной странице меню</p></span>
            <Icon color={theme === 'light' ? '#4B465C66' : '#8692D066'} icon="bi:info-circle" />
          </button>
        </div>
      </p>
      <div className={styles.wrap}>
        <div className={styles.sideCol}>
          <p className={styles.sideTitle}>Доступные категории</p>
          <div className={styles.sideContent} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }}>
            <div className={styles.contentTop} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }}>
              <input value={selectSearch} onChange={(e) => changeSelectSearch(e.target.value)} type="text" className={styles.topInput} placeholder={'Поиск'} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }} />
              <button onClick={sendOne} className={styles.swapOne} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }}>
                <Icon color={theme === 'light' ? '#4B465C' : '#CFD3EC'} icon={'tabler:chevron-right'} />
              </button>
              <button onClick={sendAll} className={styles.swapAll} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }}>
                <Icon color={theme === 'light' ? '#4B465C' : '#CFD3EC'} icon={'tabler:chevrons-right'} />
              </button>
            </div>
            <div className={styles.sideWrap}>
              {
                unselctedItems?.filter(item => item.title.toLowerCase().includes(selectSearch.toLowerCase())).map((item, i) => <PopularProductItem select={select} selectedProducts={selected} id={item._id} img={item.image.substring(24, item.image.length)} title={item.title} />)
              }
            </div>
          </div>
        </div>
        <div className={styles.sideCol}>
          <p className={styles.sideTitle}>Выбранные категории</p>
          <div className={styles.sideContent} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }}>
            <div className={styles.contentTop} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }}>
              <button onClick={removeAll} className={styles.swapAll} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }}>
                <Icon color={theme === 'light' ? '#4B465C' : '#CFD3EC'} icon={'tabler:chevrons-left'} />
              </button>
              <button onClick={removeOne} className={styles.swapOne} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }}>
                <Icon color={theme === 'light' ? '#4B465C' : '#CFD3EC'} icon={'tabler:chevron-left'} />
              </button>
              <input value={unselectSearch} onChange={(e) => changeUnselectSearch(e.target.value)} type="text" className={styles.topInput} placeholder={'Поиск'} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }} />
            </div>
            <div className={styles.sideWrap}>
              {
                selectedItems?.filter(item => item.title.toLowerCase().includes(unselectSearch.toLowerCase())).map((item, i) => <PopularProductItem select={unselect} selectedProducts={unselected} id={item._id} img={item.image.substring(24, item.image.length)} title={item.title} />)
              }
            </div>
          </div>
        </div>
      </div>
      { user?.role === 'demo' ? (
        <button title='Недоступно в демонстрационном заведении' className={styles.button} style={{cursor: 'not-allowed'}}>Сохранить</button>            
      ) : (
        <button className={styles.button} onClick={sendSettings}>Сохранить</button>
      )}
    </div>
  );
}

export default Index;
