import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import Select from '@mui/material/Select'
import MuiFormControl, { FormControlProps } from '@mui/material/FormControl'
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const SelectCustomized = ({func, value}: {func: any, value: any}) => {

  const localTheme = useSelector((state: RootState) => state.MainSlice.theme);

  const FormControl = styled(MuiFormControl)<FormControlProps>(({ theme }) => ({
    '& > .MuiInputBase-root': {
      '&.MuiInput-root:before, &.MuiInput-root:after': {
        border: 0
      }
    },
    '& .MuiInputBase-input': {
      fontFamily: '"Public Sans", sans-serif',
      fontSize: 13,
      borderRadius: 6,
      position: 'relative',
      color: localTheme === 'dark' ? '#CFD3EC' : '#A5A3AE',
      padding: '7px 34px 7px 10px',
      backgroundColor: 'transparent',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      border: localTheme === 'light' ? '1px solid #DBDADE' : `1px solid #434968`,
      fontStyle: 'normal',
      textTransform: 'uppercase',
      '&:focus-within': {
        borderRadius: 6,
        borderColor: theme.palette.warning.light,
      }
    },
  }))

  return (
    <div className='select'>
      <FormControl fullWidth sx={{width: '240px', marginTop: 0}}>
        <Select
          value={value}
          input={<InputBase />}
          onChange={func}
          id='demo-customized-select'
          labelId='demo-customized-select-label'
          sx={{color: localTheme === 'light' ? '' : ''}}
          IconComponent={(props) => <KeyboardArrowDownIcon sx={{fill: localTheme === 'light' ? '#6F6B7D' : '#CFD3EC', width: '16px'}} {...props}/>}
        >
          <MenuItem value={'Png'}>
            Png
          </MenuItem>
          <MenuItem value={'Webp'}>Webp</MenuItem>
          <MenuItem value={'Jpeg'}>Jpeg</MenuItem>
        </Select>
      </FormControl>
    </div>
  )
}

export default SelectCustomized
