import React from 'react';
import styles from '../ServiceModal/ServiceModal.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {Typography} from "@mui/material";
import {Icon} from "@iconify/react";

function Index({setActive, text, removeFc}: {setActive: any, text: string, removeFc?: any}) {

  const { theme } = useSelector((state: RootState) => state.MainSlice);
  const { user } = useSelector((state: RootState) => state.UserSlice);

  const dispatch = useDispatch();

  return (
    <div className={styles.wrap}>
      <span className={styles.bg} onClick={() => setActive(false)}/>
      <div className={styles.content} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 4px 18px rgba(75, 70, 92, 0.1)', padding: '32px 40px'}}>
        <button onClick={() => setActive(false)} className={styles.close} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 2px 4px rgba(165, 163, 174, 0.3)' : '0px 4px 18px rgba(75, 70, 92, 0.1)'}}>
          <Icon width={20} icon={'tabler:x'} color={theme === 'light' ? '#afaeae' : '#7a7fb4'}/>
        </button>
        <p className={styles.title} style={{textAlign: 'center'}}>Вы уверены что хотите удалить {text}?</p>
        <div className={styles.btns}>
          <div className={styles.rightSide}>
            <button onClick={() => setActive(false)} className={styles.closeBtn} style={{borderColor: theme === 'light' ? '#A8AAAE' : '#A8AAAE', color: theme === 'light' ? '#A8AAAE' : '#A8AAAE'}}>Отменить</button>
              { user?.role === 'demo' ? (
                <button title='Недоступно в демонстрационном заведении' className={styles.add} style={{backgroundColor: 'red', width: '115px', cursor: 'not-allowed'}}>Удалить</button>            
              ) : (
                <button className={styles.add}  style={{backgroundColor: 'red', width: '115px'}} onClick={() => {
                  removeFc()
                  setActive(false)
                }}>Удалить</button>
              )}
          
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
