import React from 'react';
import ResetPassword from "../../Components/ResetPassword";

function Index() {
  return (
    <div className={'reset-password'}>
      <ResetPassword/>
    </div>
  );
}

export default Index;
