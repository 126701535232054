import React, {useEffect, useLayoutEffect, useState} from 'react';
import styles from './WorkerEditContent.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {Typography, useTheme} from "@mui/material";
import WorkerAddSelect from "../WorkerAddSelect";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import qs from "qs";
import CheckItem from "./CheckItem";
import axios from "axios";
import {getWorkers} from "../../Hooks/Hooks";
import {setWorkers} from "../../redux/MainSlice";
import loader from '../../assets/img/loader.svg';
import PageTitle from "../PageTitle";
import {Icon} from "@iconify/react";
import generator from "generate-password-browser";
import Input from "../SignIn/Input";
import {setUser} from "../../redux/UserSlice";
import DeleteModal from '../DeleteModal/Index';

function Index() {

  const {theme, selectRestaurantId, workers} = useSelector((state: RootState) => state.MainSlice);
  const {accessToken, user, restaurants} = useSelector((state: RootState) => state.UserSlice);

  const MuiTheme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();

  const [work, setWork] = useState<any>('');
  const [modal, setModal] = useState(false);
  const [workerInfo, setWorkerInfo] = useState<{
    _id: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    password: string,
    role: string,
  }>({
    _id: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    role: '',
  })

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const currentWorker = workers?.filter(item => item['_id'] === qs.parse(location.search.substring(1)).id as string)[0]
    if(currentWorker) {
      setLoading(false);
      setWorkerInfo({
        _id: currentWorker._id,
        firstName: currentWorker.firstName,
        lastName: currentWorker.lastName,
        email: currentWorker.email,
        password: '',
        phone: user?._id === currentWorker._id ? user?.phone : '',
        role: currentWorker.role
      })
    } else if(accessToken && selectRestaurantId){
      setLoading(true)
      getWorkers({accessToken: accessToken, id: selectRestaurantId}).then(response => {
        setLoading(false);
        dispatch(setWorkers(response.workers))
      })
    }
  }, [location, workers, selectRestaurantId])
  const [value, setValue] = useState<string>('officiant')

  useEffect(() => {
    setWork(workers?.filter(item => item['_id'] === qs.parse(location.search.substring(1)).id as string)[0].role);
  }, [location, workers])

  const [firstNameError, setFirstNameError] = useState({
    active: false,
    text: ''
  })

  const [emailError, setEmailError] = useState({
    active: false,
    text: ''
  })

  const [phoneError, setPhoneError] = useState({
    active: false,
    text: ''
  })


  const [passwordError, setPasswordError] = useState({
    active: false,
    text: ''
  })


  const navigate = useNavigate();

  const editUser = async () => {
    if(workerInfo.firstName.trim() === '' || workerInfo.email.trim() === '')
    {
      if(workerInfo.firstName.trim() === '') {
        setFirstNameError({
          active: true,
          text: 'Поле не заполнено'
        })
      }
      if(workerInfo.email.trim() === '') {
        setEmailError({
          active: true,
          text: 'Поле не заполнено'
        })
      }
    } else {
      try {
        let obj = {};
        const required = {
          firstName: workerInfo.firstName,
          email: workerInfo.email,
        }
        if(workerInfo.lastName !== '' || workerInfo.phone !== '' || workerInfo.password !== '')
        {
          if(workerInfo.lastName !== '') {
            obj = {...required, ...obj, lastName: workerInfo.lastName}
          }
          if(workerInfo.phone !== '' && workerInfo.phone) {
            obj = {...required, ...obj, phone: workerInfo.phone}
          }
          if(workerInfo.password !== '') {
            obj = {...required, ...obj, password: workerInfo.password}
          }
        } else {
          obj = {...required}
        }
        const result = await axios.put(`https://api.vkusclick.ru/v1/restaurants/${selectRestaurantId}/employees/${qs.parse(location.search.substring(1)).id}`, {
          ...obj
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        if(result.status === 200) {
          if(user?._id === result.data._id) {
            dispatch(setUser({...user, ...result.data}))
          }
          navigate('/workers')
        }
      } catch (e) {

      }

    }

  }

  const generatePassword = () => {
    const password = generator.generate({
      length: 10,
      numbers: true
    })
    setPasswordError({
      active: false,
      text: ''
    })
    setWorkerInfo({...workerInfo, password})
  }

  const removeUser = async () => {
    try {
      await axios.delete(`https://api.vkusclick.ru/v1/restaurants/${selectRestaurantId}/employees/${qs.parse(location.search.substring(1)).id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      if(workers) {
        dispatch(setWorkers([...workers.filter(item => item['_id'] !== qs.parse(location.search.substring(1)).id)]));
      }
      // eslint-disable-next-line no-restricted-globals
      navigate('/workers')
      window.location.reload()
    } catch (e) {

    }

  }


  return (

    <>
      {
        modal && <DeleteModal setActive={setModal} text={'пользователя'} removeFc={removeUser}/>
      }
      <div className={styles.top}>
        {/* eslint-disable-next-line no-restricted-globals */}
        <button onClick={() => history.back()}>
          <Icon width={24} height={24} color={theme === 'dark' ? '#CFD3EC' : '#4B4B4B'} icon="tabler:arrow-narrow-left" />
        </button>
        <PageTitle title={workerInfo.role === 'director' || workerInfo.role === 'manager' ? 'Редактировать руководителя' : 'Редактировать сотрудника'}/>
      </div>
      {
        workerInfo.role !== 'director' && workerInfo.role !== user?.role ?
          <div className={styles.content} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 4px 18px rgba(75, 70, 92, 0.1)'}}>
            {
              loading ?
                <img src={loader} className={styles.loader} alt={''}/> :
                <>
                  <div className={styles.sides}>
                    <div className={styles.side}>
                      <div className={styles.inputs}>
                        <div className={styles.inputWrap}>
                          <p className={styles.inputTitle}>Имя<span>*</span></p>
                          <Input changeFunc={(text: string) => setWorkerInfo({...workerInfo, firstName: text})} value={workerInfo.firstName} placeholder={'Имя'} type={'text'} errorFunc={setFirstNameError} error={firstNameError}/>
                        </div>
                        <div className={styles.inputWrap}>
                          <p className={styles.inputTitle}>Фамилия</p>
                          <input onChange={(e) => setWorkerInfo({...workerInfo, lastName: e.target.value})} value={workerInfo.lastName} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}} type="text" className={styles.input} placeholder={'Фамилия'}/>
                        </div>
                      </div>
                      {
                        work === 'director' ?
                          <>
                            <p className={styles.inputTitle}>Пароль</p>
                            <NavLink to={'/reset-password'} className={styles.reset}>
                              Изменить пароль
                            </NavLink>
                          </>:
                          <>
                            <div className={styles.inputWrap}>
                              <p className={styles.inputTitle}>Должность</p>
                              <WorkerAddSelect value={workerInfo.role} setValue={setValue}/>
                            </div>
                            <div className={styles.inputWrap}>
                              <p className={styles.inputTitle}>Пароль</p>
                              <div className={styles.passwordInputWrap}>
                                <input onChange={(e) => setWorkerInfo({...workerInfo, password: e.target.value})} value={workerInfo.password} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}} type="text" className={styles.input} placeholder={'Пароль'}/>
                                <button className={styles.generatePassword} onClick={generatePassword}>Сгенерировать</button>
                              </div>
                            </div>
                          </>
                      }
                      {
                        work === 'manager' &&
                        <>
                          <div className={styles.inputWrap}>
                            <p className={styles.inputTitle}>Email<span>*</span></p>
                            <Input changeFunc={(text: string) => setWorkerInfo({...workerInfo, email: text})} value={workerInfo.email} placeholder={'Email'} type={'email'} errorFunc={setEmailError} error={emailError}/>
                          </div>
                          <div className={styles.inputWrap}>
                            <p className={styles.inputTitle}>Номер телефона</p>
                            <input onChange={(e) => setWorkerInfo({...workerInfo, phone: e.target.value})} value={workerInfo.phone} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}} type="text" className={styles.input} placeholder={'Номер'}/>
                          </div>
                        </>
                      }
                    </div>
                    <div className={styles.side}>
                      {
                        work === 'manager' ?
                          <>
                            <p className={styles.inputTitle}>Доступные рестораны </p>
                            <div className={styles.selects} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}}>
                              {
                                restaurants?.map(item => <CheckItem key={item._id} defaultChecked={item._id === selectRestaurantId} title={item.title}/>)
                              }

                            </div>
                          </>
                          :
                          <>
                            <div className={styles.inputWrap}>
                              <p className={styles.inputTitle}>Email<span>*</span></p>
                              <Input changeFunc={(text: string) => setWorkerInfo({...workerInfo, email: text})} value={workerInfo.email} placeholder={'Email'} type={'email'} errorFunc={setEmailError} error={emailError}/>
                            </div>
                            <div className={styles.inputWrap}>
                              <p className={styles.inputTitle}>Номер телефона</p>
                              <input onChange={(e) => setWorkerInfo({...workerInfo, phone: e.target.value})} value={workerInfo.phone} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}} type="text" className={styles.input} placeholder={'Номер'}/>
                            </div>
                          </>
                      }
                    </div>
                  </div>
                  <div className={styles.bottom}>
                    
                      { user?.role === 'demo' ? (
                        <button title='Недоступно в демонстрационном заведении' className={styles.remove} style={{color: '#fff', backgroundColor: MuiTheme.palette.error.light, borderRadius: '6px', cursor: 'not-allowed'}}>Удалить</button>
                      ) : (
                        work !== 'director' &&
                        <button className={styles.remove} style={{color: '#fff', backgroundColor: MuiTheme.palette.error.light, borderRadius: '6px'}} onClick={() => {
                          setModal(true)
                          // eslint-disable-next-line no-restricted-globals
                        }}>Удалить</button>
                      )}
                      
                    <div style={{marginLeft: work === 'director' ? 'auto' : '0'}}>
                      {/* eslint-disable-next-line no-restricted-globals */}
                      <button className={styles.remove} style={{color: theme === 'light' ? '#A8AAAE' : '#A8AAAE', borderColor: theme === 'light' ? '#A8AAAE' : '#A8AAAE', borderRadius: '6px'}} onClick={() => history.back()}>Отменить</button>
                      
                      { user?.role === 'demo' ? (
                        <button title='Недоступно в демонстрационном заведении' className={styles.remove} style={{color: '#fff', backgroundColor: "#ff9f43", borderRadius: '6px', cursor: 'not-allowed'}}>Сохранить</button>
                      ) : (
                        <button className={styles.remove} style={{color: '#fff', backgroundColor: "#ff9f43", borderRadius: '6px'}} onClick={editUser}>Сохранить</button>
                      )}
                      </div>
                  </div>
                </>
            }
          </div>
          : <div className={styles.content} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 4px 18px rgba(75, 70, 92, 0.1)'}}>
            <div className={styles.sides}>
              <div className={styles.side}>
                <div className={styles.inputs}>
                  <div className={styles.inputWrap}>
                    <p className={styles.inputTitle}>Имя<span>*</span></p>
                    <Input changeFunc={(text: string) => setWorkerInfo({...workerInfo, firstName: text})} value={workerInfo.firstName} placeholder={'Имя'} type={'text'} errorFunc={setFirstNameError} error={firstNameError}/>
                  </div>
                  <div className={styles.inputWrap}>
                    <p className={styles.inputTitle}>Фамилия</p>
                    <input onChange={(e) => setWorkerInfo({...workerInfo, lastName: e.target.value})} value={workerInfo.lastName} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}} type="text" className={styles.input} placeholder={'Фамилия'}/>
                  </div>
                </div>
                <div className={styles.inputWrap}>
                  <p className={styles.inputTitle}>Пароль</p>
                  <NavLink to={'/reset-password'} className={styles.reset}>
                    Изменить пароль
                  </NavLink>
                </div>
              </div>
              <div className={styles.side}>
                <div className={styles.inputWrap}>
                  <p className={styles.inputTitle}>Email<span>*</span></p>
                  <Input changeFunc={(text: string) => setWorkerInfo({...workerInfo, email: text})} value={workerInfo.email} placeholder={'Email'} type={'email'} errorFunc={setEmailError} error={emailError}/>
                </div>
                <div className={styles.inputWrap}>
                  <p className={styles.inputTitle}>Номер телефона</p>
                  <input onChange={(e) => setWorkerInfo({...workerInfo, phone: e.target.value})} value={workerInfo.phone} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}} type="text" className={styles.input} placeholder={'Номер'}/>
                </div>
              </div>
            </div>
            <div className={styles.bottom}>
              <div>
                {/* eslint-disable-next-line no-restricted-globals */}
                <button className={styles.remove} style={{color: theme === 'light' ? '#A8AAAE' : '#A8AAAE', borderColor: theme === 'light' ? '#A8AAAE' : '#A8AAAE', borderRadius: '6px'}} onClick={() => history.back()}>Отменить</button>
                
                { user?.role === 'demo' ? (
                  <button title='Недоступно в демонстрационном заведении' className={styles.remove} style={{color: '#fff', backgroundColor: "#ff9f43", borderRadius: '6px', cursor: 'not-allowed'}}>Сохранить</button>
                ) : (
                  <button className={styles.remove} style={{color: '#fff', backgroundColor: "#ff9f43", borderRadius: '6px'}} onClick={editUser}>Сохранить</button>
                )}
              </div>
            </div>
          </div>

      }
    </>
  );
}

export default Index;
