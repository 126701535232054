import React from 'react';
import PageTitle from "../../Components/PageTitle";
import CategoriesContent from "../../Components/CategoriesContent";

function Index() {
  return (
    <>
      <PageTitle title={'Категории'}/>
      <CategoriesContent/>
    </>

  );
}

export default Index;
