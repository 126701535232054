import React, {useEffect, useState} from 'react';
import styles from './WorkerAddContent.module.scss';
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {Typography, useTheme} from "@mui/material";
import WorkerAddSelect from "../WorkerAddSelect";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import Input from "../SignIn/Input";
import generator from 'generate-password-browser';
import CheckItem from "../WorkerEditContent/CheckItem";

function Index() {

  const {theme, selectRestaurantId} = useSelector((state: RootState) => state.MainSlice);
  const {user, accessToken, restaurants} = useSelector((state: RootState) => state.UserSlice);

  const MuiTheme = useTheme();
  const navigate = useNavigate();
  const [value, setValue] = useState<string>('officiant')

  const [firstNameError, setFirstNameError] = useState({
    active: false,
    text: ''
  })

  const [emailError, setEmailError] = useState({
    active: false,
    text: ''
  })

  const [passwordError, setPasswordError] = useState({
    active: false,
    text: ''
  })

  interface userInfo {
    firstName: string,
    lastName: string,
    role: string,
    restaurantsId: string[],
    phone: string,
    email: string,
    password: string
  }

  const [userInfo, setUserInfo] = useState<userInfo>({
    firstName: '',
    lastName: '',
    role: value,
    restaurantsId: [selectRestaurantId],
    phone: '',
    email: '',
    password: ''
  })

  const [checked, setChecked] = useState<string[]>([]);

  useEffect(() => {
    if(selectRestaurantId && checked.length === 0) {
      setChecked([selectRestaurantId])
    }
  }, [selectRestaurantId])

  useEffect(() => {
    console.log(checked);
  }, [checked])

  const createNewWorker = async () => {
    if(userInfo.firstName.trim() === '' || userInfo.email.trim() === '' || userInfo.password.trim() === '') {
      if(userInfo.firstName.trim() === '') {
        setFirstNameError({
          active: true,
          text: 'Поле не заполнено'
        })
      }
      if(userInfo.email.trim() === '') {
        setEmailError({
          active: true,
          text: 'Поле не заполнено'
        })
      }
      if(userInfo.password.trim() === '') {
        setPasswordError({
          active: true,
          text: 'Поле не заполнено'
        })
      }
    }
    else {
      let obj = {};
      const required = {
        firstName: userInfo.firstName,
        role: userInfo.role,
        restaurantsId: userInfo.restaurantsId,
        email: userInfo.email,
        password: userInfo.password
      }
      if(userInfo.lastName !== '' || userInfo.phone !== '')
      {
        if(userInfo.lastName !== '') {
          obj = {...required, ...obj, lastName: userInfo.lastName}
        }
        if(userInfo.phone !== '') {
          obj = {...required, ...obj, phone: userInfo.phone}
        }
      } else {
        obj = {...required}
      }
      try {
        const result = await axios.post(`https://api.vkusclick.ru/v1/restaurants/${selectRestaurantId}/employees`, {...obj}, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        navigate('/workers');
      } catch (e: any) {
        const error = e.response.data.message;
        if(e.response.data.errors === '"email" must be a valid email') {
          setEmailError({
            active: true,
            text: 'Некорректный Email'
          })
        } else if (error === 'Employee with this email already exists') {
          setEmailError({
            active: true,
            text: 'Email уже зарегистрирован'
          })
        }
      }

    }
  }

  const generatePassword = () => {
    const password = generator.generate({
      length: 10,
      numbers: true
    })
    setPasswordError({
      active: false,
      text: ''
    })
    setUserInfo({...userInfo, password})
  }

  return (
    <div className={styles.content} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 4px 18px rgba(75, 70, 92, 0.1)'}}>
      <div className={styles.sides}>
        <div className={styles.side}>
          <div className={styles.inputs}>
            <div className={styles.inputWrap}>
              <p className={styles.inputTitle}>Имя<span>*</span></p>
              <Input changeFunc={(text: any) => setUserInfo({...userInfo, firstName: text})} value={userInfo.firstName} placeholder={'Имя'} type={'text'} errorFunc={setFirstNameError} error={firstNameError}/>
            </div>
            <div className={styles.inputWrap}>
              <p className={styles.inputTitle}>Фамилия</p>
              <Input changeFunc={(text: any) => setUserInfo({...userInfo, lastName: text})} value={userInfo.lastName} placeholder={'Фамилия'} type={'text'} errorFunc={() => {}} error={{active: false, text: ''}}/>
            </div>
          </div>

          <div className={styles.inputWrap}>
            <p className={styles.inputTitle}>Должность</p>
            <WorkerAddSelect value={userInfo.role} setValue={(role: string) => setUserInfo({...userInfo, role: role})}/>
          </div>
          <div className={styles.inputWrap}>
            <p className={styles.inputTitle}>Пароль<span>*</span></p>
            <div className={styles.passwordInputWrap}>
              <div style={{width: '100%'}}>
                <Input changeFunc={(text: string) => setUserInfo({...userInfo, password: text})} value={userInfo.password} placeholder={'Пароль'} type={'text'} errorFunc={setPasswordError} error={passwordError}/>
              </div>
              <button onClick={generatePassword} className={styles.generatePassword}>Сгенерировать</button>
            </div>
          </div>
        </div>
        <div className={styles.side}>
          {
            userInfo.role === 'manager' &&
            <div className={styles.inputWrap}>
              <p className={styles.inputTitle}>Доступные рестораны </p>
              <div className={styles.selects} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}}>
                {
                  restaurants?.map((item: any) => <CheckItem id={item._id} checkedArray={checked} addToChecked={setChecked} key={item._id} defaultChecked={!checked.find((itemCheck: any) => itemCheck._id === item._id)} title={item.title}/>)
                }

              </div>
            </div>
          }
          <div className={styles.inputWrap}>
            <p className={styles.inputTitle}>Email<span>*</span></p>
            <Input changeFunc={(text: string) => setUserInfo({...userInfo, email: text})} value={userInfo.email} placeholder={'Email'} type={'email'} errorFunc={setEmailError} error={emailError}/>
          </div>
          <div className={styles.inputWrap}>
            <p className={styles.inputTitle}>Номер телефона</p>
            <input value={userInfo.phone} onChange={(e) => setUserInfo({...userInfo, phone: e.target.value})} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}} type="text" className={styles.input} placeholder={'Номер'}/>
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        <div>
          {/* eslint-disable-next-line no-restricted-globals */}
          <button className={styles.remove} style={{color: theme === 'light' ? '#A8AAAE' : '#A8AAAE', borderColor: theme === 'light' ? '#A8AAAE' : '#A8AAAE', borderRadius: '6px'}} onClick={() => navigate('/workers')}>Отменить</button>
          
          { user?.role === 'demo' ? (
            <button title='Недоступно в демонстрационном заведении' className={styles.remove} style={{color: '#fff', backgroundColor: "#ff9f43", borderRadius: '6px', cursor: 'not-allowed'}}>Сохранить</button>            
          ) : (
            <button className={styles.remove} style={{color: '#fff', backgroundColor: "#ff9f43", borderRadius: '6px'}} onClick={createNewWorker}>Сохранить</button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Index;
