import React, {useEffect, useState} from 'react';
import styles from './SelectWaiter.module.scss';
import {IconButton, Typography, useTheme} from "@mui/material";
import {Icon} from "@iconify/react";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import axios from "axios";
import Input from "../../SignIn/Input";
const uniq = require('lodash.uniq')


function Index({bannedTables, getSettings, assignTables, selectWaiterIndex, setIndex, selectedTables, selectedIndex, tablesCountStart, tablesCountEnd}: {bannedTables: any, selectedIndex: any, getSettings: any, tablesCountStart: any, tablesCountEnd: any,assignTables: any, selectWaiterIndex?: string, setIndex: any, selectedTables: any}) {

  const {theme, workers, selectRestaurantId} = useSelector((state: RootState) => state.MainSlice);
  const {user, accessToken} = useSelector((state: RootState) => state.UserSlice);
  const [bannedInput, changeInput] = useState('');
  const MuiTheme = useTheme();

  const getTableLine = (tables: any, id: any) => {
    const mas = tables.filter((item: any, i: number) => item.officiantId === id)
    const line = mas.find((item: any) => item.officiantId === id)
    return line && line.tables.length > 0 ? line.tables : 'Не задано';
  }

  const [inputError, setError] = useState({
    active: false,
    text: ''
  })

  useEffect(() => {
    changeInput(bannedTables);
  }, [bannedTables])

  const banTables = async () => {
      const newMas = uniq([...bannedInput.split(',').map(item => {
        if(!selectedIndex.includes(parseInt(item)) && !selectedTables.includes(parseInt(item))) {
          return parseInt(item)
        }
      })]);
      await axios.put(`https://api.vkusclick.ru/v1/restaurants/${selectRestaurantId}/tables_settings`, {
        tablesCountStart: tablesCountStart,
        tablesCountEnd: tablesCountEnd,
        unattendedTables: newMas
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      getSettings()
  }


    return (
    <div className={styles.content}>
      <p className={styles.title}>Официанты</p>
      <div className={styles.noWaitersBlock}>
        <div className={styles.blockTop}>
          <div className="title-flex mini">
            <p>Необслуживаемые столы</p>
            <button className={theme}>
              <span style={{boxShadow: theme === 'light'  ? `0px 2px 4px rgba(165, 163, 174, 0.3)` : '0px 2px 4px rgba(15, 20, 34, 0.4)'}}><p>Введите номера столов через запятую</p></span>
              <Icon color={theme === 'light' ? '#4B465C66' : '#8692D066'} icon="bi:info-circle" />
            </button>
          </div>
        </div>
        <div className={styles.blockContent}>
          <div style={{width: '100%'}}>
            <Input changeFunc={(text: string) => changeInput(text)} value={bannedInput} placeholder={'Столы'} type={'text'} errorFunc={setError} error={inputError}/>
          </div>
            { user?.role === 'demo' ? (
              <button title='Недоступно в демонстрационном заведении' className={styles.submit} style={{backgroundColor: "#ff9f43", cursor: 'not-allowed'}}>Применить</button>            
            ) : (
              <button onClick={banTables} className={styles.submit} style={{backgroundColor: "#ff9f43"}}>Применить</button>
            )}
        </div>
      </div>
      {workers?.map((item, i) => {
        return (
            item.role === 'officiant' &&
              <div onClick={() => setIndex(item._id)} key={item._id + item.firstName} className={`${styles.waiterBlock} ${item._id === selectWaiterIndex ? styles.active : ''}`} style={{borderColor: theme === 'light' ? '#B7B5BE' : '#434968'}}>
                <div className={styles.noImg} style={{backgroundColor: "#ff9f43"}}>ЖА</div>
                <div className={styles.info}>
                  <p className={styles.name}>{item.firstName} {item.lastName}</p>
                  <p color={"#ff9f43"} className={styles.tables}>{'Столы: ' + getTableLine(assignTables, item._id)}</p>
                </div>
              </div>
        )
      })}

    </div>
  );
}

export default Index;
