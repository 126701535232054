import React, { useEffect, useState } from 'react';
import styles from './OrderContent.module.scss';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Icon } from "@iconify/react";
import { Typography, useTheme } from "@mui/material";
import axios from "axios";
import { useLocation } from "react-router-dom";
import qs from "qs";
import { getDate, getProducts, getWorkers } from "../../Hooks/Hooks";
import { setProducts } from "../../redux/MainSlice";


function Index() {

  const { theme, selectRestaurantId } = useSelector((state: RootState) => state.MainSlice);
  const { accessToken } = useSelector((state: RootState) => state.UserSlice);

  const [item, setItem] = useState<any>()
  const [name, setName] = useState('');
  const [dishes, setDishes] = useState<any>();

  const location = useLocation();

  const getItem = async () => {
    if (selectRestaurantId && accessToken) {
      const orderId = qs.parse(location.search.substring(1)).id;
      try {
        const result = await axios.get(`https://api.vkusclick.ru/v1/restaurants/${selectRestaurantId}/orders/${orderId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        setItem({ ...result.data });
        getWorkers({ id: selectRestaurantId, accessToken: accessToken }).then(workers => {
          setName(`${workers?.workers?.find(item => item._id === result.data.officiantId).firstName} ${workers?.workers?.find(item => item._id === result.data.officiantId).lastName}`);
        })
        getProducts({ id: selectRestaurantId }).then(response => {
          let out = result.data.dishes.map((findItem: any) => {
            return { ...response.products.find((find: any) => find._id === findItem._id), ...findItem }
          })
          out = out.map((item: any) => {

            let price = 0;

            if (item.optionsItems && item.option) {
              const option = item.optionsItems.find((opt: any) => opt.title === item.option).price
              price += option
            }

            if (item.additionalls && item.additionallItems) {
              item.additionalls.map((add: any) => {
                const addPrice = item.additionallItems.find((temp: any) => temp.title === add).price
                price += addPrice
              })
            }
            return {...item, count: result.data.dishes.find((find: any) => item._id === find._id && `${JSON.stringify(item.option ? item.option : '')}${JSON.stringify(item.additionalls)}` === `${JSON.stringify(find.option ? find.option : '')}${JSON.stringify(find.additionalls)}`).quantity, addPrice: price}
          })

          setDishes([...out])
        })
      } catch (e) {

      }
    }
  }

  useEffect(() => {
    getItem()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectRestaurantId, accessToken])

  const MuiTheme = useTheme();

  return (
    <div className={styles.content} style={{ backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 4px 18px rgba(75, 70, 92, 0.1)' }}>
      {
        item &&
        <>
          <div className={styles.wrap}>
            <div className={styles.info}>
              <div className={styles.infoItem}>
                <div className={styles.iconWrap}
                  style={{ backgroundColor: theme === 'light' ? 'rgba(75, 70, 92, 0.08)' : 'rgba(134, 146, 208, 0.08)' }}>
                  <Icon color={theme === 'light' ? '#4B465C' : '#CFD3EC'} width={24} icon={'tabler:container'} />
                </div>
                <div className={styles.descr}>
                  <p className={styles.title}>Стол</p>
                  <p className={styles.subtitle}>{item.table}</p>
                </div>
              </div>
              <div className={styles.infoItem}>
                <div className={styles.iconWrap}
                  style={{ backgroundColor: theme === 'light' ? 'rgba(75, 70, 92, 0.08)' : 'rgba(134, 146, 208, 0.08)' }}>
                  <Icon color={theme === 'light' ? '#4B465C' : '#CFD3EC'} width={24} icon={'tabler:user'} />
                </div>
                <div className={styles.descr}>
                  <p className={styles.title}>Официант</p>
                  <p className={styles.subtitle}>{name}</p>
                </div>
              </div>
              <div className={styles.infoItem}>
                <div className={styles.iconWrap}
                  style={{ backgroundColor: theme === 'light' ? 'rgba(75, 70, 92, 0.08)' : 'rgba(134, 146, 208, 0.08)' }}>
                  <Icon color={theme === 'light' ? '#4B465C' : '#CFD3EC'} width={24} icon={'tabler:calendar'} />
                </div>
                <div className={styles.descr}>
                  <p className={styles.title}>Дата</p>
                  <p className={styles.subtitle}>{getDate(item.updatedAt)}</p>
                </div>
              </div>
              <div className={styles.infoItem}>
                <div className={styles.iconWrap}
                  style={{ backgroundColor: theme === 'light' ? 'rgba(75, 70, 92, 0.08)' : 'rgba(134, 146, 208, 0.08)' }}>
                  <Icon color={theme === 'light' ? '#4B465C' : '#CFD3EC'} width={24} icon={'tabler:blockquote'} />
                </div>
                <div className={styles.descr}>
                  <p className={styles.title}>Комментарий</p>
                  <p className={styles.subtitle}>{item.wishes}</p>
                </div>
              </div>
            </div>
            <div className={styles.table} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }}>
              <div className={styles.top} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }}>
                <div className={styles.col}>
                  <p className={styles.colTitle}>Название</p>
                </div>
                <div className={styles.col}>
                  <p className={styles.colTitle}>Модификаторы</p>
                </div>
                <div className={styles.col}>
                  <p className={styles.colTitle}>Цена, руб</p>
                </div>
                <div className={styles.col}>
                  <p className={styles.colTitle}>Количество</p>
                </div>
                <div className={styles.col}>
                  <p className={styles.colTitle}>Сумма, руб</p>
                </div>
              </div>
              <div className={styles.tableContent} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }}>
                {dishes &&
                  dishes.map((row: any, i: number) => {
                    return (
                      <div key={row.title + i} className={styles.row}
                        style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }}>
                        <div className={styles.col}>
                          <div className={styles.imgWrap} style={{ backgroundImage: row.image ? `url(https://s3.vkusclick.ru/${row.image})` : 'none', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                            {
                              !row.image &&
                              <span className={styles.noImage} style={{ backgroundColor: "#ff9f43" }}>{row.title[0]}</span>
                            }
                          </div>
                          <p className={styles.title}>{row.title}</p>
                        </div>
                        <div className={styles.col}>
                          <p className={styles.additionals}>{row.option || row.additionalls ? row.option ? `${row.option}${row.additionalls ? ', ' + row.additionalls.join(', ') : ''}` : '' : '-'}</p>
                        </div>
                        <div className={styles.col}>
                          <p className={styles.price}>{row.price + row.addPrice}</p>
                        </div>
                        <div className={styles.col}>
                          <p className={styles.count}>{row.count}</p>
                        </div>
                        <div className={styles.col}>
                          <p className={styles.totalItemPrice}>{row.count * (row.price + row.addPrice)}</p>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <p className={styles.totalPrice}>Итого: {item.totalCost} руб.</p>
        </>
      }
    </div>
  );
}

export default Index;
