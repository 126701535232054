import React, { useEffect, useRef, useState } from 'react';
import { Icon } from "@iconify/react";
import PageTitle from "../PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import styles from '../ProductAddContent/ProductAddContent.module.scss';
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import Select from '../ProductAddContent/Select';
import { useTheme } from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import axios from 'axios';
import Input from "../SignIn/Input";
import { Tags } from "../ProductAddContent/Tags";
import { useLocation, useNavigate } from "react-router-dom";
import CategorySelect from "../ProductAddContent/CategorySelect";
import { getCategories, getProducts } from "../../Hooks/Hooks";
import { setCategories, setProducts } from "../../redux/MainSlice";
import qs from "qs";
import loader from "../../assets/img/loader-white.svg";
import { optionState } from '../ProductAddContent';

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 30,
  height: 18,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: '3px 3px 0 3px',
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#FF9F43',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 12,
    height: 12,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export function dataURLtoFile(dataurl: any, filename: any) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

function Index() {

  const theme = useSelector((state: RootState) => state.MainSlice.theme);
  const { selectRestaurantId, products } = useSelector((state: RootState) => state.MainSlice);
  const { company, user, accessToken } = useSelector((state: RootState) => state.UserSlice);

  const MuiTheme = useTheme();

  const [checked, setCheck] = useState(false);

  const [image, setImage] = useState<any>(null);

  const [titleError, setTitleError] = useState({
    active: false,
    text: ''
  })
  const [priceError, setPriceError] = useState({
    active: false,
    text: ''
  })
  const [quantityError, setQuantityError] = useState({
    active: false,
    text: ''
  })
  const [servingTimeError, setServingTimeError] = useState({
    active: false,
    text: ''
  })

  const [tags, setTags] = React.useState<{
    id: string,
    text: string
  }[]>([]);

  const [loading, setLoading] = useState(true);

  const [companyTariff, setCompanyTariff] = useState('start_tariff');

  useEffect(() => {
    if (company && company.subscriptionModulesId) {
      company.subscriptionModulesId.map((moduleId: any) => {
        if (moduleId === '653ade5c8f5d7381aba0ee28') {
          setCompanyTariff('start_tariff');
        }
        if (moduleId === '653ade5c8f5d7381aba0ee29') {
          setCompanyTariff('optimal_tariff');
        }
        if (moduleId === '644b1634d059fa38049e6b55') {
          setCompanyTariff('advanced_tariff');
        }
      })
    }
  }, [company])


  const [dishInfo, changeDishInfo] = useState<{
    _id?: string,
    categoryId: string,
    title: string,
    description: string,
    price: string,
    oldPrice: string,
    quantity: string,
    measure: string,
    servingTime: string,
    kilocalories: string,
    proteins: string,
    fats: string,
    carbohydrates: string,
    ingredients: string,
    allergens?: string[],
    active: string,
    image: any
  }>({
    image: undefined,
    _id: '',
    title: '',
    price: '',
    quantity: '',
    servingTime: '',
    measure: 'grams',
    categoryId: '',
    // -------
    description: '',
    oldPrice: '',
    kilocalories: '',
    proteins: '',
    fats: '',
    carbohydrates: '',
    ingredients: '',
    allergens: [],
    active: ''
  })

  const ref = useRef<any>()

  const [scale, setScale] = useState(1);

  function zoom(e: any) {
    // setScale(Math.min(Math.max(.125, ((scale + event.deltaY) * -0.01)), 4))
    if (e.deltaY < 0) {
      setScale(scale + 0.05)
    } else {
      if (scale >= 1.05) {
        setScale(scale - 0.05)
      }
    }
    e.preventDefault ? e.preventDefault() : (e.returnValue = false);
  }



  const navigate = useNavigate();
  const [loadingBtn, setLoadingBtn] = useState(false);


  const updateDish = async () => {
    if (dishInfo.title.trim() === '' || dishInfo.price === '' || dishInfo.quantity === '' || dishInfo.servingTime === '') {
      if (dishInfo.title.trim() === '') {
        setTitleError({
          active: true,
          text: 'Поле не заполнено'
        })
      }
      if (dishInfo.price.trim() === '') {
        setPriceError({
          active: true,
          text: 'Поле не заполенено'
        })
      }
      if (dishInfo.quantity.trim() === '') {
        setQuantityError({
          active: true,
          text: 'Поле не заполенено'
        })
      }
      if (dishInfo.servingTime.trim() === '') {
        setServingTimeError({
          active: true,
          text: 'Поле не заполенено'
        })
      }
    } else {
      setLoadingBtn(true);
      let file;
      if (ref.current) {
        const canvas = ref.current.getImage().toDataURL("image/jpeg")
        file = dataURLtoFile(canvas, image.name)
      }
      let obj = {};
      const allergensLine = `[${[...tags.map(item => item.text)].join()}]`;
      const required = {
        categoryId: dishInfo.categoryId,
        title: dishInfo.title,
        price: dishInfo.price,
        quantity: dishInfo.quantity,
        measure: dishInfo.measure,
        servingTime: dishInfo.servingTime,
        active: `${checked}`,
        allergens: allergensLine.substring(1, allergensLine.length - 1)
      }
      if (dishInfo.description !== '' || dishInfo.ingredients !== '' || dishInfo.oldPrice !== '' || dishInfo.kilocalories !== '' || dishInfo.fats !== '' || dishInfo.carbohydrates !== '' || dishInfo.proteins !== '') {
        if (dishInfo.description !== '') {
          obj = { ...required, ...obj, description: dishInfo.description }
        }
        if (dishInfo.ingredients !== '') {
          obj = { ...required, ...obj, ingredients: dishInfo.ingredients }
        }
        if (dishInfo.oldPrice !== '') {
          obj = { ...required, ...obj, oldPrice: dishInfo.oldPrice }
        }
        if (dishInfo.kilocalories !== '') {
          obj = { ...required, ...obj, kilocalories: dishInfo.kilocalories }
        }
        if (dishInfo.fats !== '') {
          obj = { ...required, ...obj, fats: dishInfo.fats }
        }
        if (dishInfo.carbohydrates !== '') {
          obj = { ...required, ...obj, carbohydrates: dishInfo.carbohydrates }
        }
        if (dishInfo.proteins !== '') {
          obj = { ...required, ...obj, proteins: dishInfo.proteins }
        }
        if (options.length > 0) {
          obj = { ...required, ...obj, optionsItems: JSON.stringify([...options]).substring(1, JSON.stringify([...options]).length - 1) }
        }
        if (additionall.length > 0) {
          obj = { ...required, ...obj, additionallItems: JSON.stringify([...additionall]).substring(1, JSON.stringify([...additionall]).length - 1) }
        }
      } else {
        obj = { ...required }
      }
      const result = await axios.put(`https://api.vkusclick.ru/v1/restaurants/${selectRestaurantId}/dishes/${qs.parse(location.search.substring(1)).id}`, {
        ...obj,
        imageFile: file
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'content-type': 'multipart/form-data'
        }
      })
      if (result.status === 200) {
        setTimeout(() => {
          setLoadingBtn(false);
          navigate('/products')
        }, 2000)
      }
    }
  }

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    changeDishInfo({ ...dishInfo, allergens: [...tags.map(item => item.text)] })
  }, [tags])

  useEffect(() => {
    console.log(checked);
  }, [checked])

  const change = (data: { allergens?: string[] }) => {
    changeDishInfo({
      _id: "",
      active: "",
      allergens: [],
      carbohydrates: "",
      categoryId: "",
      description: "",
      fats: "",
      image: undefined,
      ingredients: "",
      kilocalories: "",
      measure: "",
      oldPrice: "",
      price: "",
      proteins: "",
      quantity: "",
      servingTime: "",
      title: "", ...data
    })
  }

  useEffect(() => {
    if (accessToken && selectRestaurantId) {
      setLoading(true);
      getProducts({ id: selectRestaurantId }).then(response => {
        dispatch(setProducts([response.type === 'error' ? [] : response.products]))
        const item = { ...response.products.find((item: any) => item._id === qs.parse(location.search.substring(1)).id) };
        if (item.optionsItems) {
          setOptions([...item.optionsItems]);
        }
        if (item.additionallItems) {
          setAdditionall([...item.additionallItems])
        }
        // @ts-ignore
        change({ ...item });
        setCheck(item.active);
        if (item.allergens?.length && item.allergens?.length > 0) {
          setTags([]);
          console.log('item updated!');
          item.allergens?.map((item: string, i: number) => {
            setTags((current) => [...current, { id: `${current.length + i}`, text: item }]);
          })
        }
        // @ts-ignore
        const image = response.products.find(item => item._id === qs.parse(location.search.substring(1)).id).image;
        if (image) {
          fetch('https://s3.vkusclick.ru/' + image)
            .then(async response => {
              const contentType = response.headers.get('content-type')
              const blob = await response.blob()
              // @ts-ignore
              const file = new File([blob], image, { contentType })
              setImage(file);
            })
        }
        setLoading(false);
      })
    }
  }, [selectRestaurantId])

  const [options, setOptions] = useState<optionState[]>([]);

  interface modalState {
    title: string
    active: boolean
    item?: optionState,
    type: 'option' | 'additional'
  }

  const [modal, setModal] = useState<modalState>({
    title: '',
    active: false,
    type: 'option'
  });

  const [optionInfo, setOptionInfo] = useState<optionState>({
    title: '',
    measure: 'grams',
    price: null,
    quantity: null
  })

  const [optionTitleError, setOptionTitleError] = useState({
    active: false,
    text: ''
  })

  const [optionPriceError, setOptionPriceError] = useState({
    active: false,
    text: ''
  })

  const [optionQuantityError, setOptionQuantityError] = useState({
    active: false,
    text: ''
  })

  const addOption = () => {
    if (modal.title === 'Редактировать опцию') {
      if (optionInfo.title.trim() === '') {
        if (optionInfo.title.trim() === '') {
          setOptionTitleError({
            active: true,
            text: 'Поле не заполнено'
          })
        }

        if (!optionInfo.price) {
          setOptionPriceError({
            active: true,
            text: 'Поле не заполнено'
          })
        }

        if (!optionInfo.quantity) {
          setOptionQuantityError({
            active: true,
            text: 'Поле не заполнено'
          })
        }

        if (optionInfo.price && optionInfo.price <= 0) {
          setOptionPriceError({
            active: true,
            text: 'Цена не может быть меньше 0'
          })
        }


        if (optionInfo.price && optionInfo.price <= 0) {
          setOptionQuantityError({
            active: true,
            text: 'Количество не может быть меньше 0'
          })
        }
      } else {
        setOptions(options.map(item => {
          if (modal.item && modal.item.title === item.title) {
            return { ...item, ...optionInfo }
          } else {
            return { ...item }
          }
        }))
        setOptionInfo({
          title: '',
          measure: 'grams',
          price: null,
          quantity: null
        })
        setModal({
          active: false,
          title: '',
          type: 'option'
        })
      }
    } else {
      if (optionInfo.title.trim() === '') {
        if (optionInfo.title.trim() === '') {
          setOptionTitleError({
            active: true,
            text: 'Поле не заполнено'
          })
        }

        if (!optionInfo.price) {
          setOptionPriceError({
            active: true,
            text: 'Поле не заполнено'
          })
        }

        if (!optionInfo.quantity) {
          setOptionQuantityError({
            active: true,
            text: 'Поле не заполнено'
          })
        }

        if (optionInfo.price && optionInfo.price <= 0) {
          setOptionPriceError({
            active: true,
            text: 'Цена не может быть меньше 0'
          })
        }


        if (optionInfo.price && optionInfo.price <= 0) {
          setOptionQuantityError({
            active: true,
            text: 'Количество не может быть меньше 0'
          })
        }
      } else {
        setOptions(current => [...current, { ...optionInfo }])
        setOptionInfo({
          title: '',
          measure: 'grams',
          price: null,
          quantity: null
        })
        setModal({
          active: false,
          title: '',
          type: 'option'
        })
      }
    }
  }






  const [additionall, setAdditionall] = useState<optionState[]>([]);

  interface modalState {
    title: string
    active: boolean
    item?: optionState
  }

  const [additionallInfo, setAdditionallInfo] = useState<optionState>({
    title: '',
    measure: 'grams',
    price: null,
    quantity: null
  })

  const [additionallTitleError, setAdditionallTitleError] = useState({
    active: false,
    text: ''
  })

  const [additionallPriceError, setAdditionallPriceError] = useState({
    active: false,
    text: ''
  })

  const [additionallQuantityError, setAdditionallQuantityError] = useState({
    active: false,
    text: ''
  })

  const addAdditionall = () => {
    if (modal.title === 'Редактировать дополнительно') {
      if (additionallInfo.title.trim() === '') {
        if (additionallInfo.title.trim() === '') {
          setAdditionallTitleError({
            active: true,
            text: 'Поле не заполнено'
          })
        }

        if (!additionallInfo.price) {
          setAdditionallPriceError({
            active: true,
            text: 'Поле не заполнено'
          })
        }

        if (!additionallInfo.quantity) {
          setAdditionallQuantityError({
            active: true,
            text: 'Поле не заполнено'
          })
        }

        if (additionallInfo.price && additionallInfo.price <= 0) {
          setAdditionallPriceError({
            active: true,
            text: 'Цена не может быть меньше 0'
          })
        }


        if (additionallInfo.price && additionallInfo.price <= 0) {
          setAdditionallQuantityError({
            active: true,
            text: 'Количество не может быть меньше 0'
          })
        }
      } else {
        setAdditionall(additionall.map(item => {
          if (modal.item && modal.item.title === item.title) {
            return { ...item, ...additionallInfo }
          } else {
            return { ...item }
          }
        }))
        setAdditionallInfo({
          title: '',
          measure: 'grams',
          price: null,
          quantity: null
        })
        setModal({
          active: false,
          title: '',
          type: 'option'
        })
      }
    } else {
      if (additionallInfo.title.trim() === '') {
        if (additionallInfo.title.trim() === '') {
          setAdditionallTitleError({
            active: true,
            text: 'Поле не заполнено'
          })
        }

        if (!additionallInfo.price) {
          setAdditionallPriceError({
            active: true,
            text: 'Поле не заполнено'
          })
        }

        if (!additionallInfo.quantity) {
          setAdditionallQuantityError({
            active: true,
            text: 'Поле не заполнено'
          })
        }

        if (additionallInfo.price && additionallInfo.price <= 0) {
          setAdditionallPriceError({
            active: true,
            text: 'Цена не может быть меньше 0'
          })
        }


        if (additionallInfo.price && additionallInfo.price <= 0) {
          setAdditionallQuantityError({
            active: true,
            text: 'Количество не может быть меньше 0'
          })
        }
      } else {
        setAdditionall(current => [...current, { ...additionallInfo }])
        setAdditionallInfo({
          title: '',
          measure: 'grams',
          price: null,
          quantity: null
        })
        setModal({
          active: false,
          title: '',
          type: 'option'
        })
      }
    }
  }

  useEffect(() => {
    if (!modal.active) {
      setOptionInfo({
        title: '',
        measure: 'grams',
        price: null,
        quantity: null
      })
      setAdditionallInfo({
        title: '',
        measure: 'grams',
        price: null,
        quantity: null
      })
    }
  }, [modal])

  return (
    <>
      {
        modal.active &&
        <div className={styles.modal}>
          <span className={styles.bg} onClick={() => setModal({ ...modal, active: false })}></span>
          <div className={styles.modalContent} style={{ backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 4px 18px rgba(75, 70, 92, 0.1)', padding: '32px 40px' }}>
            <button onClick={() => setModal({ ...modal, active: false })} className={styles.close} style={{ backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 2px 4px rgba(165, 163, 174, 0.3)' : '0px 4px 18px rgba(75, 70, 92, 0.1)' }}>
              <Icon icon={'tabler:x'} color={theme === 'dark' ? '#CFD3EC' : '#4B4B4B'} width={20} height={20} />
            </button>
            <h1 className={styles.modalTitle}>{modal.title}</h1>
            <div className={styles.row}>
              <div className={styles.inputWrap} style={{ width: '48%' }}>
                <p className={styles.inputTitle}>Название<span>*</span></p>
                {
                  modal.type === 'option' ?
                    <Input changeFunc={(text: string) => setOptionInfo({ ...optionInfo, title: text })} value={optionInfo.title} placeholder={'Название'} type={'text'} errorFunc={setOptionTitleError} error={optionTitleError} /> :
                    <Input changeFunc={(text: string) => setAdditionallInfo({ ...additionallInfo, title: text })} value={additionallInfo.title} placeholder={'Название'} type={'text'} errorFunc={setAdditionallTitleError} error={additionallTitleError} />
                }
              </div>
              <div className={styles.inputWrap} style={{ width: '48%' }}>
                <p className={styles.inputTitle}>Цена</p>
                {
                  modal.type === 'option' ?
                    <Input changeFunc={(text: string) => setOptionInfo({ ...optionInfo, price: parseInt(text.replace(/\D/, '')) })} value={optionInfo.price ? optionInfo.price.toString() : ''} placeholder={'Цена'} type={'text'} errorFunc={setOptionPriceError} error={optionPriceError} /> :
                    <Input changeFunc={(text: string) => setAdditionallInfo({ ...additionallInfo, price: parseInt(text.replace(/\D/, '')) })} value={additionallInfo.price ? additionallInfo.price.toString() : ''} placeholder={'Цена'} type={'text'} errorFunc={setAdditionallPriceError} error={additionallPriceError} />
                }
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.inputWrap} style={{ width: '48%' }}>
                <p className={styles.inputTitle}>Количество</p>
                {
                  modal.type === 'option' ?
                    <Input changeFunc={(text: string) => setOptionInfo({ ...optionInfo, quantity: parseInt(text.replace(/\D/, '')) })} value={optionInfo.quantity ? optionInfo.quantity.toString() : ''} placeholder={'Количество'} type={'text'} errorFunc={setOptionQuantityError} error={optionQuantityError} /> :
                    <Input changeFunc={(text: string) => setAdditionallInfo({ ...additionallInfo, quantity: parseInt(text.replace(/\D/, '')) })} value={additionallInfo.quantity ? additionallInfo.quantity.toString() : ''} placeholder={'Количество'} type={'text'} errorFunc={setAdditionallQuantityError} error={additionallQuantityError} />
                }
              </div>
              <div className={styles.inputWrap} style={{ width: '48%' }}>
                <p className={styles.inputTitle}>Единицы измерения</p>
                {
                  modal.type === 'option' ?
                    <Select defaultValue={modal.item?.measure} fullWidth={true} change={(measure: string) => setOptionInfo({ ...optionInfo, measure: measure })} /> :
                    <Select defaultValue={modal.item?.measure} fullWidth={true} change={(measure: string) => setAdditionallInfo({ ...additionallInfo, measure: measure })} />
                }
              </div>
            </div>
            <div className={styles.modalButtons}>
              <button className={styles.remove} style={{ color: theme === 'light' ? '#A8AAAE' : '#A8AAAE', borderColor: theme === 'light' ? '#A8AAAE' : '#A8AAAE', borderRadius: '6px' }} onClick={() => setModal({ ...modal, active: false })}>Отменить</button>


              <button className={styles.remove} style={{ color: '#fff', backgroundColor: "#ff9f43", borderRadius: '6px' }} onClick={() => {
                if (modal.type === 'additional') {
                  addAdditionall()
                } else {
                  addOption()
                }
              }}>{loading ?
                <img className={styles.loader} src={loader} alt={'loading'} />
                : 'Сохранить'
                }</button>

            </div>
          </div>
        </div>
      }
      {
        !loading &&
        <>
          <div className={styles.top}>
            {/* eslint-disable-next-line no-restricted-globals */}
            <button onClick={() => history.back()}>
              <Icon width={24} height={24} color={theme === 'dark' ? '#CFD3EC' : '#4B4B4B'} icon="tabler:arrow-narrow-left" />
            </button>
            <PageTitle title={'Редактировать блюдо'} />
          </div>
          <div className={styles.content} style={{ backgroundColor: theme === 'light' ? '#FFFFFF' : '#2F3349', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 4px 18px rgba(75, 70, 92, 0.1)' }}>
            <div className={styles.wrap} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }}>
              <div className={styles.side}>
                <div className={styles.inputWrap}>
                  <p className={styles.inputTitle}>Изображение</p>
                  <div onWheel={(e) => zoom(e)} className={styles.selectImg} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }}>
                    <input id={'file-input'} type="file" className={styles.image} onChange={(e) => {
                      // @ts-ignore
                      setImage(e.target.files[0])
                    }
                    } />
                    {
                      image ?
                        <>
                          <button className={styles.delete} onClick={() => setImage(null)}>
                            <Icon icon={'ph:trash-simple-fill'} />
                          </button>
                          <AvatarEditor
                            ref={ref}
                            image={image}
                            width={370}
                            height={250}
                            border={[105, 15]}
                            color={[0, 0, 0, 0.6]} // RGBA
                            scale={scale}
                            rotate={0}
                          />
                        </>
                        :
                        <label className={styles.loadLabel} htmlFor={'file-input'}>Загрузить изображение</label>
                    }
                  </div>
                </div>
                <div className={styles.inputWrap}>
                  <p className={styles.inputTitle}>Категория<span>*</span></p>
                  <CategorySelect defaultValue={dishInfo.categoryId} change={(id: string) => {
                    changeDishInfo({ ...dishInfo, categoryId: id })
                  }} />
                </div>
                <div className={styles.inputWrap}>
                  <p className={styles.inputTitle}>Название<span>*</span></p>
                  <Input changeFunc={(text: string) => changeDishInfo({ ...dishInfo, title: text })} value={dishInfo.title} placeholder={'Название'} type={'text'} errorFunc={setTitleError} error={titleError} />
                </div>
                <div className={styles.inputs}>
                  <div className={styles.inputWrap}>
                    <p className={styles.inputTitle}>Цена, руб<span>*</span></p>
                    <Input changeFunc={(text: string) => changeDishInfo({ ...dishInfo, price: text.replace(/\D/, '') })} value={dishInfo.price} placeholder={'Цена'} type={'text'} errorFunc={setPriceError} error={priceError} />
                  </div>
                  <div className={styles.inputWrap}>
                    <p className={styles.inputTitle}>Старая цена, руб</p>
                    <input onChange={(e) => changeDishInfo({ ...dishInfo, oldPrice: e.target.value })} value={dishInfo.oldPrice} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }} type="text" className={styles.input} placeholder={'Цена'} />
                  </div>
                </div>
                <div className={styles.inputs}>
                  <div className={styles.inputWrap}>
                    <p className={styles.inputTitle}>Количество<span>*</span></p>
                    <Input changeFunc={(text: string) => changeDishInfo({ ...dishInfo, quantity: text.replace(/\D/, '') })} value={dishInfo.quantity} placeholder={'Количество'} type={'text'} errorFunc={setQuantityError} error={quantityError} />
                  </div>
                  <div className={styles.inputWrap}>
                    <p className={styles.inputTitle}>Единицы измерения</p>
                    <Select defaultValue={dishInfo.measure} change={(measure: string) => changeDishInfo({ ...dishInfo, measure: measure })} />
                  </div>
                </div>
                <div className={styles.inputs}>
                  <div className={styles.inputWrap}>
                    <p className={styles.inputTitle}>Время подачи, мин<span>*</span></p>
                    <Input changeFunc={(text: string) => changeDishInfo({ ...dishInfo, servingTime: text.replace(/\D/, '') })} value={dishInfo.servingTime} placeholder={'Время подачи'} type={'text'} errorFunc={setServingTimeError} error={servingTimeError} />
                  </div>
                  <div className={styles.inputWrap}>
                    <p className={styles.inputTitle}>Активность</p>
                    <div className={styles.check}>
                      {dishInfo._id !== '' &&
                        <FormControlLabel
                          control={<IOSSwitch sx={{ m: 1, marginLeft: '13px' }} defaultChecked={checked} onChange={(e) => {
                            setCheck(e.target.checked);
                          }
                          } />}
                          label=""
                        />
                      }
                      <p>{checked ? 'Активно' : 'Неактивно'}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.side}>
                <div className={styles.inputWrap}>
                  <p className={styles.inputTitle}>Описание</p>
                  <textarea onChange={(e) => changeDishInfo({ ...dishInfo, description: e.target.value })} value={dishInfo.description} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }} className={styles.area} placeholder={'Описание'}></textarea>
                </div>
                <div className={styles.inputs}>
                  <div className={styles.inputWrap}>
                    <p className={styles.inputTitle}>Ккал</p>
                    <input onChange={(e) => changeDishInfo({ ...dishInfo, kilocalories: e.target.value.replace(/\D/, '') })} value={dishInfo.kilocalories} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }} type="text" className={styles.input} placeholder={'Ккал'} />
                  </div>
                  <div className={styles.inputWrap}>
                    <p className={styles.inputTitle}>Белки, г</p>
                    <input onChange={(e) => changeDishInfo({ ...dishInfo, proteins: e.target.value.replace(/\D/, '') })} value={dishInfo.proteins} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }} type="text" className={styles.input} placeholder={'Белки'} />
                  </div>
                </div>
                <div className={styles.inputs}>
                  <div className={styles.inputWrap}>
                    <p className={styles.inputTitle}>Жиры, г</p>
                    <input onChange={(e) => changeDishInfo({ ...dishInfo, fats: e.target.value.replace(/\D/, '') })} value={dishInfo.fats} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }} type="text" className={styles.input} placeholder={'Жиры'} />
                  </div>
                  <div className={styles.inputWrap}>
                    <p className={styles.inputTitle}>Углеводы, г</p>
                    <input onChange={(e) => changeDishInfo({ ...dishInfo, carbohydrates: e.target.value.replace(/\D/, '') })} value={dishInfo.carbohydrates} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }} type="text" className={styles.input} placeholder={'Углеводы'} />
                  </div>
                </div>
                <p className={styles.inputTitle}>Аллергены</p>
                <Tags setTags={setTags} tags={tags} />
                <div className={styles.ingredients}>
                  <p className={styles.inputTitle}>Ингридиенты</p>
                  <textarea onChange={(e) => changeDishInfo({ ...dishInfo, ingredients: e.target.value })} value={dishInfo.ingredients} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }} className={styles.area} placeholder={'Ингридиенты'}></textarea>
                </div>
              </div>
            </div>
            {companyTariff === 'advanced_tariff' && (
            <div className={styles.tables}>
              <div className={styles.table}>
                <div className={styles.topNav}>
                  <p className={styles.tableTitle}>Опции блюда</p>
                  <button className={styles.tableButton} onClick={() => setModal({ title: 'Добавить опцию', active: true, type: 'option' })}>
                    <Icon icon={'tabler:plus'} width={20} height={20} color={'#fff'} />
                    Добавить
                  </button>
                </div>
                <div className={styles.tableContent} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }}>
                  <div className={styles.tableTop} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }}>
                    <div className={styles.col}>
                      Название
                    </div>
                    <div className={[styles.col, styles.priceCol].join(' ')}>
                      Цена, руб
                    </div>
                    <div className={styles.col}>
                      Кол-во
                    </div>
                    <div className={styles.col}>
                      Действия
                    </div>
                  </div>
                  {
                    options.length > 0 ?
                      options.map((item, i) => {
                        return (
                          <div className={styles.row} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }} key={item.title + i}>
                            <div className={styles.col}>{item.title}</div>
                            <div className={styles.col}>{item.price ? item.price : '-'}</div>
                            <div className={styles.col}>{`${item.quantity ? item.quantity : '-'} ${item.quantity ? item.measure === 'grams' ? 'гр' : item.measure === 'kilograms' ? 'кг' : item.measure === 'milliliters' ? 'мл' : item.measure === 'liters' ? 'л' : item.measure === 'pieces' ? 'шт' : 'шт' : ''}`}</div>
                            <div className={styles.col}>
                              <button onClick={() => {
                                setModal({ title: 'Редактировать опцию', active: true, item: item, type: 'option' })
                                setOptionInfo({
                                  ...item
                                })
                              }}><Icon icon={'tabler:pencil'} color={'#FF9F43'} width={22} height={22} /></button>
                              <button onClick={() => setOptions(options.filter(option => option.title !== item.title))}><Icon icon={'tabler:trash'} color={'#EA5455'} width={22} height={22} /></button>
                            </div>
                          </div>
                        )
                      }) :
                      <p className={styles.nothing}>Ничего нет</p>
                  }
                </div>

              </div>
              <div className={styles.table}>
                <div className={styles.topNav}>
                  <p className={styles.tableTitle}>Дополнительно</p>
                  <button className={styles.tableButton} onClick={() => setModal({ title: 'Добавить дополнительно', active: true, type: 'additional' })}>
                    <Icon icon={'tabler:plus'} width={20} height={20} color={'#fff'} />
                    Добавить
                  </button>
                </div>
                <div className={styles.tableContent} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }}>
                  <div className={styles.tableTop} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }}>
                    <div className={styles.col}>
                      Название
                    </div>
                    <div className={[styles.col, styles.priceCol].join(' ')}>
                      Цена, руб
                    </div>
                    <div className={styles.col}>
                      Кол-во
                    </div>
                    <div className={styles.col}>
                      Действия
                    </div>
                  </div>
                  {
                    additionall.length > 0 ?
                      additionall.map((item, i) => {
                        return (
                          <div className={styles.row} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }} key={item.title + i}>
                            <div className={styles.col}>{item.title}</div>
                            <div className={styles.col}>{item.price ? item.price : '-'}</div>
                            <div className={styles.col}>{`${item.quantity ? item.quantity : '-'} ${item.quantity ? item.measure === 'grams' ? 'гр' : item.measure === 'kilograms' ? 'кг' : item.measure === 'milliliters' ? 'мл' : item.measure === 'liters' ? 'л' : item.measure === 'pieces' ? 'шт' : 'шт' : ''}`}</div>
                            <div className={styles.col}>
                              <button onClick={() => {
                                setModal({ title: 'Редактировать дополнительно', active: true, item: item, type: 'additional' })
                                setAdditionallInfo({
                                  ...item
                                })
                              }}><Icon icon={'tabler:pencil'} color={'#FF9F43'} width={22} height={22} /></button>
                              {/* <button onClick={() => setOptions(options.filter(option => option.title !== item.title))}><Icon icon={'tabler:trash'} color={'#EA5455'} width={22} height={22} /></button> */}
                              <button onClick={() => setAdditionall(additionall.filter(additionall => additionall.title !== item.title))}><Icon icon={'tabler:trash'} color={'#EA5455'} width={22} height={22} /></button>
                            </div>
                          </div>
                        )
                      }) :
                      <p className={styles.nothing}>Ничего нет</p>
                  }
                </div>
              </div>
              
            </div>
            )}
            <div className={styles.bottom}>
              {/* <button className={styles.remove} style={{color: '#fff', backgroundColor: MuiTheme.palette.error.light, borderRadius: '6px', marginRight: 'auto'}}>Удалить</button> */}
              <div>
                <button className={styles.remove} style={{ color: theme === 'light' ? '#A8AAAE' : '#A8AAAE', borderColor: theme === 'light' ? '#A8AAAE' : '#A8AAAE', borderRadius: '6px' }} onClick={() => navigate('/products')}>Отменить</button>

                {user?.role === 'demo' ? (
                  <button title='Недоступно в демонстрационном заведении' className={styles.remove} style={{ color: '#fff', backgroundColor: "#ff9f43", borderRadius: '6px', cursor: 'not-allowed' }}>Сохранить</button>
                ) : (
                  <button className={styles.remove} style={{ color: '#fff', backgroundColor: "#ff9f43", borderRadius: '6px' }} onClick={updateDish}>{loadingBtn ?
                    <img className={styles.loader} src={loader} alt={'loading'} />
                    : 'Сохранить'
                  }</button>
                )}
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
}

export default Index;
