import {useEffect, useState} from 'react'

import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MuiFormControl, { FormControlProps } from '@mui/material/FormControl'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {useLocation, useNavigate} from "react-router-dom";
import {getAuth, getNotice} from "../../Hooks/Hooks";
import {setNotice, setSelectRestaurantId} from "../../redux/MainSlice";


const SelectCustomized = () => {

  const {company, restaurants, accessToken} = useSelector((state: RootState) => state.UserSlice)

  const [value, setValue] = useState('');

  const location = useLocation();
  const dispatch = useDispatch();

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string)
    localStorage.setItem('defaultRest', restaurants?.filter(item => item.title === event.target.value)[0]['_id']);
    dispatch(setSelectRestaurantId(restaurants?.filter(item => item.title === event.target.value)[0]['_id']));
  }

  useEffect(() => {
      if(localStorage.getItem('defaultRest')) {
        if(restaurants) {
          if(restaurants?.filter(item => item._id === localStorage.getItem('defaultRest'))[0]) {
            setValue(restaurants?.filter(item => item._id === localStorage.getItem('defaultRest'))[0].title)
          } else {
            setValue(restaurants[0].title)
          }
        }
        const savedRestId = restaurants?.filter(item => item._id === localStorage.getItem('defaultRest'))[0];
        if(savedRestId) {
          dispatch(setSelectRestaurantId(savedRestId['_id']));
          if(accessToken) {

            getNotice({id: savedRestId['_id'], accessToken: accessToken}).then(response => {
              // @ts-ignore
              dispatch(setNotice([...response]))
            })
          }
        } else {
          if(restaurants) {
            setValue(restaurants[0].title)
            localStorage.setItem('defaultRest', restaurants[0]._id);
          }
        }
      } else {
        if(restaurants) {
          setValue(restaurants[0].title)
          localStorage.setItem('defaultRest', restaurants[0]._id);
          dispatch(setSelectRestaurantId(restaurants?.filter(item => item.title === restaurants[0].title)[0]['_id']));
        };
      }

  }, [location, restaurants])

  const localTheme = useSelector((state: RootState) => state.MainSlice.theme);

  const FormControl = styled(MuiFormControl)<FormControlProps>(({ theme }) => ({
    '& > .MuiInputBase-root': {
      '&.MuiInput-root:before, &.MuiInput-root:after': {
        border: 0
      }
    },
    '& .MuiInputBase-input': {
      fontFamily: '"Public Sans", sans-serif',
      fontSize: 13,
      borderRadius: 4,
      position: 'relative',
      color: localTheme === 'dark' ? '#CFD3EC' : '#A5A3AE',
      padding: '7px 34px 7px 10px',
      backgroundColor: 'transparent',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      border: localTheme === 'light' ? '1px solid #DBDADE' : `1px solid #434968`,
      fontStyle: 'normal',
      '-webkit-text-fill-color': 'inherit !important',
      '&:focus-within': {
        borderRadius: 4,
        borderColor: theme.palette.warning.light,
      }
    }
  }))

  const navigate = useNavigate();

  return (
    <div className='demo-space-x' onClick={() => company.subscriptionModulesId.length <= 1 ? navigate('/calculator') : console.log('log')}>
      <FormControl fullWidth sx={{width: '240px'}}>
        <Select
          disabled={company && company.subscriptionModulesId && company.subscriptionModulesId.length <= 1}
          value={value}
          input={<InputBase />}
          onChange={handleChange}
          id='demo-customized-select'
          labelId='demo-customized-select-label'
          IconComponent={(props) => <KeyboardArrowDownIcon sx={{fill: localTheme === 'light' ? '#6F6B7D' : '#CFD3EC'}} {...props}/>}
        >
          {
            restaurants?.map((item: any) => {
              return (
                <MenuItem key={item.id} value={item.title}>{item.title}</MenuItem>
              )
            })
          }
        </Select>
      </FormControl>
    </div>
  )
}

export default SelectCustomized
