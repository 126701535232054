import React, {useEffect, useState} from 'react';
import styles from './QrGenerationContent.module.scss';
import QrTables from "./QrTables";
import {QrGeneration} from "./QrGeneration";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {getQrSettings, getTables} from "../../Hooks/Hooks";
import {setTables} from "../../redux/MainSlice";
import {useTheme} from "@mui/material";

function Index() {

  const [qrType, setType] = useState<'standard' | 'dotted' | 'rounded'>('standard')

  const {accessToken} = useSelector((state: RootState) => state.UserSlice);
  const {selectRestaurantId} = useSelector((state: RootState) => state.MainSlice);
  const [tablesCountStart, setTablesCountStart] = useState(0);
  const [tablesCountEnd, setTablesCountEnd] = useState(0)
  const [selectedIndex, changeSelectedIndex] = useState<any>([]);
  const dispatch = useDispatch();

  const [settings, setSettings] = useState({
    title: '',
    description: '',
    _id: '',
    style: '',
    updatedAt: ''
  })

  useEffect(() => {
    if(accessToken && selectRestaurantId) {
      getSettings()
    }
  }, [accessToken, selectRestaurantId])

  const [loading, setLoading] = useState(false);

  const getSettings = () => {
    if(accessToken && selectRestaurantId) {
      setLoading(true)
      getQrSettings({id: selectRestaurantId,  accessToken}).then(response => {
        setSettings({...response.settings})
        // @ts-ignore
        setType(response.settings.style)
      })
      getTables({accessToken: accessToken, id: selectRestaurantId}).then(response => {
        let mas = [];
        let start = response?.tables.tablesCountStart
        for (start; start <= response?.tables.tablesCountEnd; start++) {
          mas.push(start);
        }
        dispatch(setTables([...mas]));
        setTablesCountStart(response?.tables.tablesCountStart)
        setTablesCountEnd(response?.tables.tablesCountEnd)
        setLoading(false);
      })
    }
  }

  useEffect(() => {changeSelectedIndex([tablesCountStart === 0 ? 1 : tablesCountStart])}, [tablesCountStart])

  useEffect(() => {
    if(accessToken && selectRestaurantId) {
      getSettings()
    }

  }, [accessToken, selectRestaurantId])

  return (
    <div className={styles.content}>
      {
        selectedIndex[0] > 0 &&
        <>
          <QrTables getSettings={getSettings} changeStart={setTablesCountStart} changeEnd={setTablesCountEnd} end={tablesCountEnd} start={tablesCountStart} settings={settings} setSettings={setSettings} selectedIndex={selectedIndex} changeSelectedIndex={changeSelectedIndex} value={qrType} setValue={setType}/>
          <QrGeneration settings={settings} selectedIndex={selectedIndex} value={qrType} setValue={setType}/>
        </>
      }
    </div>
  );
}

export default Index;
