import React, { useEffect } from 'react';
import Registration from "../../Components/Registration";

function Index() {

  useEffect(() => {
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('defaultRest');
  }, [])

  return (
    <div className={'registration-page'}>
      <Registration/>
    </div>
  );
}

export default Index;
